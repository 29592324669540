export enum RoleId {
  Admin = 'Admin',
  Customer = 'Customer',
  Contractor = 'Contractor',
  CompletenessReviewer = 'CompletenessReviewer',
  DashboardViewer = 'DashboardViewer',
  InterconnectionSPOC = 'InterconnectionSPOC',
  ApplicationReviewer = 'ApplicationReviewer',
  SuperAdmin = 'SuperAdmin',
  RoleManager = 'RoleManager',
  ApplicationViewer = 'ApplicationViewer',
  ReportViewer = 'ReportViewer',
  InternalApplicationCreator = 'InternalApplicationCreator',
  EngineerViewer = 'EngineerViewer',
  OwnerModifier = 'OwnerModifier',
  PaymentManager = 'PaymentManager',
  CollaboratorManager = 'CollaboratorManager',
  ESSDUser = 'ESSDUser',
  FileManager = 'FileManager',
  StatusHistoryEditor = 'StatusHistoryEditor',
  MaxDurationExtensionManager = 'MaxDurationExtensionManager',
  ApplicationArchivalManager = 'ApplicationArchivalManager',
  ApplicationFormManager = 'ApplicationFormManager',
  SystemAdmin = 'SystemAdmin',
  ApplicationDeletionManager = 'ApplicationDeletionManager',
  SPOCUpdater = 'SPOCUpdater',
  BulkChangeEditor = 'BulkChangeEditor',
  Contributor = 'Contributor',
  ApplicationStatusTimerExtensionManager = 'ApplicationStatusTimerExtensionManager',
  ApplicationStatusTimerManager = 'ApplicationStatusTimerManager',
  DGRebateSPOC = 'DGRebateSPOC',
  ExternalMessageManager = 'ExternalMessageManager',
}
