<div #printSection class="modal filter-modal printable">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Application Payment Slip
      <button mat-button [mat-dialog-close]="{ type: 'close' }" class="not-printable">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <mat-dialog-content class="mat-typography">
      <div class="filter-modal__input-wrapper" [innerHTML]="data.header"></div>
      <div>
        <div *ngFor="let el of data.fields" class="field-margin">
          <span class="bold"> {{ el.title }}</span> -
          <span> {{ el.value ? el.value : '(No value provided)' }} </span>
        </div>
      </div>
      <div class="filter-modal__input-wrapper" [innerHTML]="data.footer"></div>
    </mat-dialog-content>
    <mat-dialog-actions class="not-printable">
      <button class="secondary-button" i18n mat-button [mat-dialog-close]="{ type: 'close' }">
        Cancel
      </button>
      <button class="primary-button" i18n mat-button (click)="printSlip()" cdkFocusInitial>
        Print Payment Slip
      </button>
    </mat-dialog-actions>
  </div>
</div>