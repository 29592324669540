import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
// bug 66468 all imports
import { Application } from '@intellio/shared/models'; // eslint-disable-line
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import {
  NotificationService,
  BackgroundFileRepositoryService,
} from '@intellio/shared/services'; // eslint-disable-line
import {
  // eslint-disable-line
  ApplicationFacade,
  ApplicationActionsUnion,
  ApplicationActionTypes,
  getApplicationFailure,
  getApplicationSuccess,
} from '@intellio/shared/state-mgmt';

@Injectable({ providedIn: 'root' })
export class ApplicationResolver implements Resolve<Application> {
  constructor(
    private applicationFacade: ApplicationFacade,
    private actions$: Actions<ApplicationActionsUnion>,
    private notificationService: NotificationService,
    private router: Router,
    private backgroundFileRepository: BackgroundFileRepositoryService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Application> {
    this.applicationFacade.dispatchGetApplication(route.params.applicationId);

    return this.actions$.pipe(
      ofType(getApplicationSuccess, getApplicationFailure),
      mergeMap((action) => {
        if (action.type === ApplicationActionTypes.GetApplicationFailure) {
          this.notificationService.open(action.error.error.message, 'error');
          this.router.navigate(['/applications']);
          return of(null);
        }
        this.backgroundFileRepository.refresh(action.application);
        return of(action.application);
      }),
      take(1)
    );
  }
}
