import { TechnicalReviewLogTableRow } from './technical-review-log-table-row.model';

export class TechnicalReviewLogDetail {
  logSectionId: string;
  order: number;
  isSubHeader: boolean;
  isTable: boolean;
  logEntry: string;
  tableTitle: string;

  tableRows: TechnicalReviewLogTableRow[];
}
