import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap } from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import {
  getJurisdictions,
  getJurisdictionsSucceeded,
  getJurisdictionsFailure,
  getIntentsOfGeneration,
  getIntentsofGenerationSucceeded,
  getIntentsofGenerationFailure,
  getEnergySources,
  getEnergySourcesSucceeded,
  getEnergySourcesFailure,
  getBusinessRoles,
  getBusinessRolesSucceeded,
  getBusinessRolesFailure,
  getUDFs,
  getUDFsSucceeded,
  getUDFsFailure,
  getUDFOptions,
  getUDFOptionsSucceeded,
  getUDFOptionsFailure,
  getTenantFilterConfig,
  getTenantFilterConfigSucceeded,
  getTenantFilterConfigFailure,
  getActionRequiredAlertRules,
  getActionRequiredAlertRulesSucceeded,
  getActionRequiredAlertRulesFailure,
} from './queue-filter.actions';
import {
  AccountService,
  ActionRequiredAlertRuleService,
  AppUserService,
  ApplicationsService,
  BusinessRolesService,
  EnergySourcesService,
} from '@intellio/shared/services';

@Injectable()
export class QueueFilterEffects {
  constructor(
    private actions$: Actions,
    private applicationsService: ApplicationsService,
    private energySourcesService: EnergySourcesService,
    private businessRolesService: BusinessRolesService,
    private accountService: AccountService,
    private appUserService: AppUserService,
    private actionRequiredAlertRuleService: ActionRequiredAlertRuleService
  ) {}

  getJurisdiction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJurisdictions),
      mergeMap(({}) =>
        this.applicationsService.getJurisdictions().pipe(
          map((jurisdictions) =>
            getJurisdictionsSucceeded({ jurisdictions: jurisdictions.data })
          ),
          catchError((error: any) => of(getJurisdictionsFailure({ error })))
        )
      )
    )
  );

  getIntentsofGeneration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIntentsOfGeneration),
      mergeMap(({}) =>
        this.applicationsService.getAllIntentsOfGeneration().pipe(
          map((intentsOfGeneration) =>
            getIntentsofGenerationSucceeded({
              intentsofGeneration: intentsOfGeneration.data,
            })
          ),
          catchError((error: any) =>
            of(getIntentsofGenerationFailure({ error }))
          )
        )
      )
    )
  );

  getEnergySources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEnergySources),
      mergeMap(({}) =>
        this.energySourcesService.getEnergySources().pipe(
          map((energySources) =>
            getEnergySourcesSucceeded({
              energySources: energySources.data,
            })
          ),
          catchError((error: any) => of(getEnergySourcesFailure({ error })))
        )
      )
    )
  );

  getBusinessRolesSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBusinessRoles),
      mergeMap(({}) =>
        this.businessRolesService.getAllBusinessRoles().pipe(
          map((businessRoles) =>
            getBusinessRolesSucceeded({
              businessRoles: businessRoles.data,
            })
          ),
          catchError((error: any) => of(getBusinessRolesFailure({ error })))
        )
      )
    )
  );

  getUDFs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUDFs),
      mergeMap(({}) =>
        this.applicationsService.getApplicationUdfs().pipe(
          map((udfs) =>
            getUDFsSucceeded({
              udfs: udfs.data,
            })
          ),
          catchError((error: any) => of(getUDFsFailure({ error })))
        )
      )
    )
  );

  getUDFOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUDFOptions),
      mergeMap(({}) =>
        this.appUserService.getUDFDropdownOptions().pipe(
          map((udfOptions) =>
            getUDFOptionsSucceeded({
              udfOptions: udfOptions.data,
            })
          ),
          catchError((error: any) => of(getUDFOptionsFailure({ error })))
        )
      )
    )
  );

  getTenantFilterConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTenantFilterConfig),
      mergeMap(({}) =>
        this.applicationsService.getTenantFilterConfig().pipe(
          map((tenantFilterConfig) =>
            getTenantFilterConfigSucceeded({
              tenantFilterConfig: tenantFilterConfig.data,
            })
          ),
          catchError((error: any) =>
            of(getTenantFilterConfigFailure({ error }))
          )
        )
      )
    )
  );

  getActionRequiredAlertRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActionRequiredAlertRules),
      mergeMap(({ isExternal }) =>
        this.actionRequiredAlertRuleService.getActionRequiredAlertRules(isExternal).pipe(
          map((rules) =>
            getActionRequiredAlertRulesSucceeded({
              actionRequiredAlertRules: rules.data,
            })
          ),
          catchError((error: any) =>
            of(getActionRequiredAlertRulesFailure({ error }))
          )
        )
      )
    )
  );
}
