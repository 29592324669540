export class EnergyStorageSystem {
  id: number;
  manufacturer: string;
  modelNumber: string;
  technology: string;
  UL1741SupplementSATesting: boolean;
  nameplateEnergyCapacity: number;
  nameplatePower: number;
  nominalVoltage: number;
  maximumContinuousDischargeRate: number;
  UL1741SupplementSACertifyingEntity: string;
  UL1741SupplementSACertificateDate: string;
  UL1741SupplementSAFirmwareVersionsTested: string;
  UL1741SAFreqWattVoltWattCertDate: string;
  UL1741SADisablePermitServiceLimitActivePowerListingDate: string;
  isEditable: boolean;
  isActive: boolean;
  automatedUpdatesDisabled: boolean;
}
