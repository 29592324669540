<div class="title-dropdown-nav">
  <mat-form-field>
    <mat-select
      [(value)]="currentNav"
      panelClass="title-drowpdown-nav-panel"
      disableOptionCentering
    >
      <mat-select-trigger>
        <mat-icon [class]="currentNav?.iconClass + ' title-icon'">{{
          currentNav?.icon
        }}</mat-icon>
        <span class="title">{{ currentNav?.title | translate }}</span>
        <mat-icon class="dropdown-icon">expand_more</mat-icon>
      </mat-select-trigger>
      <ng-container *ngFor="let nav of navList">
        <ng-container *ngIf="nav.customEnable !== false">
          <ng-container *itcModuleEnabled="nav.module">
            <mat-option
              [value]="nav"
              [routerLink]="[nav.route]"
              *itcPermissionsRequired="nav.permissions"
            >
              <mat-icon
                *ngIf="nav.icon"
                [class]="nav.iconClass + ' title-icon'"
                >{{ nav.icon }}</mat-icon
              >
              <span class="title">{{ nav.title | translate }}</span>
            </mat-option>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
