import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroup } from '@angular/forms';
import { User } from '@intellio/shared/models';
import { AccountService } from '@intellio/shared/services';
import { AccountFacade } from '@intellio/shared/state-mgmt';
import { getValueOnObjectFromStringProperty } from '@intellio/shared/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-conditional-hide',
  template: `
    <div [hidden]="!shouldRender">
      <ng-template #fieldComponent></ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizeComponent
  extends IntellioFormlyWrapperComponent
  implements OnInit
{
  shouldRender = null;
  rootForm: AbstractControl;
  currentUser: User;

  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef,
    protected accountService: AccountService
  ) {
    super(notifierService, cd);
  }

  ngOnInit(): void {
    this.shouldRender = !this.accountService.currentUser.isExternal;
    const temp = 0;
  }
}
