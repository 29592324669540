import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { CreateAccountModel } from '@intellio/shared/models';

@Injectable({ providedIn: 'root' })
export class RegisterService {
  constructor(private accountService: AccountService) {}

  register(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
    role: string,
    userId: string,
    code: string
  ) {
    const createAccountModel = new CreateAccountModel();
    createAccountModel.FirstName = firstName;
    createAccountModel.LastName = lastName;
    createAccountModel.Email = email;
    createAccountModel.NewPassword = password;
    createAccountModel.ConfirmPassword = confirmPassword;
    createAccountModel.Role = role;
    createAccountModel.Id = userId;
    createAccountModel.Code = code;

    return this.accountService.registerUser(createAccountModel);
  }

  registerEmail(email: string, role: string) {
    const createAccountModel = new CreateAccountModel();
    createAccountModel.Email = email;
    createAccountModel.Role = role;

    return this.accountService.registerUser(createAccountModel);
  }
}
