import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function phoneNumberValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  // /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
  return value && !value.match(/^\d{10}$/)
    ? {
        invalidPhoneNumber: true,
      }
    : null;
}
