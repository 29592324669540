import { combineLatest } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  generateId,
  getChildObjects,
  getFormRoot,
} from '@intellio/shared/utils';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';

import * as _ from 'lodash';

export const generateRebateProjectId = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const root = getFormRoot(field);

    const objs = getChildObjects(root, 'generateRebateProjectId');

    const icProjectIdObj = _.find(
      objs,
      (obj) => obj.generateRebateProjectId === 'ic_project_id'
    );

    const rebateProjectIdObj = _.find(
      objs,
      (obj) => obj.generateRebateProjectId === 'rebate_project_id'
    );
    combineLatest<[string, string]>([
      field.formControl.valueChanges,
      icProjectIdObj.formControl.valueChanges,
    ]).subscribe(([rebateTypeVal, icProjectIdVal]) => {
      let rebateType = '';
      switch (rebateTypeVal) {
        case 'Generation':
          rebateType = 'G';
          break;
        case 'Storage':
          rebateType = 'S';
          break;
        case 'Both':
          rebateType = 'GS';
          break;
      }

      // Format: IC Project ID-rebateType-random hash
      if (
        rebateTypeVal !== null &&
        rebateTypeVal !== undefined &&
        icProjectIdVal !== null &&
        icProjectIdVal !== undefined &&
        icProjectIdVal !== 'null' &&
        rebateTypeVal !== 'null' &&
        rebateTypeVal.length > 0 &&
        icProjectIdVal.length > 0
      ) {
        rebateProjectIdObj.formControl.setValue(
          `${icProjectIdVal}-${rebateType}-${generateId(6)}`
        );
      }
    });
  };
};
