import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
// bug 66468
import { AppConfigService } from '@intellio/shared/services'; // eslint-disable-line
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { EditorModule } from '@tinymce/tinymce-angular';

@Component({
  selector: 'itc-formly-rich-text',
  template: `
    <mat-label>{{ props.label }}</mat-label>
    <editor
      [formControl]="control"
      [apiKey]="apiKey"
      class="rich-text-editor"
      [init]="{ plugins: 'link' }"
      [class.is-invalid]="showError"
      [disabled]="props.disabled"
    ></editor>
  `,
  styles: ['.full-width {width: 100%;}'],
  styleUrls: ['./rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  control: UntypedFormControl;
  apiKey = '';

  constructor(private appConfigService: AppConfigService) {
    super();
  }

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
    this.apiKey = this.appConfigService.tenantConfig.tinyMCEApiKey;
  }
}
