import {
  AfterViewInit,
  Directive,
  QueryList,
  ViewChildren,
} from '@angular/core';

// bug 66468
@Directive({ selector: '[autoFloatLabel]' }) // eslint-disable-line
export class AutoFloatLabelDirective implements AfterViewInit {
  @ViewChildren('mat-form-field')
  matFormField: QueryList<Element>;

  // Continue trying to figure this out
  ngAfterViewInit() {
    this.matFormField.forEach((element) => {
      element.classList.add(
        'mat-form-field-can-float',
        'mat-form-field-should-float'
      );
    });
  }
}
