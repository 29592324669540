<div class="drawer app-task-details">
  <div class="in-place-spinner-wrapper" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="drawer__content mat-typography" *ngIf="!loading">
    <form class="main-form" [formGroup]="formGroup">
      <div class="drawer__form-field">
        <mat-form-field class="task-title" appearance="fill">
          <textarea
            formControlName="displayName"
            matInput
            placeholder="Task Name"
            i18n-placeholder
            [value]="task?.displayName"
          ></textarea>
        </mat-form-field>
        <button
          class="template-button"
          *ngIf="addNew && templates?.length !== 0"
          mat-icon-button
          matTooltip="Select Template"
          (click)="addTemplateDialog()"
        >
          <mat-icon>description</mat-icon>
        </button>
      </div>
      <div
        class="warning"
        *ngIf="formGroup.controls['displayName'].errors?.['required']"
      >
        Please enter a Task Name.
      </div>
      <div class="drawer__form-field">
        <div class="under-title">
          <p class="subtitle-text">
            <span
              >{{ task?.autoCompleteType }}
              {{
                task?.blocksStatusChange === true
                  ? ' | Blocks Status Change'
                  : ''
              }}</span
            >
            <span *ngIf="task?.status === 'Completed'"
              ><br /><strong>Completed:</strong>
              {{ task.completedDateTime | date : 'mediumDate' }}
              {{ task.completedDateTime | date : 'shortTime' }}</span
            >
            <span *ngIf="task?.status === 'Completed'"
              ><br /><strong>Completed by:</strong>
              {{ task.completedBy?.fullName }}
              {{
                task?.autoCompleted === true ? ' | Auto Completed' : ''
              }}</span
            >
            <span *ngIf="!addNew"
              ><br /><strong>Last updated:</strong>
              {{ task?.lastUpdatedDateTime | date : 'mediumDate' }}
              {{ task?.lastUpdatedDateTime | date : 'shortTime' }}</span
            >
            <span *ngIf="task?.creator?.fullName !== null"
              ><br /><strong>Created By:</strong>
              {{ task?.creator?.fullName }}</span
            >
          </p>
          <button
            mat-icon-button
            *ngIf="task?.status !== 'Completed'"
            class="delete-task"
            (click)="deleteTask(task)"
            matTooltip="Delete Task"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <hr />
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Task Status</strong></mat-label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="status">
            <mat-option value="Not Started">Not Started</mat-option>
            <mat-option value="In Progress">In Progress</mat-option>
            <mat-option value="Completed">Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field assignee-list">
        <div class="assignees-title">
          <div><strong i18n>Assignee(s)</strong></div>
          <mat-icon (click)="addAssigneeDialog()" matTooltip="Add Assignment"
            >add</mat-icon
          >
        </div>
        <div
          class="warning"
          *ngIf="formGroup.controls['taskAssignments'].errors?.['required']"
        >
          Please add an assignee.
        </div>
        <div
          class="assignees"
          *ngFor="let assignment of formGroup.controls['taskAssignments'].value"
        >
          <div class="assignee">
            <div class="assignee-icon">{{ assignment.initials }}</div>
            <div>
              {{ assignment?.user?.fullName ?? assignment?.group?.name }}
            </div>
          </div>
          <div
            class="assignees__delete"
            (click)="deleteAssignment(assignment)"
            matTooltip="Delete Assignment"
          >
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Due Date</strong></mat-label>
        <mat-form-field appearance="fill">
          <input
            formControlName="dueDate"
            matInput
            placeholder="Due Date"
            i18n-placeholder
            [matDatepicker]="picker"
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Priority</strong></mat-label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="priority">
            <mat-select-trigger *ngIf="formGroup.controls['priority'].value">
              <mat-icon
                *ngIf="formGroup.controls['priority'].value !== ''"
                class="selected-priority-icon"
                [svgIcon]="formGroup.controls['priority'].value + 'Priority'"
              ></mat-icon>
              {{ formGroup.controls['priority'].value }}
            </mat-select-trigger>
            <mat-option value=""></mat-option>
            <mat-option value="Low"
              ><mat-icon class="priority-icon" svgIcon="LowPriority"></mat-icon
              >Low</mat-option
            >
            <mat-option value="Medium"
              ><mat-icon
                class="priority-icon"
                svgIcon="MediumPriority"
              ></mat-icon
              >Medium</mat-option
            >
            <mat-option value="High"
              ><mat-icon class="priority-icon" svgIcon="HighPriority"></mat-icon
              >High</mat-option
            >
            <mat-option value="Critical"
              ><mat-icon
                class="priority-icon"
                svgIcon="CriticalPriority"
              ></mat-icon
              >Critical</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field block-status-change">
        <mat-checkbox
          formControlName="blocksStatusChange"
          color="primary"
          [checked]="task?.blocksStatusChange"
        >
          Block Status Change
        </mat-checkbox>
      </div>
      <div class="drawer__form-field" *ngIf="task.applicationId !== null">
        <mat-label><strong i18n>Application Status</strong></mat-label>
        <mat-form-field appearance="fill">
          <mat-select formControlName="applicationStatusId">
            <mat-option
              *ngFor="let status of statusList"
              [value]="status.statusId"
            >
              {{ status.statusName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Description</strong></mat-label>
        <mat-form-field appearance="fill">
          <textarea
            formControlName="description"
            matInput
            placeholder="Description"
            i18n-placeholder
          ></textarea>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Notes</strong></mat-label>
        <mat-form-field appearance="fill">
          <textarea
            formControlName="notes"
            matInput
            placeholder="Notes"
            i18n-placeholder
          ></textarea>
        </mat-form-field>
        <hr />
      </div>
      <div class="upload-files">
        <mat-label><strong>Attach File(s)</strong></mat-label>
        <table>
          <tr>
            <td>
              <button
                class="primary-button"
                mat-button
                (click)="uploadInput.click()"
                i18n
              >
                <mat-icon>upload</mat-icon>Upload
              </button>
              <input
                #uploadInput
                hidden
                type="file"
                (change)="uploadFile($event)"
                (click)="clearInputValue($event)"
              />
              <span *ngIf="fileToUpload !== null">
                {{ fileToUpload?.name }}
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div
        *ngIf="
          task?.attachedFiles !== null &&
          task?.attachedFiles !== undefined &&
          !addNew
        "
      >
        <table
          mat-table
          #statusChangesTable
          [dataSource]="dataSource"
          class="table-containermat-elevation-z8"
          aria-roledescription="Attached File"
          tabindex="0"
        >
          <ng-container matColumnDef="fileName">
            <th
              tabindex="0"
              aria-label="File Name"
              mat-header-cell
              *matHeaderCellDef
              i18n
            >
              File
            </th>
            <td tabindex="0" mat-cell *matCellDef="let file">
              {{ decodeFileName(file?.fileName) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="fileUploadDate">
            <th
              tabindex="0"
              aria-label="File Uploaded Date"
              mat-header-cell
              *matHeaderCellDef
              i18n
            >
              Upload Date
            </th>
            <td tabindex="0" mat-cell *matCellDef="let file">
              {{ file?.uploadDate | date: 'shortDate' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="buttonOptions">
            <th
              tabindex="0"
              aria-label="File Actions"
              class="center-header"
              mat-header-cell
              *matHeaderCellDef
              i18n
            >
              Actions
            </th>
            <td tabindex="0" mat-cell *matCellDef="let file">
              <div class="button-row center-items">
                <ng-container>
                  <button
                    mat-icon-button
                    i18n
                    (click)="deleteTaskFile(file)"
                    matTooltip="Delete File"
                    tabindex="0"
                    aria-hidden="false"
                    aria-live="polite"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </ng-container>

                <ng-container>
                  <button
                    mat-icon-button
                    (click)="downloadFile(file?.fileName)"
                    i18n
                    matTooltip="Download File"
                  >
                    <mat-icon>download</mat-icon>
                  </button>
                </ng-container>
                <ng-container>
                  <button
                    mat-icon-button
                    (click)="openFileRepository(file?.externalId)"
                    matTooltip="Open Documentum"
                    i18n
                  >
                    <mat-icon>public</mat-icon>
                  </button>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="fileHeaders; sticky: true"></tr>
          <tr mat-row *matRowDef="let app; columns: fileHeaders"></tr>
        </table>
      </div>
    </form>
  </div>
  <div class="drawer__actions" *ngIf="!loading">
    <button
      mat-button
      i18n
      class="drawer__actions__button secondary-button"
      (click)="closeDrawer()"
      aria-roledescription="close drawer button."
    >
      Cancel
    </button>
    <button
      mat-button
      i18n
      class="drawer__actions__button primary-button"
      [disabled]="!formGroup.valid || !formGroup.dirty"
      (click)="addNew ? createTask() : updateTask()"
    >
      {{ addNew ? 'Add Task' : 'Update Task' }}
    </button>
  </div>
</div>
