import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { AppUdrService } from './app-udr.service';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ApplicationFacade } from '@intellio/shared/state-mgmt';
import { take, takeUntil } from 'rxjs';
import { TenantModuleConfigService } from './tenant-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundUdrRefreshService extends BaseService {
  beginRefreshSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-info'],
  };
  beginRefreshMessage = 'Refreshing stale data...';
  beginRefreshAction = 'Close';

  refreshSuccessSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-success'],
  };
  refreshSuccessMessage = 'Refresh succeeded...';
  refreshSuccessAction = 'Close';

  refreshErrorSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-warn'],
  };
  refreshErrorMessage = 'Refresh process encountered an error...';
  refreshErrorAction = 'Close';

  refreshFailureSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-warn'],
  };
  refreshFailureMessage = 'Refresh process failed to complete...';
  refreshFailureAction = 'Close';

  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private snackBarService: MatSnackBar,
    private applicationFacade: ApplicationFacade,
    private tenantModuleConfigService: TenantModuleConfigService
  ) {
    super(client, configService, authService);
  }

  displayToast(config: MatSnackBarConfig, msg: string, action: string) {
    return this.snackBarService.open(msg, action, config);
  }

  refresh(appId, destroyed$) {
    this.tenantModuleConfigService
      .getModuleConfigs('UtilityDefinedRecords')
      .subscribe((data) => {
        var webhookName = data.data.find(
          (tmc) =>
            tmc.configId === 'RefreshWebhookName' &&
            tmc.moduleId === 'UtilityDefinedRecords'
        ).value;
        let openToast = this.displayToast(
          this.beginRefreshSnackbarConfig,
          this.beginRefreshMessage,
          this.beginRefreshAction
        );
        this.applicationFacade.dispatchUpdateApplicationUDRs(
          appId,
          webhookName
        );
        const successSub =
          this.applicationFacade.updateApplicationUDRsSucceeded$
            .pipe(take(1))
            .subscribe({
              error: (err) => {
                openToast.dismiss();
                openToast = this.displayToast(
                  this.refreshErrorSnackbarConfig,
                  this.refreshErrorMessage,
                  this.refreshErrorAction
                );
              },
              next: (res) => {
                openToast.dismiss();
                if (res) {
                  openToast = this.displayToast(
                    this.refreshSuccessSnackbarConfig,
                    this.refreshSuccessMessage,
                    this.refreshSuccessAction
                  );
                } else {
                  openToast = this.displayToast(
                    this.refreshFailureSnackbarConfig,
                    this.refreshFailureMessage,
                    this.refreshFailureAction
                  );
                }
                successSub.unsubscribe();
              },
            });
        const failSub =
          this.applicationFacade.updateApplicationUDRsSFailed$.subscribe({
            error: (err) => {
              openToast.dismiss();
              openToast = this.displayToast(
                this.refreshErrorSnackbarConfig,
                this.refreshErrorMessage,
                this.refreshErrorAction
              );
            },
            next: (res) => {
              openToast.dismiss();
              if (res) {
                openToast = this.displayToast(
                  this.refreshFailureSnackbarConfig,
                  this.refreshFailureMessage,
                  this.refreshFailureAction
                );
              } else {
                openToast = this.displayToast(
                  this.refreshFailureSnackbarConfig,
                  this.refreshFailureMessage,
                  this.refreshFailureAction
                );
              }
              failSub.unsubscribe();
            },
          });
      });
  }
}
