export class ScheduledNotificationInstance {
  configId: string;
  currentRecurrenceCount: number;
  userId: string;
  applicationId: string;
  nextRecurrence: string;
  emailTemplateBody: string;
  emailTemplateSalutation: string;
  emailTemplateClosing: string;
  emailTemplateSubject: string;
}
