import { ApplicationFileRepository } from './application-file-repository.model';

export class ApplicationFile {
  avDetail: string;
  avStatus: string;
  avTimestamp: string;
  directoryName: string;
  fileContentIsMissing: boolean;
  fileMimeType: string;
  fileName: string;
  fileTypeId: string;
  fileTypeName: string;
  fileUploadDate: string;
  forInternalUseOnly: boolean;
  fullName: string;
  isConfirmed: boolean;
  notes: string;
  sizeInBytes: number;
  uploadedFromEditor: boolean;
  uploaderFullName: string;
  uploaderId: string;
  fileRepositoryId: string;
  folderId: string;
  fileRepository: ApplicationFileRepository;
}
