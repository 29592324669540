import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { takeUntil } from 'rxjs/operators';

export const synchronizeHook = (
  functionName,
  synchronizedUpdates,
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.form
      .get(field.key as string)
      .valueChanges.pipe(takeUntil(destroyed$))
      .subscribe((newVal) => {
        const syncArgs = functionName.slice(1);
        const fieldName = syncArgs[0];
        if (fieldName.startsWith('applicationUDF:')) {
          const udfName = fieldName.split(':')[1];
          if (!synchronizedUpdates.applicationUDFs) {
            synchronizedUpdates.applicationUDFs = {};
          }

          synchronizedUpdates.applicationUDFs[udfName] = newVal;
        } else {
          synchronizedUpdates[fieldName] = newVal;
        }
      });
  };
};
