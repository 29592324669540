import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getChildObject, getFormRoot } from './form-data.utility';
import { takeUntil } from 'rxjs/operators';

export const filterPropertyTypesHook = (destroyed$): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const rootElement = getFormRoot(field);
    const allInitialOptions = field.props.options;
    let usingAutomaticSelection = false;

    const iogField = getChildObject(rootElement, 'intentOfGeneration');
    iogField?.formControl.valueChanges
      .pipe(takeUntil(destroyed$))
      .subscribe((value) => {
        field.props.optionMappings?.forEach((mapping) => {
          if (mapping.values.includes(value)) {
            field.props.options = mapping.options;
            if (mapping.options.length === 1) {
              // If there is only one possible value, use it
              usingAutomaticSelection = true;
              field.formControl.setValue(mapping.options[0].value);
            } else if (
              // If the new mapping does not include the current field value, set the field to null
              mapping.options.filter((o) => o.Value === field.formControl.value)
                .length === 0
            ) {
              field.formControl.setValue(null);
            }
            return;
          } else {
            field.props.options = allInitialOptions;
            if (usingAutomaticSelection) {
              // If the previous value filtered the options, set the field value to null
              // to prevent unintentional automatic selection of an incorrect property type
              field.formControl.setValue(null);
              usingAutomaticSelection = false;
            }
          }
        });
      });
  };
};
