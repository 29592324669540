import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { BusinessRole, BusinessRoleUser } from '@intellio/shared/models';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class BusinessRolesService extends BaseService {
  constructor(
    client: HttpClient,
    appConfigService: AppConfigService,
    authService: AuthService
  ) {
    super(client, appConfigService, authService);
  }

  getBusinessRolesFromAppType(appType) {
    const getApplicationTypeBusinessRolesUrl =
      '/api/applications/types/' + appType + '/businessRoles';
    return this.get<BusinessRole[]>(getApplicationTypeBusinessRolesUrl);
  }

  getApplicationBusinessRolesUser(appId) {
    const getApplicationBusinessRolesUserUrl =
      '/api/applications/' + appId + '/businessRoles';
    return this.get<BusinessRoleUser[]>(getApplicationBusinessRolesUserUrl);
  }

  getAllBusinessRoles() {
    const url = '/api/businessRole';
    return this.get<BusinessRole[]>(url);
  }

  updateBusinessRoles(appId, data) {
    const url = `/api/applications/${appId}/businessRoles`;
    return this.put<BusinessRole[]>(url, data).pipe(
      map((response) => {
        return response.data;
      })
    );
  }
}
