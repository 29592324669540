import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationTypeJurisdiction } from '@intellio/shared/models';

@Component({
  selector: 'itc-correct-type',
  templateUrl: './correct-type.component.html',
  styleUrls: ['./correct-type.component.scss'],
})
export class CorrectTypeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CorrectTypeComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  correctType: string;
  capacity: number;
  minCapacity: number;
  maxCapacity: number;
  jurisdictionId: string;
  jurisdictions: ApplicationTypeJurisdiction[];

  ngOnInit(): void {
    this.correctType = this.data.correctType;
    this.capacity = this.data.capacity;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  navigateToQueue(): void {
    this.router.navigateByUrl('/applications');
    this.dialogRef.close();
  }
}
