export class FilterConfig {
  hideAlerts: boolean;
  hideApplicationCreator: boolean;
  hideApplicationOrigin: boolean;
  hideApplicationType: boolean;
  hideApplicationsSubmittedAfter: boolean;
  hideApplicationsSubmittedBefore: boolean;
  hideBusinessRolesAndUsers: boolean;
  hideEnergySource: boolean;
  hideIntentOfGeneration: boolean;
  hideJurisdiction: boolean;
  hideOwner: boolean;
  hidePaymentBalance: boolean;
  hideSPOC: boolean;
  hideStatus: boolean;
  id: number;
  isExternal: boolean;
  hideStatusTimerDuration: boolean;
  hideOutOfOffice: boolean;
  hideActionRequired: boolean;
  hideApplicationCreatorUser: boolean;
}
