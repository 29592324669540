import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnergySource, IntellioHttpResponse } from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { map, mergeAll, reduce } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class EnergySourcesService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getEnergySources(
    typeId: string = '',
    jurisdictionId: string = ''
  ): Observable<IntellioHttpResponse<EnergySource[]>> {
    const url = '/api/energysources';
    let params = new HttpParams();
    params = params.append('typeId', typeId);
    params = params.append('jurisdictionId', jurisdictionId);
    const options = {
      params: params,
    };

    return this.get<EnergySource[]>(url, options);
  }
}
