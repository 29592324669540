import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionRequiredAlertRule } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ActionRequiredAlertsService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  updateActionRequiredAlert(alertData) {
    const url = '/api/applications/actionrequired/alert';
    return this.put(url, alertData);
  }
}
