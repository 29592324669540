import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Application, TaskTemplate } from '@intellio/shared/models';
/* eslint-disable @nx/enforce-module-boundaries  */
// bug 66468 all imports
import {
  NotificationService,
  AppTasksService,
} from '@intellio/shared/services';
import { ApplicationFacade } from '@intellio/shared/state-mgmt';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'itc-app-tasks-template',
  templateUrl: './app-tasks-template.component.html',
  styleUrls: ['./app-tasks-template.component.scss'],
})
export class AppTasksTemplateComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<AppTasksTemplateComponent>,
    private appTasksService: AppTasksService,
    private notificationService: NotificationService,
    private applicationFacade: ApplicationFacade
  ) {}
  destroyed$ = new Subject();
  newTemplate: TaskTemplate;
  templateControl = new UntypedFormControl();
  loading = true;
  currentApplication: Application;
  templates: TaskTemplate[];

  ngOnInit(): void {
    this.applicationFacade.currentApplication$
      .pipe(take(1))
      .subscribe((data) => {
        this.currentApplication = data;
      });
    this.loading = false;
    this.templates = this.data.appTemplates;
  }
  applyTemplate() {
    const template = this.templates.find(
      (template) => template.id === this.templateControl.value
    );
    this.newTemplate = {
      applicationTypeId: null,
      blocksStatusChange: template.blocksStatusChange,
      description: template.description,
      displayName: template.displayName,
      durationInDays: template.durationInDays,
      dueDate: template.dueDate,
      id: template.id,
      notes: template.notes,
      priority: template.priority,
      status: template.status,
      taskAssignments: template.taskAssignments,
    };
    this.dialogRef.close({ currTemplate: this.newTemplate });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
