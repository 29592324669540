export class PresetGroupDetailsField {
  fieldKey: string;
  groupDetailId: number | string;
  value: any;
  title?: string;
  pattern?: string;
  optionValues?: unknown[];
  optionLabels?: string[];
  displayOrder?: number;
  format?: string;
}
