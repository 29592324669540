import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getChildObject } from './form-data.utility';
import { switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export const prefillESSDetailsHook = (sharedEss$, destroyed$): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.formControl.valueChanges
      .pipe(
        switchMap((val) => {
          return forkJoin<[any, any]>([of(val), sharedEss$]);
        }),
        takeUntil(destroyed$)
      )
      .subscribe(([modelNum, ess]) => {
        if (modelNum) {
          const currEss = ess.find((Ess) => {
            return Ess.modelNumber === modelNum;
          });
          (field.form.controls as any).capacity.setValue(
            currEss.nameplateEnergyCapacity
          );
        }
      });
  };
};
