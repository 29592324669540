import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageListResponse } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { MessageListRequestParams } from '@intellio/shared/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesService extends BaseService {
  readonly PAGE_SIZE = 25;
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getMessageSubject$ = new Subject<MessageListRequestParams>();

  getMessageSummaries(params: MessageListRequestParams) {
    return this.get<MessageListResponse>(`/api/messages`, {
      params,
    });
  }
}
