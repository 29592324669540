import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class PVWattsAPIService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getEstimatedGrossAnnualEnergyProduction(data) {
    const url = '/api/pvwatts';
    const azimuth = data.azimuth;
    const system_capacity = data.systemCapacity;
    const losses = data.losses;
    const tilt = data.tilt;
    const array_type = data.arrayType;
    const module_type = data.moduleType;
    const address = data.address;

    var updatedURL = url + `?system_capacity=${system_capacity}`;
      updatedURL += `&azimuth=${azimuth}`;
      updatedURL += `&losses=${losses}`;
      updatedURL += `&tilt=${tilt}`;
      updatedURL += `&array_type=${array_type}`;
      updatedURL += `&module_type=${module_type}`;
      updatedURL += `&address=${address}`;
      updatedURL += `&inv_eff=96`;
      updatedURL += `&radius=0`;
      updatedURL += `&gcr=0.4`;
      updatedURL += `&timeframe=monthly`;
      updatedURL += `&dataset=nsrdb`;
      updatedURL += `&dc_ac_ratio=1.2`;

    return this.get(updatedURL);
  }
}
