import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WrapperChangeDetectNotifierService {
  private submitClicked = new Subject<void>();
  readonly submitClicked$ = this.submitClicked.asObservable();

  formSubmitClicked() {
    this.submitClicked.next(null);
  }
}
