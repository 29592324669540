import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationStatusChange } from '@intellio/shared/models';
import { ApplicationStatusEdit } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusChangeService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getStatusChanges(appId) {
    const url = `/api/applications/${appId}/status/changes`;
    return this.get<ApplicationStatusChange[]>(url, {});
  }

  editStatusChange(
    appId: string,
    statusChangeId: number,
    data: ApplicationStatusEdit
  ) {
    const updateStatusChangeUrl = `/api/applications/${appId}/status/changes/${statusChangeId}`;
    return this.put<string>(updateStatusChangeUrl, data);
  }

  deleteStatusChange(
    appId: string,
    statusChangeId: number,
    deleteAssociatedMessages: boolean = false
  ) {
    const url = `/api/applications/${appId}/status/changes/${statusChangeId}`;
    let params = new HttpParams();
    params = params.append(
      'deleteAssociatedMessages',
      deleteAssociatedMessages.toString()
    );
    const options = {
      params: params,
    };
    return this.delete<string>(url, options);
  }
}
