export class ApplicationCreateRequest {
  accountNumber: string;
  addressCity: string;
  addressLineOne: string;
  addressLineTwo: null;
  addressState: string;
  addressZIP: string;
  applicationCapacity: number;
  customerFullName: string;
  energySourceId: string;
  generatorTypeId: string;
  intentOfGenerationId: string;
  jurisdiction: string;
  jurisdictionId: string;
  latitude: number;
  longitude: number;
  projectName: string;
  schemaName: string;
  typeId: string;
  content: any;
}
