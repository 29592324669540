import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-formly-accordion',
  styleUrls: ['./formly-accordion.component.scss'],
  template: `
    <div [id]="'accordion__' + props.step" (click)="props.onClick(field.key)">
      <mat-expansion-panel
        [expanded]="props.active === true"
        #formAccordion
        class="formly-accordion"
        [disabled]="props.isEmpty"
      >
        <mat-expansion-panel-header class="formly-accordion__header">
          <div>
            <mat-panel-title>
              <div
                [ngClass]="{
                  'formly-accordion__header-content': true,
                  sublevel: props.isSublevel
                }"
              >
                <mat-icon
                  *ngIf="
                    !props.legacyReadOnlyMode &&
                    !field.options.formState.isReadOnlyMode &&
                    props.completed !== null &&
                    props.completed
                  "
                  [style]="props.completed ? 'color: #009D3F' : 'color: gray'"
                  class="formly-accordion__header-content__icon"
                >
                  check_circle_outline
                </mat-icon>
                <mat-icon
                  *ngIf="
                    !field.options.formState.isReadOnlyMode &&
                    props.completed !== null &&
                    !props.completed
                  "
                  [style]="!props.completed ? 'color: #d93224' : 'color: gray'"
                  class="formly-accordion__header-content__icon"
                >
                  highlight_off
                </mat-icon>
                <mat-icon
                  *ngIf="
                    !field.options.formState.isReadOnlyMode &&
                    props.completed === null
                  "
                  [style]="
                    props.completed === null ? 'color: gray' : 'color: gray'
                  "
                  class="formly-accordion__header-content__icon"
                >
                  do_not_disturb_on
                </mat-icon>
                <div>
                  <div *ngIf="props.step" class="formly-accordion__step">
                    STEP {{ props.step }}
                  </div>
                  <div
                    [ngClass]="{
                      'formly-accordion__title': true,
                      sublevel: props.isSublevel
                    }"
                  >
                    {{ props.title }}
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </div>
        </mat-expansion-panel-header>
        <div class="formly-accordion__content">
          <ng-container #fieldComponent></ng-container>
        </div>
      </mat-expansion-panel>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyAccordionComponent
  extends IntellioFormlyWrapperComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  @ViewChild(MatExpansionPanel)
  accordion: MatExpansionPanel;

  ngAfterViewInit() {
    if (!this.props.onClick) {
      // Set to identity function
      // if not explicitly set
      this.props.onClick = (key: string) => key;
    } else {
      const handler = this.props.onClick;
      // decorate click handler with reference to the accordion
      const func = (panel: MatExpansionPanel) => (key: string) => {
        handler(key, panel, this.cd);
      };

      this.props.onClick = func(this.accordion);
    }
  }

  ngOnInit() {
    this.props.completed = null;
    this.field.formControl.statusChanges
      .pipe(
        startWith(this.field.formControl.status),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe((val) => {
        if (
          val === 'VALID' &&
          (this.field.formControl.touched || this.props.completed === null)
        ) {
          this.props.completed = true;
        } else if (val === 'INVALID' && this.field.formControl.touched) {
          this.props.completed = false;
        }

        this.cd.markForCheck();
      });
    this.initChangeDetectSub();
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
