import { HttpClient } from '@angular/common/http';
import { Injectable, ElementRef } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { Observable } from 'rxjs';
import {
  ApplicationFile,
  FileUploadWithMetadata,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { Activity } from '@intellio/shared/models';
@Injectable({
  providedIn: 'root',
})
export class ChangesService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }
  applicationTableElementRef: ElementRef;

  uploadBulkUDFChanges(
    file: File
  ): Observable<IntellioHttpResponse<ApplicationFile>> {
    const fileWithMetadata: FileUploadWithMetadata = {
      file,
      fileName: file.name,
      fileMimeType: file.type,
      fileTypeId: '',
    };
    const fileExtension = file.name.split('.').pop();
    return this.uploadFiles(`/api/changes/exceldata/udfdata/${fileExtension}`, [
      fileWithMetadata,
    ]);
  }

  getApplicationActivityChanges(activityType) {
    const url =
      '/api/applications/activities?includeActivities=' +
      activityType +
      '&skip=0&take=25';
    return this.get<Activity[]>(url, {});
  }
}
