import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { startWith, takeUntil } from 'rxjs/operators';
import { setValueOnObjectFromStringProperty } from './form-data.utility';

export const formModelContentHook = (
  functionName,
  formContent,
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    // set the default value if one exists
    // // don't set a value if one is already there
    field.form
      .get(field.key as string)
      .valueChanges.pipe(startWith(field.defaultValue), takeUntil(destroyed$))
      .subscribe((newVal) => {
        const syncArgs = functionName.slice(1);
        const fieldName = syncArgs[0];
        setValueOnObjectFromStringProperty(fieldName, formContent, newVal);
      });
  };
};
