import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { PermissionService } from './permission.service';

@Injectable({ providedIn: 'root' })
export class PermissionsRequiredGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.permissionService.doesUserHaveAnyPermissions(
      route.data.requiredPermissions
    )
      ? true
      : this.router.parseUrl('/not-authorized');
  }
}
