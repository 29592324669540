export class AggregationTableRow {
  createdBy: string;
  createdDateTime: string;
  gridHierarchyLevelDERAggregationTableId: string;
  id: string;
  modifiedBy: string;
  modifiedDateTime: string;
  order: number;
  rowData: string;
}

export class AggregationTable {
  createdBy: null;
  createdDateTime: string;
  gridHierarchyLevelDERAggregationId: string;
  gridHierarchyLevelDERAggregationTableRows: AggregationTableRow[];
  id: string;
  modifiedBy: string;
  modifiedDateTime: string;
  order: number;
  tableColumns: string;
  tableHeader: string;
}

export class GridHierarchyLevelDERAggregationCharacteristic {
  key: string;
  value: unknown;
}

export class GridHierarchyLevelDERAggregation {
  createdBy: string;
  createdDateTime: string;
  gridHierarchyDERAggregationId: string;
  gridHierarchyLevelComponentTypeId: string;
  gridHierarchyLevelDERAggregationCharacteristics: GridHierarchyLevelDERAggregationCharacteristic[];
  gridHierarchyLevelDERAggregationTables: AggregationTable[];
  gridHierarchyLevelName: string;
  id: string;
  livenedDER: number;
  modifiedBy: string;
  modifiedDateTime: string;
  order: number;
  pendingDER: number;
  aggregateDER: number | string;
  showBody: boolean;
}

export class Aggregation {
  aggregationErrorMessage: null;
  applicationId: string;
  createdBy: string;
  createdDateTime: string;
  gridHierarchyLevelDERAggregations: GridHierarchyLevelDERAggregation[];
  id: string;
  modifiedBy: string;
  modifiedDateTime: string;
}
