import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { Token } from '@intellio/shared/models';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  token: Token;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  token: null,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.getAuthSuccess, (state, { token }) => ({ ...state, token })),
  on(AuthActions.getAuthFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
