import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  VIEW_HEADER_FEATURE_KEY,
  ViewHeadersState,
} from './view-header.reducer';

// Lookup the 'ViewHeader' feature state managed by NgRx
export const getViewHeaderState = createFeatureSelector<ViewHeadersState>(
  VIEW_HEADER_FEATURE_KEY
);

export const getApplicationHeadersLoaded = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.appHeadersloaded
);

export const getQueueHeadersLoaded = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.queueHeadersloaded
);

export const getRelatedAppsHeadersLoaded = createSelector(
  getViewHeaderState, 
  (state: ViewHeadersState) => state.appHeadersloaded
)

export const getViewHeaderError = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.error
);

export const getApplicationHeaders = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.applicationHeaders
);

export const getQueueHeaders = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.queueHeaders
);

export const getUDFQueueHeaders = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.udfQueueHeaders
);

export const getRelatedAppsHeaders = createSelector(
  getViewHeaderState,
  (state: ViewHeadersState) => state.relatedAppsHeaders
);