import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrintingService } from '@intellio/shared/services';

@Component({
  selector: 'itc-payment-slip-modal',
  templateUrl: './payment-slip-modal.component.html',
  styleUrls: ['./payment-slip-modal.component.scss'],
})
export class PaymentSlipModalComponent {
  @ViewChild('printSection') printSection: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private printingService: PrintingService,
    public dialogRef: MatDialogRef<PaymentSlipModalComponent>
  ) {}

  printSlip() {
    const printContents = this.printSection.nativeElement.innerHTML;
    const styles = Array.from(
      document.querySelectorAll('link[rel="stylesheet"], style')
    )
      .map((link) => link.outerHTML)
      .join('\n');

    this.printingService.print(printContents, styles);
  }
}
