import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ApplicationFacade } from '@intellio/shared/state-mgmt';
import { take } from 'rxjs';
import { TenantModuleConfigService } from './tenant-module-config.service';
import { Application } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BackgroundFileRepositoryService extends BaseService {
  refreshErrorSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-warn'],
  };
  refreshErrorMessage = 'Error while fetching missing file repository folder';
  refreshErrorAction = 'Close';

  refreshSuccessSnackbarConfig: MatSnackBarConfig = {
    duration: 1000 * 3,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-success'],
  };
  refreshSuccessMessage = 'File folder linked successfully';
  refreshSuccessAction = 'Close';

  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private snackBarService: MatSnackBar,
    private applicationFacade: ApplicationFacade,
    private tenantModuleConfigService: TenantModuleConfigService
  ) {
    super(client, configService, authService);
  }

  displayToast(config: MatSnackBarConfig, msg: string, action: string) {
    return this.snackBarService.open(msg, action, config);
  }

  refresh(application: Application) {
    this.tenantModuleConfigService
      .getTenantModuleConfigs()
      .subscribe((data) => {
        var webhookName = data.data.find(
          (tmc) =>
            tmc.configId === 'RefreshWebhookName' &&
            tmc.moduleId === 'FileManagement'
        ).value;
        var folderUDFId = data.data.find(
          (tmc) =>
            tmc.configId === 'FolderUDFId' && tmc.moduleId === 'FileManagement'
        ).value;
        var repositoryUDF = application.applicationUDFDetails.find(
          (udf) => udf.id === folderUDFId
        );
        var workOrderUDF = application.applicationUDFDetails.find(
          (udf) => udf.id === 'WorkOrderNumber'
        );
        let changedUDFToSend = 'ServiceRequestNumber';
        // decide on whether to update based on SR # or WO #
        if (workOrderUDF != null) {
          changedUDFToSend = 'WorkOrderNumber';
        }

        // only refresh legacy apps
        if (
          repositoryUDF == null &&
          application.legacyId != null &&
          application.categoryId == 'NewBusiness'
        ) {
          let openToast: MatSnackBarRef<TextOnlySnackBar>;
          this.applicationFacade.dispatchUpdateApplicationFileRepository(
            application.id,
            changedUDFToSend,
            application.system.jurisdictionId,
            webhookName
          );
          const successSub =
            this.applicationFacade.updateApplicationFileRepositorySucceeded$.subscribe(
              {
                error: (err) => {
                  openToast = this.displayToast(
                    this.refreshErrorSnackbarConfig,
                    this.refreshErrorMessage,
                    this.refreshErrorAction
                  );
                },
                next: (res) => {
                  if (res) {
                    openToast = this.displayToast(
                      this.refreshSuccessSnackbarConfig,
                      this.refreshSuccessMessage,
                      this.refreshSuccessAction
                    );
                  }
                  successSub.unsubscribe();
                },
              }
            );
          const failSub =
            this.applicationFacade.updateApplicationFileRepositoryFailed$.subscribe(
              {
                error: (err) => {
                  openToast = this.displayToast(
                    this.refreshErrorSnackbarConfig,
                    this.refreshErrorMessage,
                    this.refreshErrorAction
                  );
                },
                next: (res) => {
                  if (res) {
                    openToast = this.displayToast(
                      this.refreshErrorSnackbarConfig,
                      this.refreshErrorMessage,
                      this.refreshErrorAction
                    );
                  } else {
                    openToast = this.displayToast(
                      this.refreshErrorSnackbarConfig,
                      this.refreshErrorMessage,
                      this.refreshErrorAction
                    );
                  }
                  failSub.unsubscribe();
                },
              }
            );
        }
      });
  }
}
