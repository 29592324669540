import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-formly-panel',
  styleUrls: ['./section.component.scss'],
  template: `
    <div class="section">
      <div class="section__form">
        <div class="section__title">{{ props.title }}</div>
        <ng-container #fieldComponent></ng-container>
        <div></div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent
  extends IntellioFormlyWrapperComponent
  implements OnInit
{
  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  ngOnInit() {
    this.initChangeDetectSub();
  }
}
