import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingBarService {
  private _activeRequestCount$ = new BehaviorSubject<number>(0);
  public readonly activeRequestCount$ =
    this._activeRequestCount$.asObservable();

  markRequestStarted(): void {
    // Set timeout makes the count update asynchronous
    // So cases of parallel network requests starting/ending
    // within the same change detection cycle will not cause a ChangedAfterChecked error
    setTimeout(() => {
      this._activeRequestCount$.next(this._activeRequestCount$.value + 1);
    }, 0);
  }

  markRequestedEnded(): void {
    setTimeout(() => {
      if (this._activeRequestCount$.value > 0) {
        // Set timeout makes the count update asynchronous
        // So cases of parallel network requests starting/ending
        // within the same change detection cycle will not cause a ChangedAfterChecked error
        this._activeRequestCount$.next(this._activeRequestCount$.value - 1);
      }
    }, 0);
  }
}
