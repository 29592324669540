import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSecondsAsDays',
})
export class FormatSecondsAsDaysPipe implements PipeTransform {
  transform(input: any, makePositive: boolean = false): number {
    if (!isNaN(input)) {
      const seconds = parseFloat(input);
      const days = seconds / 86400;
      const roundedDays = Math.ceil(days);
      if (makePositive) {
        return Math.abs(roundedDays);
      } else {
        return roundedDays;
      }
    }
  }
}
