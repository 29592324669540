import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationActivities, Notifications } from '@intellio/shared/models';
import { BaseService } from './base-service.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseService {
  readonly PAGE_SIZE = 25;
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  readNotification(id) {
    const getNotificationsUrl = `/api/notifications/${id}`;
    const data = {
      isRead: true,
    };
    return this.put(getNotificationsUrl, data);
  }

  readSelectedNotifications(notificationList) {
    const getNotificationsUrl = '/api/notifications';
    const data = {
      NotificationIds: notificationList,
      IsUnread: false,
    };
    return this.put(getNotificationsUrl, data);
  }

  unreadSelectedNotifications(notificationList) {
    const getNotificationsUrl = '/api/notifications';
    const data = {
      NotificationIds: notificationList,
      IsUnread: true,
    };
    return this.put(getNotificationsUrl, data);
  }

  deleteSelectedNotifications(notificationList) {
    const getNotificationsUrl = '/api/notifications/delete';
    return this.put(getNotificationsUrl, notificationList);
  }

  getNotifications(skip: number, take: number) {
    const url = '/api/notifications';
    const notificationParams = { skip, take };
    const config = { params: notificationParams, ignoreLoadingBar: true };

    return this.get<Notifications>(url, config);
  }
  getNotificationsEndpoint(params, endpoint) {
    const url = '/api/notifications/' + endpoint;
    const config = { params: params };

    return this.get<NotificationActivities>(url, config);
  }
  getDeliveryStatuses() {
    const url = '/api/enums/notificationdeliverystatuses';
    return this.get<string[]>(url);
  }
  getInboxDeliveryStatuses() {
    const url = '/api/enums/inboxdeliverystatuses';
    return this.get<string[]>(url);
  }
  getNotificationTypes() {
    const url = '/api/notifications/types';
    return this.get<string[]>(url);
  }
  getApplicationTypes() {
    const url = '/api/applications/types';
    return this.get<string[]>(url);
  }
}
