import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileType, FileUploadWithMetadata } from '@intellio/shared/models';
// bug 66468
import { ApplicationFilesService } from '@intellio/shared/services'; // eslint-disable-line
import { AccountFacade, ApplicationFacade } from '@intellio/shared/state-mgmt'; // eslint-disable-line
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-formly-field-file-type',
  template: `
    <div [id]="id" class="file-upload-field">
      <input
        type="file"
        hidden
        [formControl]="control"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileUpload
        [required]="props.required"
      />
      <div class="file-upload-title">
        <strong>{{ props.title }}</strong>
        <span
          *ngIf="props.required"
          [ngClass]="{
            'required-asterisk': true,
            'ng-error': control.invalid && control.pristine === false
          }"
        >
          *
        </span>
      </div>
      <mat-form-field class="notes-form-field">
        <mat-label>File Notes - add before uploading (optional)</mat-label>
        <input
          type="text"
          i18n-placeholder
          matInput
          [formControl]="notesControl"
        />
      </mat-form-field>
      <div class="file-upload" *ngIf="!isLoading">
        <button
          type="button"
          class="primary-button upload-button"
          (click)="fileUpload.click()"
          matTooltip="Upload a file"
          [disabled]="
            props.disabled ||
            (control.value !== null && control.value !== undefined)
          "
        >
          <mat-icon>upload</mat-icon>
        </button>
        <div>{{ fileNameControl.value || 'Select a file to upload' }}</div>
        <button
          mat-icon-button
          *ngIf="fileNameControl.value"
          (click)="deleteFile()"
          matTooltip="Remove File"
        >
          <mat-icon class="remove-icon">close</mat-icon>
        </button>
      </div>
      <div class="in-place-spinner-wrapper" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  private destroyed$ = new Subject();
  constructor(
    private appFilesService: ApplicationFilesService,
    private accountFacade: AccountFacade,
    private applicationFacade: ApplicationFacade,
    private cd: ChangeDetectorRef,
    private snackBarService: MatSnackBar
  ) {
    super();
  }

  control: UntypedFormControl;
  notesControl = new UntypedFormControl();
  fileNameControl = new UntypedFormControl();
  fileTypes: FileType[] = [];

  isLoading: boolean;
  uploaderId: string;
  uploaderName: string;
  userIsExternal: boolean;
  applicationId: string;

  ngOnInit() {
    this.isLoading = false;
    this.control = this?.formControl as UntypedFormControl;
    if (this.control.value != null) {
      this.field.options.formState.files$.subscribe((files) => {
        const pastUpload = files.find((f) => f.fileName === this.control.value);
        if (pastUpload) {
          this.notesControl.setValue(pastUpload.notes);
          this.notesControl.disable();
          this.fileNameControl.setValue(pastUpload.fileName);
          this.fileNameControl.disable();
        } else {
          this.control.setValue(null);
          this.cd.markForCheck();
        }
      });
    }
    this.accountFacade.currentUser$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        this.uploaderId = user.id;
        this.uploaderName = user.fullName;
        this.userIsExternal = user.isExternal;
      });
    this.applicationFacade.currentApplication$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((app) => {
        this.applicationId = app.id;
      });
  }

  generateFileName(fileTypeId: string) {
    if (this.fileTypes.length > 0) {
      const fileType = this.fileTypes.filter((val) => {
        return val.id == fileTypeId;
      });
      return fileType[0].name;
    }
    return 'Other';
  }

  deleteFile() {
    this.appFilesService
      .deleteFile(this.applicationId, this.control.value, false)
      .subscribe(() => {
        this.notesControl.setValue(null);
        this.control.setValue(null);
        this.fileNameControl.setValue(null);
        this.notesControl.enable();
        this.control.enable();
        this.cd.detectChanges();
      });
  }

  uploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      console.log('FileUpload -> files', fileList);
    }
  }

  onFileSelected(event) {
    const file = event.target.files[0];
    event.target.value = null;
    if (file) {
      this.isLoading = true;
      const metadata = new FileUploadWithMetadata();
      metadata.file = file;
      if (file.type !== '') {
        metadata.fileMimeType = file.type;
      } else {
        // The browser didn't recognize the file type.
        // However, we still require a MimeType, so we use a placeholder type.
        metadata.fileMimeType = 'unknown';
      }

      metadata.notes = this.notesControl.value ? this.notesControl.value : '';
      metadata.forInternalUseOnly = !this.userIsExternal;
      metadata.fileTypeId = this.props.fileType ? this.props.fileType : 'Other';

      metadata.fileName = file.name;
      metadata.uploaderId = this.uploaderId;
      metadata.uploaderFullName = this.uploaderName;
      metadata.fileUploadDate = new Date().toDateString();

      this.appFilesService.uploadFile(this.applicationId, metadata).subscribe(
        (uploadedFile) => {
          this.control.setValue(uploadedFile.data.fileName);
          this.fileNameControl.setValue(uploadedFile.data.fileName);
          this.notesControl.disable();
          this.isLoading = false;
        },
        (err) => {
          this.control.setValue(null);
          this.fileNameControl.setValue(null);
          this.cd.markForCheck();
          this.snackBarService.open('Error uploading file', 'Close', {
            duration: 10000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ['snackbar-warn'],
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
