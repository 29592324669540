import { createReducer, on } from '@ngrx/store';
import * as AccountActions from './account.actions';
import {
  ApplicationCategory,
  StatusChangeSchema,
  User,
  SimpleUser,
} from '@intellio/shared/models';

export const ACCOUNT_FEATURE_KEY = 'account';

export interface AccountState {
  currentUser: User;
  currentUserId: string;
  externalUsers: User[];
  simpleExternalUsers: SimpleUser[];
  externalUsersLoaded: boolean;
  simpleExternalUsersLoaded: boolean;
  internalUsers: User[];
  internalUsersLoaded: boolean;
  availableCategories: ApplicationCategory[];
  statusChangeSchemas: StatusChangeSchema[];
}

export interface AccountPartialState {
  readonly [ACCOUNT_FEATURE_KEY]: AccountState;
}

export const initialState: AccountState = {
  currentUser: undefined,
  currentUserId: '',
  externalUsers: [],
  externalUsersLoaded: false,
  simpleExternalUsers: [],
  simpleExternalUsersLoaded: false,
  internalUsers: undefined,
  internalUsersLoaded: false,
  availableCategories: [],
  statusChangeSchemas: [],
};

export const reducer = createReducer(
  initialState,
  on(AccountActions.getAccountSuccess, (state, { currentUser }) => {
    state = {
      ...state,
      currentUser,
      currentUserId: currentUser.id,
    };
    return state;
  }),
  on(AccountActions.logoutAccount, (state) => {
    state = {
      ...state,
      currentUser: null,
      currentUserId: '',
    };
    return state;
  }),
  on(AccountActions.getExternalUsersSuccess, (state, { users }) => {
    state = {
      ...state,
      externalUsers: users,
      externalUsersLoaded: true,
    };
    return state;
  }),
  on(AccountActions.getSimpleExternalUsersSuccess, (state, { users }) => {
    state = {
      ...state,
      simpleExternalUsers: users,
      simpleExternalUsersLoaded: true,
    };
    return state;
  }),
  on(AccountActions.getInternalUsersSucceeded, (state, { internalUsers }) => {
    state = {
      ...state,
      internalUsers: internalUsers,
      internalUsersLoaded: true,
    };
    return state;
  }),
  on(
    AccountActions.getAvailableCategoriesForUserSuccess,
    (state, { availableCategories }) => {
      state = {
        ...state,
        availableCategories,
      };
      return state;
    }
  ),
  on(
    AccountActions.updateAccountCurrentAppCategorySucceeded,
    (state, { appCategory }) => {
      state = {
        ...state,
        currentUser: {
          ...state.currentUser,
          activeCategoryId: appCategory.id,
        },
      };
      return state;
    }
  ),
  on(AccountActions.getStatusChangeSchemasSuccess, (state, { schemas }) => {
    state = {
      ...state,
      statusChangeSchemas: schemas,
    };
    return state;
  })
);
