export class TaskTemplate {
  applicationTypeId: string;
  blocksStatusChange?: boolean;
  description: string;
  displayName: string;
  durationInDays?: number;
  dueDate?: Date;
  id?: number;
  notes: string;
  priority: string;
  status: string;
  taskAssignments?: string[];
}
