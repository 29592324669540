import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { combineLatest, filter, startWith } from 'rxjs';
import { getChildObject, getFormRoot } from './form-data.utility';

export const displayStorageRebateCalculation = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    const numUnitsObj = getChildObject(p, 'numberOfUnits');
    const numberOfUnits = numUnitsObj.formControl.value;
    const capacityObj = getChildObject(p, 'capacity');
    const capacity = capacityObj.formControl.value;
    const enrollNetMeteringObj = getChildObject(
      getFormRoot(field),
      'netMeteringEnrollment'
    );

    const enrollNetMetering = enrollNetMeteringObj.formControl.value;
    combineLatest<[number, number, number]>([
      numUnitsObj.formControl.valueChanges.pipe(
        filter((v) => v != null),
        startWith(numUnitsObj.formControl.value)
      ),
      capacityObj.formControl.valueChanges.pipe(
        filter((v) => v != null),
        startWith(capacityObj.formControl.value)
      ),
      enrollNetMeteringObj.formControl.valueChanges.pipe(
        filter((v) => v != null && v != undefined),
        startWith(enrollNetMeteringObj.formControl.value)
      ),
    ]).subscribe(([numberOfUnits, capacity, enrollNetMetering]) => {
      field.formControl.setValue(
        `${numberOfUnits} units * ${capacity}kW * $${enrollNetMetering}`
      );
    });
  };
};
