import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service.service";
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { Application } from "@intellio/shared/models";

@Injectable({
    providedIn: 'root',
})
export class ProjectsService extends BaseService {
    constructor(
        protected client: HttpClient,
        protected configService: AppConfigService,
        protected authService: AuthService
    ) {
        super(client, configService, authService);
    }
    
    getProjectApplications(projectId: number) {
        const projectAppsUrl = 
            `/api/projects/${projectId}/applications`;
        return this.get<Application[]>(projectAppsUrl);
    }

    updateApplicationProjects(removeLink: boolean, newProjectId: number, applicationIds: string[], initialAppId?: string) {
        const appProjectUrl = 
            `/api/projects/applications`; 
        return this.post<string>(appProjectUrl, { removeLink, newProjectId, applicationIds, initialAppId });
    }
}