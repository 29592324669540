import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { tap } from 'rxjs/operators';

export const formatFein = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.formControl.valueChanges
      .pipe(
        tap((value) => {
          const newValue = interpolateValue(value);

          // To avoid infinite loop
          if (newValue !== value) {
            // Set value without emitting another change event
            field.formControl.setValue(newValue, { emitEvent: false });
          }
        })
      )
      .subscribe();
  };
};

function interpolateValue(value: string): string {
  if (!value) {
    return value;
  }
  // Remove non-digits
  const sanitizedValue = value.replace(/[^0-9]/g, '');

  // Limit to 9 digits
  const truncatedValue = sanitizedValue.substring(0, 9);

  if (truncatedValue.length <= 2) {
    return truncatedValue;
  }

  // Format as "XX-XXXXXXX"
  return truncatedValue.substring(0, 2) + '-' + truncatedValue.substring(2);
}
