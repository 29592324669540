import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'precisionDecimal',
})
export class PrecisionDecimalPipe implements PipeTransform {
  transform(input: string | number): string | number {
    if (input) {
      const strNum = input.toString();
      if (strNum.indexOf('.') !== -1) {
        const removeTrailingZeros = strNum.replace(/0*$/, '');
        if (
          removeTrailingZeros.indexOf('.') ===
          removeTrailingZeros.length - 1
        ) {
          // if we have stripped off all digits right of the mantissa,
          // drop the decimal point e.g 8.00 -> 8. -> 8
          return removeTrailingZeros.replace('.', '');
        }
      }
      // whole number; do nothing
      return input;
    }
  }
}
