import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppStatusTimer,
  AppStatusTimerConfig,
  AppStatusTimerExtension,
  AppTypeStatusJurisdictionTimerConfig,
  CreateAppStatusTimerExtensionRequest,
  CreateAppStatusTimerNotificationsRequest,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppStatusTimersService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getStatusTimers(appId) {
    const url = `/api/applications/${appId}/statustimers`;
    return this.get<AppStatusTimer[]>(url);
  }

  getInactiveTimersConfiguration(appId) {
    const url = `/api/applications/${appId}/statustimers/configurations`;
    return this.get<AppStatusTimerConfig[]>(url);
  }

  getStatusTimerExtensions(timerId) {
    const url = `/api/applications/statustimers/${timerId}/extensions`;
    return this.get<AppStatusTimerExtension[]>(url);
  }

  updateStatusTimerExtensions(applicationId, timerId, extensionData) {
    const url = `/api/applications/${applicationId}/statustimers/${timerId}/extensions/sync`;
    return this.put<CreateAppStatusTimerExtensionRequest>(url, extensionData);
  }

  updateStatusTimerNotifications(applicationId, timerId, timerData) {
    const url = `/api/applications/${applicationId}/statustimers/${timerId}`;
    return this.put<CreateAppStatusTimerNotificationsRequest>(url, timerData);
  }

  getTypeStatusJurisdictionTimerConfigs() {
    const url = `/api/apptypestatusjurisdictionconfig`;
    return this.get<AppTypeStatusJurisdictionTimerConfig[]>(url);
  }
}
