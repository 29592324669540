import { ApplicationFile } from './application-file.model';

export class ApplicationFolder {
  folderId: string;
  folderName: string;
  folderUploadDate: string;
  uploaderFullName: string;
  uploaderId: string;
  readonly: boolean;
  editMode: boolean;
  foldersExpanded: boolean = true;
  files: ApplicationFile[];
}
