import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'itc-import-preset-modal',
  templateUrl: './import-preset-modal.component.html',
  styleUrls: ['./import-preset-modal.component.scss'],
})
export class ImportPresetModalComponent {
  constructor(public dialogRef: MatDialogRef<ImportPresetModalComponent>) {}

  closeModal(): void {
    this.dialogRef.close(false);
  }

  importPresets(): void {
    this.dialogRef.close(true);
  }
}
