<div>
  <table class="readonly">
    <tr *ngFor="let field of readOnlyForm" class="row">
      <td  *ngIf="field.title">
        <h2 class="title">{{ field.title }}</h2>
      </td>

      <td class="label" *ngIf="field.label">{{ field.label }}</td>
      <td class="value" *ngIf="field.value">
        {{ field.value }} {{ field.unitType }}
      </td>
    </tr>
  </table>
</div>
