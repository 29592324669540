export class System {
  addressCity: string;
  addressLineOne: string;
  addressLineTwo: string;
  addressState: string;
  addressZIP: string;
  azimuth: number;
  deratingFactor: number;
  energySourceId: string;
  energySourceName: string;
  fullAddress: string;
  generatorType: string;
  groundAlbedo: number;
  id: number;
  installDate: string;
  inverterBased: boolean;
  inverterCertified: boolean;
  isApproved: boolean;
  isLivened: boolean;
  isMicroInverter: boolean;
  jurisdictionId: string;
  jurisdictionName: string;
  latitude: number;
  longitude: number;
  mailingAddress: string;
  name: string;
  nameplateCapacity: number;
  operatingCompany: string;
  panelEfficiency: number;
  phase: string;
  primeMover: string;
  systemAgeInYears: number;
  systemImpactOutputResult: boolean;
  tilt: number;
  trackingModeId: string;
  withinCapacityLimit: boolean;
}
