import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';

export const importAggregatorConstantsHook = (
  functionName,
  supplementalFormModuleConfigs
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const syncArgs = functionName.slice(1)[0].split(',');
    const configId = syncArgs[0];
    const moduleConfig = supplementalFormModuleConfigs.find((config) => {
      return config.configId === configId;
    });
    if (field.props.constants) {
      field.props.constants.push(moduleConfig.value);
    } else {
      field.props.constants = [moduleConfig.value];
    }
    return;
  };
};
