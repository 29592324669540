import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'itc-formly-repeat-section',
  styles: ['button {margin-bottom: 20px;}'],
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-field class="col w-100" [field]="field"></formly-field>
      <div>
        <button tabindex="-1" mat-icon-button (click)="remove(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <button mat-stroked-button color="primary" (click)="add()">
        {{ to.addText }}
      </button>
    </div>
    <div *ngIf="showError" class="ng-error" [style.display]="'block'">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatSectionTypeComponent extends FieldArrayType {}
