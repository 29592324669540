import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ApplicationTypeStatusBase,
  HelpItem,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { ApplicationType } from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelpService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getTypes() {
    const url = '/api/applications/types';
    return this.get<ApplicationType[]>(url, {});
  }

  getTypeStatuses(typeId: string) {
    return this.get<ApplicationTypeStatusBase[]>(
      `/api/applications/types/${typeId}/statuses`,
      {}
    );
  }

  protected uploadHelpItem(
    serviceUrl: string,
    helpItem: HelpItem,
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<any>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        const metadata = [];
        const formData: FormData = new FormData();
        formData.append('file', helpItem.file);
        metadata.push({ ...helpItem, file: undefined });

        formData.append('metadata', JSON.stringify(metadata));

        let params = new HttpParams();

        Object.keys(helpItem).forEach((val) => {
          if (val !== 'file') {
            params = params.append(val, helpItem[val]);
          }
        });
        const options = {
          params,
        };

        return this.client.post<IntellioHttpResponse<any>>(
          `${this.configService.url}${serviceUrl}`,
          formData,
          options
        );
      }),
      take(1)
    );
  }

  downloadFile(helpItemId: string) {
    const url = 'api/helpitems/' + helpItemId + '/download';
    this.getFile(url).pipe(take(1)).subscribe();
  }

  // GET api/helpitems
  getHelpItems(): Observable<IntellioHttpResponse<HelpItem[]>> {
    const url = '/api/helpitems';
    return this.get<HelpItem[]>(url);
  }

  // POST api/helpitems (Body contains help item binding model)
  createHelpItem(
    helpItem: HelpItem
  ): Observable<IntellioHttpResponse<HelpItem>> {
    const url = '/api/helpitems';
    return this.uploadHelpItem(url, helpItem);
  }

  // PUT api/helpitems/{helpItemId} (Body contains help item binding model)
  updateHelpItem(helpItem: HelpItem) {
    const url = '/api/helpitems/' + helpItem.id;
    return this.put(url, helpItem);
  }

  // DELETE api/helpitems/{helpItemId}
  deleteHelpItem(helpItemId: string) {
    const url = '/api/helpitems/' + helpItemId;
    return this.delete(url);
  }
}
