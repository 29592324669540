export class ApplicationTypeJurisdiction {
  applicationTypeId: string;
  jurisdictionId: string;
  fixedPrice: number;
  variablePrice: number;
  minNamePlateCapacity?: number;
  maxNamePlateCapacity?: number;
  detail: string;
  applicationTypeName: string;
}
