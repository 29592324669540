import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationInsights,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { AppConfigService } from './app-config.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AnalyticsPageLoadType, User } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private appInsights: ApplicationInsights;

  constructor(
    private router: Router,
    private appConfigService: AppConfigService
  ) {}

  init(currentUser$: Observable<User>) {
    try {
      const angularPlugin = new AngularPlugin() as any;
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey:
            this.appConfigService.tenantConfig.appInsightsInstrumentationKey,
          enableCorsCorrelation: true,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router },
          },
        },
      });
      this.appInsights.loadAppInsights();

      this.loadCustomTelemetryProperties();

      currentUser$
        .pipe(
          tap((user) => {
            if (user) {
              this.setUserId(user.id);
            } else {
              this.clearUserId();
            }
          })
        )
        .subscribe();
    } catch (e) {
      console.warn('Invalid instrumentation key for analytics');
    }
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name, properties });
  }

  private setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  private clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      // Add custom properties here
      // item.properties['ApplicationName'] = 'ApplicationName';
    });
  }

  startPageLoadTimer(pageLoadType: AnalyticsPageLoadType) {
    this.appInsights.startTrackEvent(pageLoadType);
  }

  endPageLoadTimer(pageLoadType: AnalyticsPageLoadType) {
    this.appInsights.stopTrackEvent(pageLoadType, {
      type: 'PAGE LOAD TIME',
    });
  }
}
