import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Type,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/material/form-field';
import { MatFormFieldAppearance } from '@angular/material/form-field';

interface InputProps extends FormlyFieldProps {
  fieldMask;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<InputComponent>;
}

@Component({
  selector: 'itc-formly-field-mat-input',
  template: `
    <ng-container [ngSwitch]="type">
      <input
        *ngSwitchCase="'phone'"
        matInput
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="control"
        [placeholder]="props.placeholder"
        mask="(000) 000-0000"
      />
      <input
        *ngSwitchCase="'currency'"
        matInput
        [id]="id"
        type="currency"
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        mask="separator.0"
        thousandSeparator=","
      />
      <input
        *ngSwitchCase="'number'"
        matInput
        [id]="id"
        type="number"
        [readonly]="props.readonly"
        [required]="props.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
      />
      <input
        *ngSwitchDefault
        matInput
        [id]="id"
        [type]="type || 'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        itcMask
        [isMaskEnabled]="props.fieldMask"
      />
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent
  extends FieldType<FieldTypeConfig<InputProps>>
  implements OnInit
{
  get type() {
    return this.props.type || 'text';
  }
  control: UntypedFormControl;
  override defaultOptions = {
    props: {
      appearance: 'fill' as MatFormFieldAppearance,
      fieldMask: false,
    },
  };

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
  }
}
