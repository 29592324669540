import { FormlyField, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { Observable, combineLatest } from 'rxjs';
import {
  combineLatestAll,
  combineLatestWith,
  filter,
  mergeAll,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { FormFieldHandlers } from '@intellio/shared/models';
import {
  fetchFormObjectByAttribute,
  getChildObjects,
  getFormRoot,
} from './form-data.utility';

const collaboratorInfoList = ['person', 'primary_phone', 'email', 'address'];

export const registerFieldInstancesHook = (
  functionName: string[]
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const functionArgs = functionName
      .slice(1)[0]
      .split(',')
      .map((a) => a.trim());
    const handler = functionArgs[0];
    console.log(functionArgs);
    console.log(handler);
    switch (handler.toLowerCase()) {
      case FormFieldHandlers.Multiplex:
        MultiplexHandler(functionArgs, field);
      case FormFieldHandlers.EnrollCollaborator:
        CollaboratorHandler(functionArgs, field);
        break;
      default:
        return;
    }
  };
};

function MultiplexHandler(args: string[], field: FormlyFieldConfig) {
  const key = args[1];
  const root = getFormRoot(field);
  const fields = getChildObjects(root, key) as FormlyFieldConfig[];
  //var fieldVals = fields.map(f => f.formControl.value);
  var states = fields.map((f, index) => {
    return f.formControl.value === true;
  });
  var fieldValueChangeEvents = fields.map((f) => {
    return f.formControl.valueChanges.pipe(startWith(f.formControl.value));
  });

  combineLatest(fieldValueChangeEvents).subscribe((inputs) => {
    var newActiveIndex = -1;
    inputs.forEach((val, index) => {
      if (val === true && states[index] === false) {
        //this indicates our current value differs from
        if (states[index] !== val) {
          //this is the value that change from false to true
          newActiveIndex = index;
        }
      }
    });
    if (newActiveIndex > -1) {
      fields.forEach((f, i) => {
        if (i !== newActiveIndex) {
          f.formControl.setValue(false, { emitEvent: false });
          states[i] = false;
        } else {
          f.formControl.setValue(true, { emitEvent: false });
          states[i] = true;
        }
      });
    }
  });
}

function CollaboratorHandler(args: string[], field: FormlyFieldConfig) {
  const key = args[1];
  const root = getFormRoot(field);
  const fields = getChildObjects(root, key) as FormlyFieldConfig[];

  var fieldValueChangeEvents = fields.map((f) => {
    return f.formControl.valueChanges.pipe(startWith(f.formControl.value));
  });

  combineLatest(fieldValueChangeEvents).subscribe((inputs) => {
    inputs.forEach((val, index) => {
      const parent = fields[index].parent;
      if (parent.key != 'customer_contact') {
        setCollaboratorValues(parent.fieldGroup, false);
      }
    });

    inputs.forEach((val, index) => {
      if (val == true) {
        const parent = fields[index].parent;
        setCollaboratorValues(parent.fieldGroup, true);
      }
    });
  });
}

function setCollaboratorValues(fieldGroup: any, valueToSet: boolean) {
  fieldGroup.forEach((field) => {
    var keyToCheck = field.key;
    if (keyToCheck == null && field?.fieldGroup) {
      keyToCheck = field?.fieldGroup[0]?.key;
    }
    if (collaboratorInfoList.includes(keyToCheck?.toString())) {
      switch (keyToCheck) {
        case 'address': {
          field.fieldGroup.forEach((addressField) => {
            if (!addressField.key) {
              addressField?.fieldGroup?.forEach((adField) => {
                switch (adField.key) {
                  case 'city':
                    adField['collaboratorEnrollment'] = valueToSet
                      ? 'addressCity'
                      : null;
                    break;
                  case 'state':
                    adField['collaboratorEnrollment'] = valueToSet
                      ? 'addressState'
                      : null;
                    break;
                  case 'zip_code':
                    adField['collaboratorEnrollment'] = valueToSet
                      ? 'addressZIP'
                      : null;
                    break;
                }
              });
            }
            switch (addressField.key) {
              case 'line_one':
                addressField['collaboratorEnrollment'] = valueToSet
                  ? 'addressLineOne'
                  : null;
                break;
              case 'line_two':
                addressField['collaboratorEnrollment'] = valueToSet
                  ? 'addressLineTwo'
                  : null;
                break;
            }
          });
          break;
        }
        case 'person': {
          field['collaboratorEnrollment'] = valueToSet
            ? 'customerFullName'
            : null;
          break;
        }
        case 'primary_phone': {
          field?.fieldGroup?.forEach((phoneField) => {
            switch (phoneField.key) {
              case 'primary_phone':
                phoneField['collaboratorEnrollment'] = valueToSet
                  ? 'phoneNumber'
                  : null;
                break;
            }
          });
          break;
        }
        case 'email': {
          field['collaboratorEnrollment'] = valueToSet ? 'email' : null;
          break;
        }
      }
    }
  });
}
