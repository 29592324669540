export enum NotificationTypeId {
  NewApplicationPost = 'New Application Post',
  NewApplicationOwner = 'New Application Owner',
  NewApplicationStatus = 'New Application Status',
  DaysRemainingThresholdReached = 'Days Remaining Threshold Reached',
  DaysRemainingDeadlineReached = 'Days Remaining Deadline Reached',
  FileUploaded = 'File Uploaded',
  ReviewApproved = 'Review Approved',
  NewApplicationInitialSpoc = 'New Application Initial Spoc',
  ReviewSubmitted = 'Review Submitted',
  ReviewDeclined = 'Review Declined',
  PaymentCompleted = 'Payment Completed',
  NewApplicationCollaborator = 'New Application Collaborator',
  MaxDurationExtensionProvided = 'Max Duration Extension Provided',
  ReviewUnapproved = '		Review Unapproved',
  AccountConfirmation = 'Account Confirmation',
  PasswordReset = 'Password Reset',
  Custom = 'Custom',
  EmailSummary = 'Email Summary',
  ApplicationStatusTimerAlert = 'Application Status Timer Alert',
}
