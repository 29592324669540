import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormlyDropdownValue } from '@intellio/shared/models';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, Subject } from 'rxjs';
import { filter, isEmpty, map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'itc-formly-application-type',
  template: `
    <ng-container *ngIf="props.useTypeahead; else radioButtons">
      <mat-form-field class="full-width">
        <mat-label>{{ props.label }} {{ props.required ? '*' : '' }}</mat-label>
        <input
          type="text"
          matInput
          [formControl]="control"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option.value"
            (onSelectionChange)="dropdownSelectionMade(option)"
          >
            {{ option?.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <ng-template #radioButtons>
      <strong *ngIf="(hasNoValues | async) === false; else elseBlock">{{ props.label }}</strong>
      {{ props.required ? ' *' : '' }}
      <ng-template #elseBlock>
        <strong>
          Based on your current selections, no applicable application types were found.
        </strong>
      </ng-template>
      <mat-radio-group (change)="selectionMade($event.source.value)">
        <div *ngFor="let option of props.applicationTypes$ | async">
          <mat-radio-button [value]="option.value" color="primary">
            <strong>{{ option?.label }}</strong>
            {{ ' ' + option?.detail }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .full-width {
        width: 100%;
      }
    `,
  ],
})
export class ApplicationTypeTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy {
  constructor(protected cd: ChangeDetectorRef) {
    super();
  }

  control: UntypedFormControl;
  onDestroy$ = new Subject<void>();
  results: FormlyDropdownValue[];
  jsonOptions: any[];
  hasNoValues: Observable<boolean>;
  filteredOptions: Observable<any[]>;
  displayValue: string; // Separate property to store display value

  displayFn = (val) => {
    return this.displayValue || val;
  };

  ngOnInit() {
    this.control = this.formControl as UntypedFormControl;
    this.hasNoValues = this.props.applicationTypes$.pipe(isEmpty());

    this.setupDynamicOptions();
  }

  setupDynamicOptions() {
    this.props.applicationTypes$.subscribe((options) => {
      this.results = options;
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map((search) => {
          if (typeof search === 'string') {
            let lowerSearch = search.toLowerCase();
            return this.results.filter(
              (option) =>
                option.label.toLowerCase().includes(lowerSearch) ||
                option.value.toLowerCase().includes(lowerSearch)
            );
          }
        })
      );
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selectionMade(option) {
    this.control.setValue(option);
  }

  dropdownSelectionMade(option) {
    this.control.setValue(option.value);
    this.displayValue = option.label; // Update the display value
    this.cd.detectChanges(); // Trigger change detection
  }
}
