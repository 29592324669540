import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ModuleId, Application } from '@intellio/shared/models';
// bug 66468
import { ModuleService } from '@intellio/shared/services'; // eslint-disable-line
import { ApplicationFacade } from '@intellio/shared/state-mgmt'; // eslint-disable-line

@Directive({ selector: '[itcModuleEnabled]' })
export class ModuleEnabledDirective implements OnInit {
  @Input('itcModuleEnabled')
  moduleToCheck: ModuleId;
  private currentApplication: Application;
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private moduleService: ModuleService,
    private applicationFacade: ApplicationFacade
  ) {
    this.applicationFacade.currentApplication$.subscribe((app) => {
      this.currentApplication = app;
    });
  }

  ngOnInit() {
    this.viewContainer.clear();

    if (
      !this.moduleToCheck || //have no modules to check
      this.moduleService.isModuleEnabled(
        this.moduleToCheck,
        this.currentApplication?.categoryId
      )
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
