import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@intellio/shared/models';
import { Observable, Subject, timer } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { AutoLogoutWarningComponent } from './notifications/auto-logout-warning/auto-logout-warning.component';

@Injectable({ providedIn: 'root' })
export class AutoLogoutService {
  private readonly timeoutSeconds = 15;

  private userActivityChangeCallback: () => void;
  private timer$: Observable<unknown>;
  private restartTimer$ = new Subject<void>();
  private stopTimer$ = new Subject<void>();

  constructor(
    private zone: NgZone,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router
  ) {}

  init(user: User) {
    if (this.appConfigService.tenantConfig.autoLogoutUsersAfterInactivity) {
      if (user) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    }
  }

  private startTimer() {
    if (this.timer$) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = () => this.handleUserActivity();

      window.document.addEventListener(
        'click',
        this.userActivityChangeCallback,
        true
      );

      window.document.addEventListener(
        'mousemove',
        this.userActivityChangeCallback,
        true
      );

      this.timer$ = this.restartTimer$.pipe(
        startWith(undefined as void),
        switchMap(() =>
          timer(this.timeoutSeconds * 1000, this.timeoutSeconds * 1000)
        ),
        map((iteration) => this.checkIdle(iteration)),
        takeUntil(this.stopTimer$)
      );
      this.timer$.subscribe();
    });
  }

  private stopTimer() {
    if (this.timer$) {
      this.zone.runOutsideAngular(() => {
        window.document.removeEventListener(
          'click',
          this.userActivityChangeCallback,
          true
        );

        window.document.removeEventListener(
          'mousemove',
          this.userActivityChangeCallback,
          true
        );

        this.stopTimer$.next(null);
        this.timer$ = null;
      });
    }
  }

  private handleUserActivity() {
    if (
      this.notificationService.snackBar?.instance instanceof
      AutoLogoutWarningComponent
    ) {
      this.zone.run(() => {
        this.notificationService.dismiss();
      });
    }

    this.restartTimer$.next(null);
  }

  private checkIdle(iteration: number): void {
    this.zone.run(() => {
      const maxIdleTime =
        this.appConfigService.tenantConfig
          .autoLogoutUsersAfterInactivityTimeSpanInSeconds;

      const currentIdleTime = (iteration + 1) * this.timeoutSeconds;

      if (currentIdleTime >= maxIdleTime) {
        this.authService.logoutUser();
        this.router.navigateByUrl('/login');
      } else if (currentIdleTime >= maxIdleTime - this.timeoutSeconds) {
        this.notificationService.openFromComponent(
          AutoLogoutWarningComponent,
          'warn',
          this.timeoutSeconds
        );
      }
    });
  }
}
