<div class="modal filter-modal">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Discard Changes?
      <button mat-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <mat-dialog-content class="mat-typography">
      <p>You have unsaved changes. Are you sure you wish to proceed?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="secondary-button"
        i18n
        mat-button
        [mat-dialog-close]="false"
      >
        Cancel
      </button>
      <button
        class="primary-button"
        i18n
        mat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        color="warn"
      >
        Yes
      </button>
    </mat-dialog-actions>
  </div>
</div>
