export class FilterApplications {
  jurisdiction?: string[];
  intentOfGeneration?: string[];
  startDate?: string;
  endDate?: string;
  source?: string;
  type?: string;
  businessRole?: number;
  usersForBusinessRole?: string[];
  owner?: string[];
  spoc?: string[];
  status?: string[];
  search?: string;
  exactMatch?: boolean;
  alert?: string;
  actionRequiredAlertRule?: string; //TODO: make this a string array when we enable multi-select
  internalCreator?: boolean;
  applicationOrigin?: boolean;
  paymentBalance?: boolean;
  StatusTimerDurationOperator?: string;
  StatusTimerDuration?: number;
  actionRequired?: boolean;
  outOfOfficeOwner?: boolean;
  outOfOfficeSpoc?: boolean;
  includeOOOForSpocs?: boolean;
  includeOOOForOwners?: boolean;
  udf?: string;
  applicationCreatorUser?: string[];
}
