import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as ViewHeaderActions from './view-header.actions';
import * as ViewHeaderSelectors from './view-header.selectors';

@Injectable()
export class ViewHeaderFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  // loaded
  appHeadersloaded$ = this.store.pipe(
    select(ViewHeaderSelectors.getApplicationHeadersLoaded)
  );
  queueHeadersloaded$ = this.store.pipe(
    select(ViewHeaderSelectors.getQueueHeadersLoaded)
  );

  relatedAppsHeadersloaded$ = this.store.pipe(
    select(ViewHeaderSelectors.getRelatedAppsHeadersLoaded)
  );

  // headers 
  applicationHeaders$ = this.store.pipe(
    select(ViewHeaderSelectors.getApplicationHeaders)
  );
  queueHeaders$ = this.store.pipe(select(ViewHeaderSelectors.getQueueHeaders));
  udfQueueHeaders$ = this.store.pipe(
    select(ViewHeaderSelectors.getUDFQueueHeaders)
  );
  relatedAppsHeaders$ = this.store.pipe(
    select(ViewHeaderSelectors.getRelatedAppsHeaders)
  ); 

  // success and failure 
  getApplicationHeadersSucceeded$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getApplicationHeadersSuccess)
  );

  getApplicationHeadersFailure$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getApplicationHeadersFailure)
  );

  getQueueHeadersSucceeded$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getQueueHeadersSuccess)
  );

  getQueueHeadersFailure$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getQueueHeadersFailure)
  );

  getRelatedAppsHeadersSucceeded$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getRelatedAppsHeadersSuccess)
  );

  getRelatedAppsHeadersFailure$ = this.actions$.pipe(
    ofType(ViewHeaderActions.getRelatedAppsHeadersFailure)
  );



  constructor(private store: Store, private actions$: Actions) {}

  dispatchGetApplicationHeaders(isExternal: boolean, appType?: string) {
    this.store.dispatch(
      ViewHeaderActions.getApplicationHeaders({ isExternal, appType })
    );
  }

  dispatchGetQueueHeaders(isExternal: boolean, categoryId: string) {
    this.store.dispatch(ViewHeaderActions.getQueueHeaders({ isExternal, categoryId }));
  }

  dispatchGetRelatedAppsHeaders(isExternal: boolean) {
    this.store.dispatch(
      ViewHeaderActions.getRelatedAppsHeaders({isExternal})
    );
  }
}
