import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { Activity } from '@intellio/shared/models';
import { ActivityType } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class AppHistoryService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getActivities(id, activityTypesSelected) {
    let params = new HttpParams();
    let options = {};
    if (!activityTypesSelected) {
      activityTypesSelected = null;
    } else {
      activityTypesSelected.forEach((param) => {
        params = params.append('includeActivities', param);
      });
      options = {
        params: params,
      };
    }

    const getActivitiesUrl = '/api/applications/' + id + '/activities';
    return this.get<Activity[]>(getActivitiesUrl, options);
  }

  getActivityTypes(activityParams) {
    if (!activityParams) {
      activityParams = null;
    }

    const getActivityTypesUrl = '/api/activities/types';
    return this.get<ActivityType[]>(getActivityTypesUrl, {});
  }
}
