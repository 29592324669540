import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function accountNumberValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  return value && !value.match(/^\d+$/)
    ? {
        invalidAccountNumber: true,
      }
    : null;
}
