import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileType, FileUploadWithMetadata } from '@intellio/shared/models';
import {
  AccountService,
  AppConfigService,
  ApplicationFilesService,
  NotificationService,
} from '@intellio/shared/services';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'itc-task-files-uploader',
  templateUrl: './task-files-uploader.component.html',
  styleUrls: ['./task-files-uploader.component.scss'],
})
export class TaskFilesUploaderComponent implements OnInit {
  constructor(
    private applicationFilesService: ApplicationFilesService,
    private accountService: AccountService,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<TaskFilesUploaderComponent>
  ) {}

  files: NgxFileDropEntry[] = [];
  fileMetadata: FileUploadWithMetadata[] = [];
  dataSource = new MatTableDataSource<FileUploadWithMetadata>([]);
  fileHeaders: string[] = ['fileName', 'fileUploadDate'];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  loading = true;

  ngOnInit(): void {
    if (this.data.fileUpload) {
      if (this.accountService.currentUser.isExternal) {
        this.data.fileUpload.forInternalUseOnly = false;
      }
      this.fileMetadata.push(this.data.fileUpload);
      this.dataSource.data = this.fileMetadata;
    }
    this.loading = false;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onUpload() {
    this.loading = true;
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.invalid) {
      const results: Observable<any>[] = [];
      for (let i = 0; i < this.fileMetadata.length; i++) {
        if (
          this.fileMetadata[i].file.size >
          this.appConfigService.tenantConfig.maxFileSize * 1048576
        ) {
          this.notificationService.open(
            $localize`${this.fileMetadata[i].file.name} is too large. File size must not exceed ${this.appConfigService.tenantConfig.maxFileSize} MB`,
            'error',
            {
              duration: 5000,
            }
          );
          return;
        }
        if (
          this.fileMetadata[i].forInternalUseOnly === undefined ||
          this.fileMetadata[i].forInternalUseOnly === null
        ) {
          this.fileMetadata[i].forInternalUseOnly = true;
        }
        this.fileMetadata[i].fileTypeId = 'Other';
        results.push(
          this.applicationFilesService
            .uploadTaskFile(this.data.taskId, this.fileMetadata[i])
            .pipe(
              catchError((error: any) => {
                return of(false);
              })
            )
        );
      }
      forkJoin(results).subscribe(() => {
        this.loading = false;
        this.dialogRef.close();
      });
    }
  }
}
