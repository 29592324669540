// Angular service

import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class PrintingService {
  constructor(private notificationService: NotificationService) {}
  print(content: string, styles: any) {
    this.initPopupBlocker();

    // Attempt to open the window off-screen
    const printWindow = window.open('', '_blank', 'width=1200,height=750');
    if (!printWindow) {
      this.notificationService.open(
        'Please allow pop-ups for this site to print the payment slip.',
        'error',
        { duration: 3000 }
      );
      return;
    }

    printWindow.document.write(`
        <html>
          <head>
            <title>Print tab</title>
            ${styles}
            <style>
              @media print {
                .not-printable { display: none; }
              }
            </style>
          </head>
          <body>${content}</body>
        </html>`);
    printWindow.onafterprint = printWindow.close;
    printWindow.print();
  }

  private initPopupBlocker() {
    const popupInitWindow = window.open(
      '',
      '_blank',
      'top=0,left=0,width=0,height=0'
    );
    popupInitWindow.close();
  }
}
