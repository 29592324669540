import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  getChildObjects,
  getFormRoot,
  getValueOnObjectFromStringProperty,
} from '@intellio/shared/utils';

import * as _ from 'lodash';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';

export const prepopulateAsync = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const cback = (app) => {
      const root = getFormRoot(field);
      const prepopulateObjs = getChildObjects(root, 'prepopulateAsync');
      prepopulateObjs.forEach((obj) => {
        const fieldNameToPrepopulate = obj['prepopulateAsync'];
        let valueToPrepopulate = null;
        if (fieldNameToPrepopulate.includes(':')) {
          const params = fieldNameToPrepopulate.split(':');
          if (params[0] != '') {
            valueToPrepopulate = getValueOnObjectFromStringProperty(
              params[0],
              app
            );
          } else {
            valueToPrepopulate = app;
          }

          valueToPrepopulate = valueToPrepopulate.find(
            (obj) => obj[params[1]].toString() === params[2].toString()
          );
          if (valueToPrepopulate !== undefined) {
            if (valueToPrepopulate[params[3]] !== undefined) {
              valueToPrepopulate = valueToPrepopulate[params[3]];
            }
            obj.formControl.setValue(valueToPrepopulate);
          }
        } else {
          valueToPrepopulate = getValueOnObjectFromStringProperty(
            fieldNameToPrepopulate,
            app
          );

          if (valueToPrepopulate !== undefined && valueToPrepopulate !== null) {
            obj.formControl.setValue(valueToPrepopulate, {
              emitEvent: true,
            });
          }
        }
      });
    };
    field.props.onClick = cback;
  };
};
