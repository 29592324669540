<div class="modal">
  <div class="xsmall">
    <h2 i18n mat-dialog-title>
      Delete Task
      <button mat-icon-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <div class="in-place-spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
      <p i18n>Are you sure you want to delete this task?</p>
      <div>
        <strong class="strong-text">
          {{ task.displayName }}
        </strong>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="secondary-button"
        mat-button
        i18n
        (click)="closeModal()"
        [disabled]="loading"
      >
        Cancel
      </button>
      <button
        class="primary-button"
        mat-button
        i18n
        (click)="deleteTask()"
        [disabled]="loading"
      >
        Delete
      </button>
    </mat-dialog-actions>
  </div>
</div>
