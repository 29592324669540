import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import {
  getFormRoot,
  getValueOnObjectFromStringProperty,
  setValueOnObjectFromStringProperty,
} from './form-data.utility';

export const mapToKeyHook = (functionName): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const root = getFormRoot(field);
    const args = functionName.slice(1);
    const parameters = args[0].split(',');
    const key = parameters[0];
    let dataMapper = null;
    let val = getValueOnObjectFromStringProperty(key, root.model);
    let shouldMap = true;
    const model = root.model; // needed for the eval statement

    if (val) {
      if (parameters.length > 1) {
        switch (args[0].split(',')[1]) {
          case 'multicheckboxAdapter':
            dataMapper = (val: string[]) => {
              const res = {};
              val.forEach((v) => {
                res[v] = true;
              });
              return res;
            };
            val = dataMapper(val);
            dataMapper = null;
            break;
          default:
            shouldMap = eval(args[0].split(',')[1]);
        }
      }

      if (shouldMap) {
        field.formControl.setValue(val);
      }
    }

    field.formControl.valueChanges.subscribe((value) => {
      if (parameters.length > 1) {
        switch (args[0].split(',')[1]) {
          case 'multicheckboxAdapter':
            dataMapper = (val: any) => {
              const res = [];
              Object.keys(val).forEach((k) => {
                if (val[k]) {
                  res.push(k);
                }
              });
              return res;
            };
            if (value !== null) {
              value = dataMapper(value);
            }
            break;
          default:
            shouldMap = eval(args[0].split(',')[1]);
        }
      }

      if (shouldMap) {
        setValueOnObjectFromStringProperty(key, root.model, value);
      }
    });
  };
};
