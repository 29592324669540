import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function zipCodeValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  return value && !value.match(/^[0-9]{5}$/)
    ? {
        invalidZipCode: true,
      }
    : null;
}
