<h2 i18n>Import Preset For Equipment Contractor</h2>

<h4 i18n>This will overwrite all fields in the Equipment Contractor section</h4>

<button mat-button class="center" color="primary" (click)="closeModal()" i18n>
  Cancel
</button>
<button
  mat-button
  class="center"
  color="primary"
  (click)="importPresets()"
  i18n
>
  Import
</button>
