import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OutOfOfficeBackup,
  OutOfOfficeEntry,
  Task,
  TaskTemplate,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class OutOfOfficeService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getOutOfOfficeEntry(userId) {
    const appUrl = '/api/outofoffice/entry/' + userId;
    return this.get<OutOfOfficeEntry>(appUrl);
  }

  getOutOfOfficeBackupByUser(userId) {
    const appUrl = '/api/outofoffice/backup/user/' + userId;
    return this.get<OutOfOfficeBackup[]>(appUrl);
  }

  createOutOfOfficeEntry(outOfOfficeEntryModel) {
    const appURL = '/api/outofoffice/entry';
    return this.post<any>(appURL, outOfOfficeEntryModel);
  }

  deleteOutOfOfficeEntry(outOfOfficeEntryModel) {
    const appURL = '/api/outofoffice/entry';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: outOfOfficeEntryModel.id,
        userId: outOfOfficeEntryModel.userId,
      },
    };
    return this.delete<any>(appURL, options);
  }

  updateOutOfOfficeEntry(outOfOfficeEntryModel) {
    const appURL = '/api/outofoffice/entry';
    return this.put<any>(appURL, outOfOfficeEntryModel);
  }
}
