import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Role,
  RoleMetadata,
  UserCreationOriginId,
} from '@intellio/shared/models';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  private _roleMetadata: RoleMetadata[] = null;
  private _externalRoles: Record<string, Role[]> = null;
  private _internalRoles: Role[] = null;
  private _spocRoles: Role[] = null;

  constructor(
    client: HttpClient,
    appConfigService: AppConfigService,
    authService: AuthService
  ) {
    super(client, appConfigService, authService);
  }

  get roleMetadata(): RoleMetadata[] {
    return this._roleMetadata || [];
  }

  get externalRoles(): Record<string, Role[]> {
    // record of userCreationOriginId as a key to a list of external roles. Use a key of 'All' to get the larger list
    return this._externalRoles || {};
  }

  get internalRoles(): Role[] {
    return this._internalRoles || [];
  }

  get spocRoles(): Role[] {
    return this._spocRoles || [];
  }

  initPublicRoles() {
    return (
      this._roleMetadata == null
        ? this.getRoleMetadata()
        : of(this.roleMetadata)
    ).pipe(
      switchMap(() => {
        return this._externalRoles == null
          ? this.getExternalRoles()
          : of(this.externalRoles);
      }),
      switchMap(() => {
        return of(true);
      })
    );
  }

  initPrivateRoles() {
    return forkJoin([
      this._internalRoles == null
        ? this.getInternalRoles()
        : of(this.internalRoles),
      this._spocRoles == null ? this.getSpocRoles() : of(this.spocRoles),
    ]);
  }

  getExternalRoles(
    userCreationOriginId?: UserCreationOriginId,
    appCategory?: string
  ): Observable<Record<string, Role[]>> {
    const [uccId] =
      userCreationOriginId == null
        ? [null]
        : Object.entries(UserCreationOriginId).find(
            ([_, value]) => value === userCreationOriginId
          );

    const getExternalRolesUrl = '/api/account/publicroles';
    const config = {
      isExternal: true,
      params: {
        userCreationOriginId: uccId,
        appCategory: appCategory,
      },
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.get<Role[]>(getExternalRolesUrl, config).pipe(
      switchMap((response) => {
        const activeRoles = {
          ...this.externalRoles,
          [userCreationOriginId || 'All']: this.applyRoleMetadata(
            response.data
          ),
        };
        this._externalRoles = activeRoles;
        return of(activeRoles);
      })
    );
  }

  getInternalRoles(): Observable<Role[]> {
    if (this.internalRoles.length > 0) {
      return of(this.internalRoles);
    }

    const getInternalRolesUrl = '/api/account/internalroles';

    return this.get<Role[]>(getInternalRolesUrl).pipe(
      switchMap((response) => {
        const activeRoles = this.applyRoleMetadata(response.data);

        this._internalRoles = activeRoles;
        return of(activeRoles);
      })
    );
  }

  getSpocRoles(): Observable<Role[]> {
    if (this.spocRoles.length > 0) {
      return of(this.spocRoles);
    }

    const getSpocRolesUrl = '/api/account/spocroles';
    return this.get<Role[]>(getSpocRolesUrl).pipe(
      switchMap((response) => {
        this._spocRoles = response.data;
        return of(response.data);
      })
    );
  }

  getRoleMetadata(): Observable<RoleMetadata[]> {
    const roleMetadataUrl = '/api/rolemetadata';
    const config = {
      isExternal: true,
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };
    return this.get<RoleMetadata[]>(roleMetadataUrl, config).pipe(
      switchMap((response) => {
        this._roleMetadata = response.data;
        return of(response.data);
      })
    );
  }

  applyRoleMetadata(roles: Role[]) {
    return roles
      .map((role) => {
        const metadata = this.roleMetadata.find(
          (rm) => rm.roleNameId === role.id
        );

        if (metadata) {
          if (!metadata.isActive) {
            return null;
          }

          if (metadata.roleDisplayName) {
            role.name = metadata.roleDisplayName;
          }
        }

        return role;
      })
      .filter((role) => role);
  }
}
