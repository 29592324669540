import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { Observable, pipe } from 'rxjs';
import {
  ApplicationFile,
  FileUploadWithMetadata,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EngineeringService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  downloadEngineeringTemplate(templateName: string): void {
    this.getFile(`/api/engineering/${templateName}`).pipe(take(1)).subscribe();
  }

  uploadEngineeringTemplate(
    templateName: string,
    file: File
  ): Observable<IntellioHttpResponse<ApplicationFile>> {
    const fileWithMetadata: FileUploadWithMetadata = {
      file,
      fileName: file.name,
      fileMimeType: file.type,
      fileTypeId: '',
    };
    const fileExtension = file.name.split('.').pop();
    return this.uploadFiles(
      `/api/engineering/exceldata/${templateName}/${fileExtension}`,
      [fileWithMetadata]
    );
  }
}
