import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
  } from '@angular/core';
  // bug 66468
  import { FeatureManagementService } from '@intellio/shared/services'; // eslint-disable-line
  
  @Directive({ selector: '[itcFeatureEnabled]' })
  export class FeatureEnabledDirective implements OnInit {
    @Input('itcFeatureEnabled')
    featureToCheck: string;
    constructor(
      private templateRef: TemplateRef<unknown>,
      private viewContainer: ViewContainerRef,
      private featureService: FeatureManagementService
    ) {}
  
    ngOnInit() {
      this.viewContainer.clear();
  
      if (
        !this.featureToCheck || //have no feature to check
        this.featureService.isFeatureActivated(
          this.featureToCheck
        )
      ) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }