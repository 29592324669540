export class ApplicationViewHeader {
  id: string;
  name: string;
  dataLocation: string;
  dataType: string;
  secondaryId?: string; // temporary until canonical model is introduced with viewheaders
  trueValue?: string;
  falseValue?: string;
  condition?: string;
  isEditable?: boolean;
  updateType?: string;
}
