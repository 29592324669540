import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ScheduledNotificationInstance,
  ScheduledNotificationSearchResult,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduledNotificationService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getScheduledNotifications(params: any) {
    const url = '/api/schedulednotification';
    return this.get<ScheduledNotificationSearchResult>(url, { params });
  }

  getUpcomingScheduledNotifications(appId: string) {
    const url = '/api/schedulednotification/upcoming/' + appId;
    return this.get<ScheduledNotificationInstance[]>(url);
  }

  createScheduledNotification(model: any) {
    const url = '/api/schedulednotification';
    return this.post(url, model);
  }

  updateScheduledNotification(id: string, model: any) {
    const url = `/api/schedulednotification/${id}`;
    return this.put(url, model);
  }

  deleteScheduledNotifications(id: string) {
    const url = `/api/schedulednotification/${id}`;
    return this.delete(url, {});
  }
}
