import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IntellioHttpResponse,
  ContextualHelpModel,
} from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ContextualHelpService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getContextualHelp(): Observable<IntellioHttpResponse<ContextualHelpModel>> {
    const url = '/api/schemas/Help';
    return this.get<ContextualHelpModel>(url);
  }
}
