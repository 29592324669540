import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, mapTo, take } from 'rxjs/operators';
import * as fastDeepEqual from 'fast-deep-equal';
import { Observable, merge } from 'rxjs';
import {
  getAccount,
  getExternalUsers,
  getSimpleExternalUsers,
  logoutAccount,
} from './account.actions';
import * as AccountSelectors from './account.selectors';
import * as AccountActions from './account.actions';
import {
  ApplicationCategory,
  StatusChangeSchema,
  User,
} from '@intellio/shared/models';

@Injectable({ providedIn: 'root' })
export class AccountFacade {
  constructor(private store: Store, private actions: Actions) {}

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  currentUser$: Observable<User> = this.store.pipe(
    select(AccountSelectors.getAccount),
    filter((user) => user !== undefined),
    distinctUntilChanged(fastDeepEqual)
  );

  externalUsers$: Observable<User[]> = this.store.pipe(
    select(AccountSelectors.getExternalUsers),
    filter((users) => users.length > 0),
    distinctUntilChanged(fastDeepEqual)
  );

  simpleExternalUsers$: Observable<User[]> = this.store.pipe(
    select(AccountSelectors.getSimpleExternalUsers),
    filter((users) => users.length > 0),
    distinctUntilChanged(fastDeepEqual)
  );

  internalUsers$: Observable<User[]> = this.store.pipe(
    select(AccountSelectors.getInternalUsers),
    filter((internalUsers) => internalUsers?.length > 0)
  );

  activeCategoryId$ = this.store.pipe(
    select(AccountSelectors.getUserActiveCategory)
  );

  statusChangeSchemas$: Observable<StatusChangeSchema[]> = this.store.pipe(
    select(AccountSelectors.getStatusChangeSchemas)
  );

  availableCategories$ = this.store.pipe(
    select(AccountSelectors.getAvailableCategoriesForUser)
  );

  switchCategoryInitiated$ = this.actions.pipe(
    ofType(AccountActions.updateAccountCurrentAppCategory)
  );

  switchCategorySuccess$ = this.actions.pipe(
    ofType(AccountActions.updateAccountCurrentAppCategorySucceeded)
  );

  switchCategoryReqFinished$ = this.actions.pipe(
    ofType(
      AccountActions.updateAccountCurrentAppCategorySucceeded,
      AccountActions.updateAccountCurrentAppCategoryFailed
    )
  );

  switchCategoryLoadingState$ = merge(
    this.switchCategoryInitiated$.pipe(mapTo(true)),
    this.switchCategoryReqFinished$.pipe(mapTo(false))
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  dispatchGetAccount() {
    this.store.dispatch(getAccount());
  }

  dispatchLogoutAccount() {
    this.store.dispatch(logoutAccount());
  }

  dispatchGetExternalUsers() {
    this.store
      .pipe(select(AccountSelectors.getExternalUsersLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded) {
          this.store.dispatch(getExternalUsers());
        }
      });
  }

  dispatchGetSimpleExternalUsers() {
    this.store
      .pipe(select(AccountSelectors.getSimpleExternalUsersLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded) {
          this.store.dispatch(getSimpleExternalUsers());
        }
      });
  }

  dispatchGetInternalUsers() {
    this.store
      .pipe(select(AccountSelectors.getInternalUsers), take(1))
      .subscribe((loaded) => {
        if (!loaded) {
          this.store.dispatch(AccountActions.getInternalUsers());
        }
      });
  }

  dispatchSwitchCategory(appCategory: ApplicationCategory) {
    this.store.dispatch(
      AccountActions.updateAccountCurrentAppCategory({ appCategory })
    );
  }
}
