import {
  Component,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { ContextualHelpModalComponent } from './contextual-help-modal/contextual-help-modal.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-formly-wrapper-addons',
  template: `
    <div class="edit-field-wrapper">
      <div class="start-display">
        <ng-template #matPrefix>
          <span
            *ngIf="props.addonLeft"
            [ngStyle]="{
              cursor: props.addonLeft.onClick ? 'pointer' : 'inherit'
            }"
            (click)="addonLeftClick($event)"
          >
            <mat-icon *ngIf="props.addonLeft.icon">{{
              props.addonLeft.icon
            }}</mat-icon
            >&nbsp;
            <span *ngIf="props.addonLeft.text">{{ props.addonLeft.text }}</span
            >&nbsp;
          </span>
        </ng-template>

        <ng-container #fieldComponent>
          <div class="warning-icon">
            <mat-icon *ngIf="showAlert"> warning </mat-icon>
          </div>
        </ng-container>
      </div>

      <div class="end-display">
        <div class="right-addon">
          <span
            *ngIf="props.addonRight"
            [ngStyle]="{
              cursor: props.addonRight.onClick ? 'pointer' : 'inherit'
            }"
            (click)="addonRightClick($event)"
          >
            &nbsp;<mat-icon *ngIf="props.addonRight.icon">{{
              props.addonRight.icon
            }}</mat-icon>
            &nbsp;<span *ngIf="props.addonRight.text">{{
              props.addonRight.text
            }}</span>
          </span>
        </div>

        <div *ngIf="props.contextualHelp" class="helpText">
          <mat-icon
            *ngIf="props.contextualHelp"
            class="help-icon"
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="openContextualHelpModal($event)"
          >
            help
          </mat-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./addons-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonsWrapperComponent
  extends IntellioFormlyWrapperComponent
  implements AfterViewInit, OnInit
{
  constructor(
    private matDialog: MatDialog,
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  showAlert = false;

  @ViewChild('matPrefix') matPrefix: TemplateRef<any>;
  @ViewChild('matSuffix') matSuffix: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.matPrefix) {
      Promise.resolve().then(() => (this.props.prefix = this.matPrefix));
    }

    if (this.matSuffix) {
      Promise.resolve().then(() => (this.props.suffix = this.matSuffix));
    }
  }

  addonRightClick($event: any) {
    if (this.props.addonRight.onClick) {
      this.props.addonRight.onClick(this.to, this, $event);
    }
  }

  addonLeftClick($event: any) {
    if (this.props.addonLeft.onClick) {
      this.props.addonLeft.onClick(this.to, this, $event);
    }
  }

  openContextualHelpModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.matDialog.open(ContextualHelpModalComponent, {
      data: this.field,
    });
  }

  ngOnInit() {
    if (
      this.props.alertValue !== undefined &&
      this.formControl.value === this.props.alertValue
    ) {
      this.showAlert = true;
    } else {
      this.showAlert = false;
    }
    this.formControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        if (val === this.props.alertValue) {
          this.showAlert = true;
        } else {
          this.showAlert = false;
        }
      });
    this.initChangeDetectSub();
  }
}
