import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

@Component({
  selector: 'itc-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {
  @Input() erroredFields: any[];
  constructor() {}

  @Output() focusElementClicked = new EventEmitter<any>();
  ngOnInit(): void {}

  focusElement(field: any) {}
}
