import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeocodeResultModel } from '@intellio/shared/models';

@Component({
  selector: 'itc-geocode-modal',
  templateUrl: './geocode-modal.component.html',
  styleUrls: ['./geocode-modal.component.scss'],
})
export class GeocodeModalComponent {
  selectedAddress: any = null;
  ignoreLineTwo: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: GeocodeResultModel,
    public dialogRef: MatDialogRef<GeocodeModalComponent>
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  returnAddress(): void {
    if (this.selectedAddress.addressCity) {
      this.dialogRef.close(this.selectedAddress);
    } else {
      //else unverified address selected, dont return any data to be changed
      this.dialogRef.close();
    }
  }
}
