export class DocusignFile {
  envelopeId: string;
  documentTitle: string;
  envelopeStatus: string;
  envelopeUrl: string;
  internalSignerName: string;
  internalSignerStatus: string;
  externalSignerName: string;
  externalSignerStatus: string;
  customerSignerName: string;
  customerSignerStatus: string;
  createdDateTime: string;
}
