import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itc-formly-field-help',
  template: `
    <div
      [hidden]="props.hidden"
      [innerHTML]="(control.value ? control.value : props.helpValue) | safeHtml"
    ></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  private destroyed$ = new Subject<void>();

  control: UntypedFormControl;

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
