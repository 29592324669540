import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<Environment>('app.config');

export interface Environment {
  production: boolean;
  config: AppConfig;
  googleAnalyticsMeasurementId: string;
}

export interface AppConfig {
  url: string;
  tenant: string;
}
