import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function emailValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  return value && !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/)
    ? {
        invalidEmail: true,
      }
    : null;
}
