import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(
    private appConfig: AppConfigService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Let requests with a config header
    // Let non-http calls go through because of files loading locally before login
    if (req.headers.has('X-Config') || !req.url.startsWith('http')) {
      return next.handle(req);
    }
    // Don't add auth headers if the sender
    // of the request adds the X-Allow-Anonymous
    // header
    if (!req.headers.has('X-Allow-Anonymous')) {
      const token = this.getToken();

      const authenticatedRequest = req.clone({
        setHeaders: {
          Authorization: token,
          'X-Tenant': this.appConfig.tenant.toLowerCase(),
        },
      });

      return next.handle(authenticatedRequest);
    }
    if (req.headers.has('X-Allow-Anonymous')) {
      const anonymousRequest = req.clone({
        setHeaders: {
          'X-Tenant': this.appConfig.tenant.toLowerCase(),
        },
      });

      return next.handle(anonymousRequest);
    }
  }

  getToken(): string {
    if (this.authService.accessToken) {
      return `${this.authService.accessTokenType} ${this.authService.accessToken}`;
    }

    return null;
  }
}
