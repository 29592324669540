export class ApplicationListRequestParams {
  search: string;
  exactMatch?: boolean;
  skip: number;
  take: number;
  sort: string;
  asc: boolean;
  my: boolean;
  excludeArchivedApps: boolean;
  jurisdiction?: string[];
  intentOfGeneration?: string[];
  applicationsSubmittedAfter?: string;
  applicationsSubmittedBefore?: string;
  source?: string;
  type?: string;
  businessRole?: number;
  usersForBusinessRole?: string[];
  owner?: string[];
  spoc?: string[];
  status?: string[];
  alert?: string;
  actionRequiredAlertRule?: string; //TODO: make this a string array when we enable multi-select
  internalCreator?: boolean;
  applicationOrigin?: boolean;
  paymentBalance?: boolean;
  startDate?: string;
  endDate?: string;
  StatusTimerDurationOperator?: string;
  StatusTimerDuration?: number;
  actionRequired?: boolean;
  includeOOOForOwners?: boolean;
  includeOOOForSpocs?: boolean;
  outOfOfficeOwner?: boolean;
  outOfOfficeSpoc?: boolean;
  udf?: string;
  applicationCreatorUser?: string[];
  usePerformantRetrieval: boolean;
  category?: string;
  getAllCategories?: boolean; 
}
