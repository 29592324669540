import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getChildObject } from './form-data.utility';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export const filterInverterModelsHook = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    const mfgObj = getChildObject(p, 'inverterManufacturer');
    const mfgFormField = field.form.get(mfgObj.key);

    const modelObj = getChildObject(p, 'inverterModel');

    field.props.options = mfgFormField.valueChanges.pipe(
      startWith(`${mfgFormField.value}:initial`),
      tap((value) => {
        if (field.form.status === 'DISABLED') {
          field.form.get(modelObj.key).disable();
        }
        if (field.form.status !== 'DISABLED') {
          const model = field.form.get(modelObj.key);
          const isStartValue =
            value === null ? false : value?.toString().split(':').length > 1;
          if (!isStartValue) {
            model.setValue('');
          }

          const ratingkWObj = getChildObject(p, 'inverterRatingkW');
          const ratingkW = field.form.get(ratingkWObj.key);
          if (!isStartValue) {
            ratingkW.setValue('');
          }

          const ratingkVAObj = getChildObject(p, 'inverterRatingKVA');
          const ratingkVA = field.form.get(ratingkVAObj.key);
          if (!isStartValue) {
            ratingkVA.setValue('');
          }

          const ratedVoltageObj = getChildObject(p, 'inverterRatingVoltage');
          const ratedVolts = field.form.get(ratedVoltageObj.key);
          if (!isStartValue) {
            ratedVolts.setValue('');
          }

          const ratedDCVoltageObj = getChildObject(p, 'inverterDCRatedVoltage');
          const ratedDCVolts = field.form.get(ratedDCVoltageObj.key);
          if (!isStartValue) {
            ratedDCVolts.setValue('');
          }

          const ratedDCCircuitVoltsObj = getChildObject(
            p,
            'inverterDCCircuitVoltage'
          );
          const ratedDCCircuitVolts = field.form.get(
            ratedDCCircuitVoltsObj.key
          );
          if (!isStartValue) {
            ratedDCCircuitVolts.setValue('');
          }

          const inverterIsCertifiedObj = getChildObject(
            p,
            'inverterIsCertified'
          );
          const inverterIsCertified = field.form.get(
            inverterIsCertifiedObj.key
          );
          if (!isStartValue) {
            inverterIsCertified.setValue('');
          }
        }
      }),
      switchMap((mfg) => {
        if (mfg?.toString().includes(':initial')) {
          mfg = mfg.replace(':initial', '');
        }
        const filteredInverters = field.options.formState.inverters.filter(
          (inv) => inv.manufacturer === mfg
        );
        if (filteredInverters.length > 0) {
          const options = filteredInverters.map((inv) => {
            return {
              value: inv.modelNumber,
              label: inv.modelNumber,
            };
          });
          return of(options);
        } else {
          return of([]);
        }
      })
    );
  };
};
