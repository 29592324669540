import { TechnicalReviewAutomationRunLog } from './technical-review-automation-run-log.model';

export class TechnicalReviewAutomationRunLogRepresentation {
  applicationId: string;
  dgSystemId: string;
  projectId: string;
  approved: boolean;
  reason: string;
  technicalReviewVersionNumber: number;
  timeStamp: Date;

  logs: TechnicalReviewAutomationRunLog;
}
