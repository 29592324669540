import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aria',
})
export class AriaPipe implements PipeTransform {
  transform(text: string, route: string): string {
    if (!text) {
      return null;
    }
    const currentRoute = window.location.href.split('/').pop();

    if (currentRoute === route) {
      return `${text} link. You are currently on this page.`;
    } else {
      return `${text} link. This will take you to the ${text} page.`;
    }
  }
}
