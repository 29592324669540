import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: unknown, limit: number, truncationMarker = '...'): unknown {
    if (typeof value !== 'string' || value.length < limit) {
      return value;
    } else {
      return `${value.substring(0, limit)}${truncationMarker}`;
    }
  }
}
