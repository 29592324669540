import { TechnicalReviewLogSection } from './technical-review-log-section.model';

export class TechnicalReviewAutomationRunLog {
  applicationId: string;
  dgSystemId: string;
  projectId: string;
  approved: boolean;
  reason: string;
  technicalReviewVersionNumber: number;

  sections: TechnicalReviewLogSection[];
}
