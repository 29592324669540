<div class="modal delete-modal">
  <div class="xsmall">
    <h2 mat-dialog-title>
      <div>
        <span>More Information: </span>
        <strong>{{ data.props?.title }}</strong>
      </div>

      <button mat-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <mat-dialog-content class="mat-typography">
      <p [innerHTML]="helpHtml"></p>
    </mat-dialog-content>
  </div>
</div>
