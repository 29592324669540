import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import {
  ApplicationCategory,
  StatusChangeSchema,
} from '@intellio/shared/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppCategoryService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getSchemasForCategory(category: string) {
    const url = `/api/applications/statuschangeschema/${category}`;
    return this.get<StatusChangeSchema[]>(url, {}).pipe(
      map((response) => response.data)
    );
  }

  getAllCategories(): Observable<ApplicationCategory[]> {
    return this.get<ApplicationCategory[]>(
      '/api/applications/categories',
      {}
    ).pipe(map((response) => response.data));
  }

  getAvailableCategoriesForUser(): Observable<ApplicationCategory[]> {
    return this.get<ApplicationCategory[]>(
      '/api/applications/categories/currentuser',
      {}
    ).pipe(map((response) => response.data));
  }

  switchCurrentAppCategory(appCategory): Observable<boolean> {
    return this.put<string>(
      '/api/applications/categories/currentuser',
      appCategory
    ).pipe(map(() => true));
  }
}
