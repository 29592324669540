import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntellioHttpResponse, Inverter } from '@intellio/shared/models';
import { config, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class InvertersService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getInverters(): Observable<IntellioHttpResponse<Inverter[]>> {
    const url = '/api/inverters';
    return this.get<Inverter[]>(url);
  }
}
