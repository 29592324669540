import {
  ApplicationQueueHeader,
  ApplicationViewHeader,
  UDF,
} from '@intellio/shared/models';
import { createAction, props } from '@ngrx/store';

export enum ViewHeaderActionTypes {
  GetApplicationHeaders = '[Application Headers] Get',
  GetApplicationHeadersSuccess = '[Application Headers] Get Succeeded',
  GetApplicationHeadersFailure = '[Application Headers] Get Failure',
  GetQueueHeaders = '[Queue Headers] Get',
  GetQueueHeadersSuccess = '[Queue Headers] Get Succeeded',
  GetQueueHeadersFailure = '[Queue Headers] Get Failure',
  GetRelatedAppsHeaders = '[Related App Headers] Get', 
  GetRelatedAppsHeadersSuccess = '[Related App Headers] Get Succeeded',
  GetRelatedAppsHeadersFailure = '[Related App Headers] Get Failure'
}

export const getApplicationHeaders = createAction(
  ViewHeaderActionTypes.GetApplicationHeaders,
  props<{ isExternal: boolean; appType?: string }>()
);

export const getApplicationHeadersSuccess = createAction(
  ViewHeaderActionTypes.GetApplicationHeadersSuccess,
  props<{ applicationHeaders: ApplicationViewHeader[] }>()
);

export const getApplicationHeadersFailure = createAction(
  ViewHeaderActionTypes.GetApplicationHeadersFailure,
  props<{ error: any }>()
);

export const getQueueHeaders = createAction(
  ViewHeaderActionTypes.GetQueueHeaders,
  props<{ isExternal: boolean; categoryId: string }>()
);

export const getQueueHeadersSuccess = createAction(
  ViewHeaderActionTypes.GetQueueHeadersSuccess,
  props<{ queueHeaders: ApplicationQueueHeader[]; udfs: UDF[], categoryId: string}>()
);

export const getQueueHeadersFailure = createAction(
  ViewHeaderActionTypes.GetQueueHeadersFailure,
  props<{ error: any }>()
);

export const getRelatedAppsHeaders = createAction(
  ViewHeaderActionTypes.GetRelatedAppsHeaders, 
  props<{ isExternal: boolean }>()
);

export const getRelatedAppsHeadersSuccess = createAction(
  ViewHeaderActionTypes.GetApplicationHeadersSuccess, 
  props<{relatedAppsHeaders: any}>()
);

export const getRelatedAppsHeadersFailure = createAction(
  ViewHeaderActionTypes.GetApplicationHeadersFailure,
  props<{error: any}>()
);