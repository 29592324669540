import { Injectable } from '@angular/core';
import {
  Application,
  ApplicationStatuses,
  ApplicationType,
  BusinessRole,
  EditApplicationSync,
  RuleAlerts,
  UDF,
  User,
} from '@intellio/shared/models';
import { Actions, ofType } from '@ngrx/effects';

import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import * as ApplicationActions from './application.actions';
import * as ApplicationSelectors from './application.selectors';
import * as fastDeepEqual from 'fast-deep-equal';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(ApplicationSelectors.getApplicationLoaded));

  currentApplication$: Observable<Application> = this.store.pipe(
    select(ApplicationSelectors.getApplication),
    filter((app) => app !== null),
    distinctUntilChanged(fastDeepEqual)
  );

  currentBusinessRoles$: Observable<BusinessRole[]> = this.store.pipe(
    select(ApplicationSelectors.getCurrentBusinessRoles)
  );

  currentId$ = this.store.pipe(select(ApplicationSelectors.getCurrentId));

  preProcessingResult$ = this.store.pipe(
    select(ApplicationSelectors.getPreProcessingResult)
  );

  postProcessingResult$ = this.store.pipe(
    select(ApplicationSelectors.getPostProcessingResult)
  );

  loadedUDRs$ = this.store.pipe(
    select(ApplicationSelectors.getApplicationUDRsResult)
  );

  getUDRFromCache = (udrId: number) =>
    this.store.pipe(
      select(ApplicationSelectors.selectUDRByID(udrId)),
      filter((udrs) => udrs !== null && udrs !== undefined)
    );

  applicationUDRs$ = this.store.pipe(
    select(ApplicationSelectors.getApplicationUDRDetailsResult)
  );

  // Update Owner Success/Failure Output Streams

  updateApplicationOwnerSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationOwnerSuccess)
  );

  updateApplicationOwnerFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationOwnerFailure)
  );

  // Update Spoc Success/Failure Output Streams

  updateApplicationSpocSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationSpocSuccess)
  );

  updateApplicationSpocFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationSpocFailure)
  );

  // Update Business Roles Success/Failure Output Streams

  updateApplicationBusinessRolesSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationBusinessRolesSuccess)
  );

  updateApplicationBusinessRolesFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationBusinessRolesFailure)
  );

  // Create Application Success/Failure Output Streams

  createApplicationSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.createApplicationSuccess)
  );

  createApplicationFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.createApplicationFailure)
  );

  // Update Archive Success/Failure Output Streams

  updateApplicationArchiveSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationArchiveSuccess)
  );

  updateApplicationArchiveFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationArchiveFailure)
  );

  // Update Status Success/Failure Output Streams

  updateApplicationStatusSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationStatusSuccess)
  );

  updateApplicationStatusFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationStatusFailure)
  );

  updateApplicationUDRsSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationUDRsSucceeded)
  );

  updateApplicationUDRsSFailed$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationUDRsFailed)
  );

  updateApplicationFileRepositorySucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationFileRepositorySucceeded)
  );

  updateApplicationFileRepositoryFailed$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationFileRepositoryFailed)
  );

  updateApplicationUDFsSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationUDFsSuccess)
  );

  updateApplicationUDFsFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationUDFsFailure)
  );

  // Update Application Type Success/Failure Output Streams

  updateApplicationTypeSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationTypeSuccess)
  );

  updateApplicationTypeFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationTypeFailure)
  );

  // Update Application  Success/Failure Output Streams

  updateApplicationDetailsSucceeded$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationDetailsSuccess)
  );

  updateApplicationDetailsFailure$ = this.actions$.pipe(
    ofType(ApplicationActions.updateApplicationDetailsFailure)
  );

  applicationTypes$: Observable<ApplicationType[]> = this.store.pipe(
    select(ApplicationSelectors.getApplicationTypes),
    filter((applicationTypes) => applicationTypes?.length > 0)
  );

  statuses$: Observable<ApplicationStatuses[]> = this.store.pipe(
    select(ApplicationSelectors.getStatuses),
    filter((statuses) => statuses?.length > 0)
  );

  allApplicationAlerts$: Observable<RuleAlerts[]> = this.store.pipe(
    select(ApplicationSelectors.getAllApplicationAlerts),
    filter((allApplicationAlerts) => allApplicationAlerts?.length > 0)
  );

  constructor(private store: Store, private actions$: Actions) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  dispatchGetApplication(applicationId: string) {
    this.store.dispatch(ApplicationActions.getApplication({ applicationId }));
  }

  dispatchUpdateApplicationDetails(
    applicationId: string,
    model: EditApplicationSync
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationDetails({
        applicationId,
        model,
      })
    );
  }

  dispatchGetApplicationUDRs(
    appId,
    udrType,
    hasParent,
    includeUDRs,
    detailId,
    fetchDepth
  ) {
    this.store.dispatch(
      ApplicationActions.getApplicationUDRs({
        appId,
        udrType,
        hasParent,
        includeUDRs,
        detailId,
        fetchDepth,
      })
    );
  }

  dispatchUpdateApplicationUDRs(appId, webhookName) {
    this.store.dispatch(
      ApplicationActions.updateApplicationUDRs({ appId, webhookName })
    );
  }

  dispatchUpdateApplicationFileRepository(
    appId,
    udfId,
    jurisdictionId,
    webhookName
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationFileRepository({
        appId,
        udfId,
        jurisdictionId,
        webhookName,
      })
    );
  }

  dispatchUpdateApplicationUDFs(applicationId: string, udfs: UDF[]) {
    this.store.dispatch(
      ApplicationActions.updateApplicationUDFs({
        applicationId,
        udfs,
      })
    );
  }

  dispatchUpdateApplicationContent(applicationId: string, content: any) {
    this.store.dispatch(
      ApplicationActions.updateApplicationContent({
        applicationId,
        content,
      })
    );
  }

  dispatchCreateApplicationAssociation(
    applicationId: string,
    associatedIds: any
  ) {
    this.store.dispatch(
      ApplicationActions.createApplicationAssociation({
        applicationId,
        associatedIds,
      })
    );
  }

  dispatchUpdateApplicationType(
    applicationId: string,
    typeId: string,
    typeName: string,
    detail: string,
    maxCapacity: number,
    minCapacity: number
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationType({
        applicationId,
        typeId,
        typeName,
        detail,
        maxCapacity,
        minCapacity,
      })
    );
  }

  dispatchUpdateApplicationOwner(
    applicationIds: string[],
    owner: User,
    muteNotification: boolean
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationOwner({
        applicationIds,
        owner,
        muteNotification,
      })
    );
  }

  dispatchUpdateApplicationSpoc(
    applicationIds: string[],
    assignedSpoc: User,
    muteNotification: boolean
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationSpoc({
        applicationIds,
        assignedSpoc,
        muteNotification,
      })
    );
  }

  dispatchUpdateApplicationBusinessRoles(applicationId: string, payload: any) {
    this.store.dispatch(
      ApplicationActions.updateApplicationBusinessRoles({
        applicationId,
        payload,
      })
    );
  }

  dispatchUpdateApplicationArchive(
    archiveStatus: boolean,
    applicationId: string
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationArchive({
        archiveStatus,
        applicationId,
      })
    );
  }

  dispatchCreateApplication(payload) {
    this.store.dispatch(
      ApplicationActions.createApplication({
        payload,
      })
    );
  }

  dispatchUpdateApplicationStatus(
    applicationId: string,
    newStatusId: string,
    fileNames: string[],
    postBody: string,
    muteNotification: boolean,
    attachFilesToEmail: boolean,
    newStatusName: string,
    newOwnerId: string
  ) {
    this.store.dispatch(
      ApplicationActions.updateApplicationStatus({
        applicationId,
        newStatusId,
        fileNames,
        postBody,
        muteNotification,
        attachFilesToEmail,
        newStatusName,
        newOwnerId,
      })
    );
  }

  dispatchUpdateApplicationDueDate(newDueDate: string) {
    this.store.dispatch(
      ApplicationActions.updateApplicationDueDate({
        newDueDate,
      })
    );
  }

  dispatchGetApplicationTypes(override?: boolean) {
    this.store
      .pipe(select(ApplicationSelectors.getApplicationTypesLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(ApplicationActions.getApplicationTypes());
        }
      });
  }

  dispatchGetStatuses(override?: boolean) {
    this.store
      .pipe(select(ApplicationSelectors.getStatusesLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(ApplicationActions.getStatuses());
        }
      });
  }

  dispatchGetAllApplicationAlerts() {
    this.store
      .pipe(select(ApplicationSelectors.getAllApplicationAlerts), take(1))
      .subscribe((loaded) => {
        if (!loaded) {
          this.store.dispatch(ApplicationActions.getAllApplicationAlerts());
        }
      });
  }
}
