<div class="modal geocode-modal">
  <div class="small">
    <h2 i18n mat-dialog-title *ngIf="data?.addresses?.length > 0">
      Select an Address
    </h2>
    <h2 i18n mat-dialog-title *ngIf="data?.addresses?.length == 0">
      Invalid Address
    </h2>
    <mat-dialog-content class="mat-typography">
      <div *ngIf="data?.addresses?.length === 0">
        <div i18n>Your address could not be verified.</div>
      </div>

      <div *ngIf="data?.addresses?.length > 0">
        <mat-radio-group [(ngModel)]="selectedAddress">
          <h4 i18n>Verified Addresses</h4>
          <mat-radio-button
            class="verified-addresses"
            *ngFor="let address of data.addresses"
            [value]="address"
          >
            {{ ignoreLineTwo ? address.addressLineOne : address.fullAddress }}
          </mat-radio-button>

          <h4 i18n>Unverified Address</h4>
          <mat-radio-button [value]="data.keyAddress">
            {{ data.keyAddress }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-raised-button
        [disabled]="selectedAddress === null"
        class="primary-button"
        color="primary"
        (click)="returnAddress()"
        i18n
        *ngIf="data?.addresses?.length > 0"
      >
        Use Selected Address
      </button>
      <button
        mat-raised-button
        class="primary-button"
        color="primary"
        (click)="closeModal()"
        i18n
        *ngIf="data?.addresses?.length == 0"
      >
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>
