import { FormlyFieldConfig } from '@ngx-formly/core';

export function cypressExtension(field: FormlyFieldConfig) {
  if (
    !field.props ||
    (field.wrappers && field.wrappers.indexOf('cypress') !== -1)
  ) {
    return;
  }

  field.wrappers = [...(field.wrappers || []), 'cypress'];
}
