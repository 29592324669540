import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocusignFile } from '@intellio/shared/models';
import { AppConfigService, AuthService } from '..';
import { BaseService } from './base-service.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicationDocusignService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getAppDocusignEnvelopes(appId: string) {
    const filesUrl = `/api/docusignEsignature/${appId}`;
    return this.get<DocusignFile[]>(filesUrl, {});
  }

  resendDocusignEnvelope(
    envelopeId: string,
    applicationId: string,
    originalFileName: string
  ) {
    const payload = {
      EnvelopeId: envelopeId,
      OriginalFileApplicationId: applicationId,
      OriginalFileName: originalFileName,
    };

    const url = `/api/docusignEsignature/resend`;
    return this.post(url, payload);
  }

  downloadDocusignEnvelopeFile(envelopeId: string) {
    const url = `/api/docusignEsignature/envelope/${envelopeId}/download`;

    this.getFile(url).pipe(take(1)).subscribe();
  }
}
