import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function feinValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  return value && !value.match(/^[1-9]\d?-\d{7}$/)
    ? {
        invalidFein: true,
      }
    : null;
}
