export class SystemLocation {
  systemId: number;
  fullAddress: string;
  energySourceId: string;
  energySourceName: string;
  projectName: string;
  latitude: number;
  longitude: number;
  systemType: any;
  intentOfGenerationId: string;
  jurisdictionId: string;
}
