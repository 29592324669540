import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModuleConfig } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class TenantModuleConfigService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getTenantModuleConfigs() {
    const url = '/api/tenant/modules/configs';
    return this.get<ModuleConfig[]>(url);
  }

  getModuleConfigs(moduleId: string) {
    const url = `/api/tenant/modules/${moduleId}/configs`;
    return this.get<ModuleConfig[]>(url);
  }
}
