export class VerifiedAddressModel {
  addressCity: string;
  addressLineOne: string;
  addressLineTwo: string;
  addressState: string;
  addressZIP: string;
  fullAddress: string;
  latitude: number;
  locationType: number;
  longitude: number;
}
