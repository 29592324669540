import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getChildObject } from './form-data.utility';
import { takeUntil } from 'rxjs/operators';

export const prefillInverterDetailsHook = (destroyed$): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.form
      .get(field.key as string)
      ?.valueChanges.pipe(takeUntil(destroyed$))
      .subscribe((newVal) => {
        if (newVal) {
          const p = field.parent;
          const mfgObj = getChildObject(p, 'inverterManufacturer');
          const mfg = field.form.get(mfgObj.key);
          const modelNumber = newVal;
          const inverter = field.options.formState.inverters.find((inv) => {
            return (
              inv.manufacturer == mfg.value && inv.modelNumber == modelNumber
            );
          });
          if (inverter) {
            const ratingkWObj = getChildObject(p, 'inverterRatingkW');
            const ratingkW = field.form.get(ratingkWObj.key);
            ratingkW.setValue(inverter.kWRating);

            const ratingkVAObj = getChildObject(p, 'inverterRatingKVA');
            const ratingkVA = field.form.get(ratingkVAObj.key);
            ratingkVA.setValue(inverter.kVARating);

            const ratedVoltageObj = getChildObject(p, 'inverterRatingVoltage');
            const ratedVolts = field.form.get(ratedVoltageObj.key);
            ratedVolts.setValue(inverter.voltageRating);

            const ratedDCVoltageObj = getChildObject(
              p,
              'inverterDCRatedVoltage'
            );

            const ratedDCVolts = field.form.get(ratedDCVoltageObj.key);
            ratedDCVolts.setValue(inverter.nominalDCVoltage);

            const ratedDCCircuitVoltsObj = getChildObject(
              p,
              'inverterDCCircuitVoltage'
            );
            const ratedDCCircuitVolts = field.form.get(
              ratedDCCircuitVoltsObj.key
            );
            ratedDCCircuitVolts.setValue(inverter.ratingCircuitVoltage);

            const inverterIsCertifiedObj = getChildObject(
              p,
              'inverterIsCertified'
            );
            const inverterIsCertified = field.form.get(
              inverterIsCertifiedObj.key
            );
            inverterIsCertified.setValue(inverter.ieeE1547Certified);

            // When we are pre-filling an inverter
            // ensure that hidden inverter fields
            // remain in a disabled state
            const mfgOtherObj = getChildObject(p, 'inverterManufacturerOther');
            const mfgOther = field.form.get(mfgOtherObj.key);
            mfgOther.disable();

            const modelOtherObj = getChildObject(p, 'inverterModelOther');
            const modelOther = field.form.get(modelOtherObj.key);
            modelOther.disable();

            const cutSheetObj = getChildObject(p, 'inverterCutSheet');
            const cutSheet = field.form.get(cutSheetObj.key);
            cutSheet.disable();
          }
        }
      });
  };
};
