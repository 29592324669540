import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApplication from './application/application.reducer';
import * as fromAccount from './account/account.reducer';
import { ApplicationEffects } from './application/application.effects';
import { ApplicationFacade } from './application/application.facade';
import { AccountEffects } from './account/account.effects';
import * as fromAuth from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';
import { AuthFacade } from './auth/auth.facade';
import { AccountFacade } from './account/account.facade';
import * as fromQueueFilter from './queue-filter/queue-filter.reducer';
import { QueueFilterEffects } from './queue-filter/queue-filter.effects';
import { QueueFilterFacade } from './queue-filter/queue-filter.facade';
import * as fromViewHeader from './view-header/view-header.reducer';
import { ViewHeaderEffects } from './view-header/view-header.effects';
import { ViewHeaderFacade } from './view-header/view-header.facade';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromApplication.APPLICATION_FEATURE_KEY,
      fromApplication.reducer
    ),
    EffectsModule.forFeature([ApplicationEffects]),
    StoreModule.forFeature(
      fromAccount.ACCOUNT_FEATURE_KEY,
      fromAccount.reducer
    ),
    EffectsModule.forFeature([AccountEffects]),
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(
      fromQueueFilter.QUEUE_FILTER_FEATURE_KEY,
      fromQueueFilter.reducer
    ),
    EffectsModule.forFeature([QueueFilterEffects]),
    StoreModule.forFeature(
      fromViewHeader.VIEW_HEADER_FEATURE_KEY,
      fromViewHeader.reducer
    ),
    EffectsModule.forFeature([ViewHeaderEffects]),
    RouterModule,
  ],
  providers: [
    ApplicationFacade,
    AccountFacade,
    AuthFacade,
    QueueFilterFacade,
    ViewHeaderFacade,
  ],
})
export class SharedStateMgmtModule {}

// NOTE: We intentionally only export the facades
// from this module. That forces consumers
// of state to only interact with NgRx through the simple
// facade API and not have to deal with
// actions, effects, reducers, and selectors.

export { ApplicationFacade } from './application/application.facade';
export { AccountFacade } from './account/account.facade';
export { AuthFacade } from './auth/auth.facade';
export { QueueFilterFacade } from './queue-filter/queue-filter.facade';
export { ViewHeaderFacade } from './view-header/view-header.facade';

// these need to be exported for an application resolver
export {
  ApplicationActionsUnion,
  getApplicationSuccess,
  getApplicationFailure,
  ApplicationActionTypes,
} from './application/application.actions';
