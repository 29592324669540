import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FileUploadWithMetadata,
  Link,
  LogoTypes,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AdminBrandingService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getLinks() {
    const url = '/api/tenant/links';
    return this.get<Link[]>(url);
  }

  addLink(linkData) {
    const url = '/api/tenant/link';
    return this.post<Link>(url, linkData);
  }

  updateLink(linkData) {
    const url = '/api/tenant/link/' + linkData.Link.linkId;
    return this.put<Link>(url, linkData);
  }

  deleteLink(linkId) {
    const url = '/api/tenant/link/' + linkId;
    return this.delete(url);
  }

  uploadFile(type: LogoTypes, file: FileUploadWithMetadata) {
    let url;
    switch (type) {
      case LogoTypes.Logo:
        url = '/api/tenant/logo';
        break;
      case LogoTypes.ExternalLogo:
        url = '/api/tenant/external-logo';
        break;
      case LogoTypes.HeroImage:
        url = '/api/tenant/hero-image';
        break;
      case LogoTypes.SecondaryExternalLogo:
        url = '/api/tenant/secondary-external-logo';
        break;
    }
    const params = new HttpParams();
    params.append('sizeInBytes', file.file.size.toString());
    return this.uploadFiles(url, [file], { params });
  }
}
