export class UDF {
  categoryId?: number;
  displayOrder: number;
  id: string;
  isAvailableForTemplates: boolean;
  isConfiguredToRecordUDFValue: boolean;
  isShownInEditor: boolean;
  isShownInQueue: boolean;
  isVisibleExternally: boolean;
  isVisibleOnlyToSystemSupport: boolean;
  name: string;
  type: string;
  validationRegex?: string;
  value?: string | number;
  newValue?: string | number;
  ishidden: boolean;
  privilegeLevel: number;
  isActive: boolean = true;
  originalUDFCategory?: number;
  isSearchable?: boolean;
  isFilterable?: boolean;
}
