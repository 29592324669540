import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaveBeforeSavingComponent } from '../leave-before-saving/leave-before-saving.component';

export abstract class CanComponentDeactivate {
  constructor(protected dialogService: MatDialog) {}

  abstract canDeactivate(): Observable<boolean> | boolean;

  public showStandardCancelModal(): Observable<boolean> {
    const modalRef = this.dialogService.open(LeaveBeforeSavingComponent);

    return modalRef.afterClosed();
  }
}

@Injectable({
  providedIn: 'root',
})
export class LeaveBeforeSavingGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (component.canDeactivate === undefined) {
      throw new Error(
        'component guarded with LeaveBeforeSavingGuard must extend class CanComponentDeactivate'
      );
    }

    // SO: https://stackoverflow.com/a/46584358
    if (nextState.url === '/login') {
      return true; // bypass checks if we are trying to go to /login
    }

    return component.canDeactivate();
  }
}
