import {
  Component,
  ChangeDetectionStrategy,
  Type,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

interface TextAreaProps extends FormlyFieldProps {
  autosize?: boolean;
  autosizeMinRows?: number;
  autosizeMaxRows?: number;
}

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<TextAreaComponent>;
}

@Component({
  selector: 'itc-formly-text-area',
  template: `
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>{{ props.label }}</mat-label>
      <textarea
        matInput
        [name]="stringKey"
        [formControl]="control"
        [cols]="props.cols"
        [rows]="props.rows"
        class="form-control"
        [class.is-invalid]="showError"
        [disabled]="props.disabled"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
      ></textarea>
    </mat-form-field>
  `,
  styles: ['.full-width {width: 100%;}'],
  providers: [
    // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
    // rely on formControl value instead of elementRef which return empty value in Firefox.
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: TextAreaComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent
  extends FieldType<FieldTypeConfig<TextAreaProps>>
  implements OnInit
{
  override defaultOptions = {
    props: {
      cols: 1,
      rows: 1,
    },
  };

  control: UntypedFormControl;
  stringKey: string;

  ngOnInit() {
    this.stringKey = this.key as string;
    this.control = this?.formControl as UntypedFormControl;
  }
}
