<div class="modal">
  <div>
    <h2 i18n mat-dialog-title>
      Upload File(s)
      <button mat-icon-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <div class="in-place-spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
      <div *ngIf="dataSource.data.length > 0">
        <div class="table-container">
          <table
            class="file-table"
            mat-table
            [formGroup]="formGroup"
            #statusChangesTable
            [dataSource]="dataSource"
          >
            <ng-container>
              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef i18n>File</th>
                <td mat-cell *matCellDef="let file" class="file-table-column">
                  <span class="filename">{{ file.fileName }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileUploadDate">
                <th mat-header-cell *matHeaderCellDef i18n>Date</th>
                <td mat-cell *matCellDef="let file" class="file-table-column">
                  {{ file.fileUploadDate | date : 'shortDate' }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="fileHeaders; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let app; columns: fileHeaders"></tr>
            </ng-container>
          </table>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="secondary-button" mat-button i18n (click)="onCancel()">
        Cancel
      </button>
      <button
        class="primary-button"
        mat-button
        i18n
        (click)="onUpload()"
        [disabled]="loading || !formGroup.valid"
        data-cy="confirm-upload-btn"
      >
        Upload File(s)
      </button>
    </mat-dialog-actions>
  </div>
</div>
