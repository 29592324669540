import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingBarService } from './loading-bar.service';
import { catchError, finalize, tap } from 'rxjs/operators';

@Injectable()
export class LoadingBarInterceptor implements HttpInterceptor {
  constructor(private service: LoadingBarService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith('http')) {
      return next.handle(req);
    }

    this.service.markRequestStarted();

    return next.handle(req).pipe(
      finalize(() => {
        this.service.markRequestedEnded();
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
}
