import {
  Component,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { getValueOnObjectFromStringProperty } from '@intellio/shared/utils';

@Component({
  selector: 'itc-formly-readonly',
  templateUrl: './formly-readonly.component.html',
  styleUrls: ['./formly-readonly.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormlyReadonlyComponent {
  @Input() form: UntypedFormGroup;
  @Input() model: any;
  readOnlyForm = [];

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(event) {
    event.preventDefault();
    this.setupPrint();
  }

  setupPrint() {
    this.readOnlyForm = [];
    this.printHelper(this.form, '');
  }

  printHelper(group, keyPath) {
    //for each control in the dictionary

    const controlGroup = [];
    this.getAllControls(group.controls, controlGroup, keyPath);

    for (const controlKey in group.controls) {
      const control = group.controls[controlKey];
      if (!this.shouldBeShown(control, keyPath + '.' + controlKey)) {
        //dont show fields where the condition for showing them is not met
        continue;
      }
      if (control['_fields'] && control['_fields'][0].props.title) {
        this.readOnlyForm.push({
          title: control['_fields'][0].props.title,
        });
      }
      //if the sub control has deeper sub controls then it is a FormGroup
      if ('controls' in control) {
        const formGroup = control as UntypedFormGroup;
        this.printHelper(formGroup, keyPath + '.' + controlKey);
        //else it is a FormControl
      } else {
        const formControl = control as UntypedFormControl;
        this.addFormControlToReadonlyView(
          formControl,
          keyPath + '.' + controlKey
        );
      }
    }
  }

  getAllControls(
    controls: UntypedFormGroup | UntypedFormControl | any,
    foundControls,
    keypath
  ) {
    //const controlGroup = getAllControls(group.controls, [], keyPath);
    if (controls === null || controls === undefined) {
      return;
    }
    if (controls instanceof UntypedFormControl) {
      foundControls.push(controls);
    } else if (controls instanceof UntypedFormGroup) {
      this.getAllControls(controls.controls, foundControls, keypath);
    } else {
      for (const [key, val] of Object.entries(controls)) {
        this.getAllControls(val, foundControls, keypath + '.' + key);
      }
    }
  }

  addFormControlToReadonlyView(
    formControl: UntypedFormControl,
    keyPath: string
  ) {
    const props = formControl['_fields'][0].props;
    const value = getValueOnObjectFromStringProperty(
      keyPath.substring(1), //substring removes prepended .
      this.model
    );

    const field = {};

    field['value'] = value;
    if (props.label) {
      field['label'] = props.label;
    } else if (props.placeholder) {
      field['label'] = props.placeholder;
    }

    if (props.addonRight?.text) {
      field['unitType'] = props.addonRight?.text;
    }

    this.readOnlyForm.push(field);
  }

  //checks if conditional is present and true
  shouldBeShown(formGroup: UntypedFormGroup, keyPath: string): boolean {
    const props = formGroup['_fields'][0].props;
    const conditions = props.condition;

    if (!conditions) {
      //no conditions present, control should be shown
      return true;
    }

    //note: conditions are AND not OR when multiple are present
    for (const index in conditions) {
      const condition = conditions[index];

      //find the value the condition checks
      let value;
      if (condition.keyIsGlobal) {
        value = getValueOnObjectFromStringProperty(condition.key, this.model);
      } else {
        value = getValueOnObjectFromStringProperty(
          `${keyPath.substring(1)}.${condition.key}`, //substring removes prepended .
          this.model
        );
      }

      if (condition.operator == '!' && condition.value == value) {
        //condition not met, hide field
        return false;
      } else if (condition.value != value) {
        //condition not met, hide field
        return false;
      }
    }
    //no false conditions found, control should be shown
    return true;
  }
}
