import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getFormRoot } from './form-data.utility';
import { startWith, takeUntil } from 'rxjs';

export const joinMulticheckboxValuesHook = (
  functionName,
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const parameters = functionName.replace(' ', '').split(',');
    const checkboxFieldKey = parameters[0];
    const checkboxStringArray = parameters[1];
    const separator = parameters[2];
    const root = getFormRoot(field);
    const formControl = root.formControl.get(checkboxFieldKey);
    formControl.valueChanges
      .pipe(startWith([]), takeUntil(destroyed$))
      .subscribe(() => {
        let checkboxStringValue = root.model[checkboxStringArray];
        let value = checkboxStringValue?.map((option) => {
          if (option.startsWith('key:') && option.endsWith(':key')) {
            const optionField = root.formControl.get(
              option.replace('key:', '').replace(':key', '')
            );
            return optionField?.value ?? '';
          } else {
            return option;
          }
        });
        let joinedString = value?.join(separator);

        if (!field.props.disabled) {
          field.formControl.setValue(joinedString);
        }
      });
  };
};
