import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import {
  AnalyticsEventBindType,
  AnalyticsEventType,
  Application,
  User,
} from '@intellio/shared/models';
// bug 66468 all import lines
import { AnalyticsService } from '@intellio/shared/services'; // eslint-disable-line
import { fromEvent, Subscription } from 'rxjs'; // eslint-disable-line
import { AccountFacade, ApplicationFacade } from '@intellio/shared/state-mgmt'; // eslint-disable-line

/*
  Usage examples (non-exhaustive):
  <button itcEvent="changePassword" />
  <button id="changePassword" itcEvent />
  <button itcEvent="changePassword" [itcEventProperties]="{key: 'value'}" />
  <button itcEvent="changePassword" itcEventBind="blur" />
  <button itcEvent="changePassword" [itcEventProperties]="{key: 'value'}" itcEventBind="focus" />
*/

@Directive({
  selector: '[itcEvent]',
})
export class AnalyticsEventDirective implements OnDestroy {
  @Input() itcEvent: AnalyticsEventType | '';
  @Input() id: string;
  @Input() itcEventProperties: { [key: string]: any };
  @Input() set itcEventBind(eventBind: AnalyticsEventBindType) {
    if (this.bindSubscription) {
      this.bindSubscription.unsubscribe();
    }

    this._itcEventBind = eventBind;
    this.bindSubscription = fromEvent(
      this.el.nativeElement,
      eventBind
    ).subscribe(() => this.trigger());
  }
  get itcEventBind(): AnalyticsEventBindType {
    return this._itcEventBind;
  }

  private bindSubscription?: Subscription;
  private _itcEventBind: AnalyticsEventBindType;
  private currentUser: User;
  private currentApplication: Application;

  constructor(
    private analyticsService: AnalyticsService,
    private readonly el: ElementRef,
    private accountFacade: AccountFacade,
    private applicationFacade: ApplicationFacade
  ) {
    this.itcEventBind = 'click';
    this.accountFacade.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });
    this.applicationFacade.currentApplication$.subscribe((app) => {
      this.currentApplication = app;
    });
  }

  ngOnDestroy() {
    if (this.bindSubscription) {
      this.bindSubscription.unsubscribe();
    }
  }

  protected trigger() {
    this.analyticsService.logEvent(
      this.itcEvent || this.id,
      Object.assign(
        {},
        {
          userEmail: this.currentUser?.email,
          appId: this.currentApplication?.id,
        },
        this.itcEventProperties
      )
    );
  }
}
