import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  APPLICATION_FEATURE_KEY,
  ApplicationState,
  ApplicationPartialState,
} from './application.reducer';
import { UDRDetail } from '@intellio/shared/models';

// Lookup the 'Application' feature state managed by NgRx
export const getApplicationState = createFeatureSelector<ApplicationState>(
  APPLICATION_FEATURE_KEY
);

export const getApplicationLoaded = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.loaded
);

export const getApplicationError = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.error
);

export const getApplication = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.currentApplication
);

export const getCurrentId = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.currentId
);

export const getCurrentBusinessRoles = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.currentBusinessRoles
);

export const getPreProcessingResult = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.preProcessingResult
);

export const getPostProcessingResult = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.postProcessingResult
);

export const getApplicationUDRsResult = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.loadedUDRs
);

export const getApplicationUDRDetailsResult = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.applicationUDRDetails
);

export const getApplicationTypes = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.applicationTypes
);

export const getApplicationTypesLoaded = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.applicationTypesLoaded
);

export const getStatuses = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.statuses
);

export const getStatusesLoaded = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.statusesLoaded
);

export const getAllApplicationAlerts = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.allApplicationAlerts
);

export const getAllApplicationAlertsLoaded = createSelector(
  getApplicationState,
  (state: ApplicationState) => state.allApplicationAlertsLoaded
);

export const selectUDRByID = (udrId: number) =>
  createSelector(
    getApplicationUDRDetailsResult,
    (udrs: Record<number, UDRDetail[]>) => {
      if (udrs !== null) {
        return udrs[udrId];
      } else {
        return null;
      }
    }
  );
