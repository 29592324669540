export class MessageListRequestParams {
  Search: string;
  Skip: number;
  Take: number;
  Sort: string;
  Asc: boolean;
  My: boolean;
  GetExternalMessages: boolean;
  SpocFilter: string;
}
