import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  getFormRoot,
  getValueOnObjectFromStringProperty,
} from '@intellio/shared/utils';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'itc-field-aggregator',
  template: `
    <ng-container [ngSwitch]="type">
      <input
        *ngSwitchCase="'currency'"
        matInput
        [id]="id"
        [type]="type"
        [readonly]="props.readonly"
        [required]="props.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        prefix="$"
        mask="separator"
        thousandSeparator=","
      />
      <input
        *ngSwitchCase="'number'"
        matInput
        [id]="id"
        type="number"
        [readonly]="props.readonly"
        [required]="props.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
      />
      <input
        *ngSwitchDefault
        matInput
        [id]="id"
        [type]="type || 'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="control"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
      />
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldAggregatorComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  get type() {
    return this.props.type || 'text';
  }

  control: UntypedFormControl;

  product = 0;
  private destroyed$ = new Subject();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control = this?.formControl as UntypedFormControl;

    if (this.field.props.formFieldPaths) {
      this.field.props.formFieldPaths.forEach((element) => {
        const isObject = typeof element === 'object';

        let formControl = null;
        if (isObject) {
          formControl = element.keyIsGlobal
            ? this.field.form.root
            : this.field.form;
        } else {
          formControl = this.field.props.aggregateGlobally
            ? this.field.form.root
            : this.field.form;
        }
        const el = formControl.get(isObject ? element.key : element);
        el?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
          let root = getFormRoot(this.field);
          const model = root.model;
          const result = eval(this.field.props.aggregateCondition);

          if (
            !this.field.props.aggregateCondition ||
            (this.field.props.aggregateCondition && result)
          ) {
            this.updateAggregation(this.field, root);
          }
        });
      });
    } else {
      //if only aggregating constants, only need to do once
      if (this.field.props.constants) {
        const constantProduct = this.field.props.constants.reduce(
          (sum, value) => {
            if (this.field.props.operator === 'add') {
              return sum + +value;
            }
            if (this.field.props.operator === 'multiply') {
              return sum * +value;
            }
          },
          this.field.props.operator === 'add' ? 0 : 1
        );
        if (!this.formControl.disabled) {
          this.formControl.setValue(constantProduct);
        }
      }
    }
  }

  updateAggregation(field, root) {
    const hasValue = field.props.formFieldPaths.some((key) => {
      const isObject = typeof key === 'object';
      let val = null;
      if (isObject) {
        val = key.keyIsGlobal
          ? getValueOnObjectFromStringProperty(key.key, root.model)
          : this.model[key.key];
      } else {
        val = field.props.aggregateGlobally
          ? getValueOnObjectFromStringProperty(key, root.model)
          : this.model[key];
      }

      return val != null;
    });
    let formFieldProduct = 0;
    if (hasValue) {
      formFieldProduct = field.props.formFieldPaths.reduce(
        (sum, key: string | any) => {
          const isObject = typeof key === 'object';
          let num = 0;
          if (isObject) {
            num = key.keyIsGlobal
              ? getValueOnObjectFromStringProperty(key.key, root.model)
              : this.model[key.key];
          } else {
            num = field.props.aggregateGlobally
              ? getValueOnObjectFromStringProperty(key, root.model)
              : this.model[key];
          }

          if (num === null || num === undefined) {
            return sum;
          } else {
            if (field.props.operator === 'add') {
              return sum + +num;
            }
            if (field.props.operator === 'multiply') {
              return sum * +num;
            }
          }
        },
        this.field.props.operator === 'add' ? 0 : 1
      );
    }

    if (field.props.constants) {
      const constantProduct = field.props.constants.reduce(
        (sum, value) => {
          if (field.props.operator === 'add') {
            return sum + +value;
          }
          if (field.props.operator === 'multiply') {
            return sum * +value;
          }
        },
        this.field.props.operator === 'add' ? 0 : 1
      );
      if (field.props.operator === 'add') {
        this.product = formFieldProduct + constantProduct;
      }
      if (field.props.operator === 'multiply') {
        this.product = formFieldProduct * constantProduct;
      }
    } else {
      this.product = formFieldProduct;
    }

    if (field.props.round && !field.props.roundDecimal) {
      // For rounding to the nearest tens, hundreds, thousands, ...
      this.product =
        Math.round(this.product / field.props.round) * field.props.round;
      if (!this.formControl.disabled) {
        this.formControl.setValue(this.product);
      }
    } else {
      if (field.props.type === 'currency') {
        if (!this.formControl.disabled) {
          this.formControl.setValue(
            (Math.round(this.product * 100) / 100).toFixed(2)
          );
          return;
        }
      }

      // For rounding to the nearest tenths, hundreths, thousandths, ...
      if (field.props.roundDecimal) {
        if (!this.formControl.disabled) {
          this.formControl.setValue(
            this.roundToNDigits(this.product, field.props.round)
          );
        }
      } else {
        if (!this.formControl.disabled) {
          this.formControl.setValue(this.roundToNDigits(this.product, 2));
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  // Based on this SO Post: https://stackoverflow.com/a/18358056
  roundToNDigits(num: any, precision: any) {
    return +(
      Math.round(Number(num + `e+${precision.toString()}`)) +
      `e-${precision.toString()}`
    );
  }
}
