import { Injectable } from '@angular/core';
import { ThemeMode } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _themeName = '';
  private _mode: ThemeMode;

  setTheme(name: string, mode: ThemeMode): void {
    this._themeName = name;
    this._mode = mode;
    const styleTag = document.head.querySelector(
      `link[rel="stylesheet"].style-manager-theme`
    );
    styleTag.setAttribute('href', `${name}-${mode}.css`);
  }

  changeMode(mode: ThemeMode): void {
    this.setTheme(this._themeName, mode);
  }
}
