import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-formly-wrapper-panel',
  styleUrls: ['./title.component.scss'],
  template: `
    <div class="title">
      <div class="title__title">{{ props.title }}</div>
      <div class="title__subtitle">{{ props.subtitle }}</div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent extends IntellioFormlyWrapperComponent {
  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  ngOnInit() {
    this.initChangeDetectSub();
  }
}
