import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
// import { DataPersistence } from '@nx/angular';

import * as RootFeature from './root.reducer';
import * as RootActions from './root.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class RootEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.init),
      map(() => RootActions.loadRootSuccess({ root: [] }))
    )
  );
  //  init$ = createEffect(() => this.dataPersistence.fetch(RootActions.init, {
  //     run: (action: ReturnType<typeof RootActions.init>, state: RootFeature.RootPartialState) => {
  //       // Your custom service 'load' logic goes here. For now just return a success action...
  //       return RootActions.loadRootSuccess({ root: [] });
  //     },

  //     onError: (action: ReturnType<typeof RootActions.init>>, error) => {
  //       console.error('Error', error);
  //       return RootActions.loadRootFailure({ error });
  //     }
  //   }));

  constructor(
    private actions$: Actions //private dataPersistence: DataPersistence<RootFeature.RootPartialState>
  ) {}
}
