import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { UDRDetail } from '@intellio/shared/models';
import { map, throwError } from 'rxjs';
import { WebhookService } from './webhook.service';

@Injectable({
  providedIn: 'root',
})
export class AppUdrService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private webhookService: WebhookService
  ) {
    super(client, configService, authService);
  }

  getAppUDRDetails(
    appId,
    udrType,
    hasParent,
    includeUDRs = true,
    detailId = undefined,
    fetchDepth = 1
  ) {
    const getUDRUrl = `/api/udrs/details`;
    let params = new HttpParams();
    params = params.append('ApplicationId', appId);
    if (
      hasParent !== null &&
      hasParent !== undefined &&
      (detailId !== null || detailId !== undefined)
    ) {
      params = params.append('HasParent', hasParent);
    }
    if (includeUDRs !== null && includeUDRs !== undefined) {
      params = params.append('IncludeUDRs', includeUDRs);
    }
    if (udrType) {
      params = params.append('UDRType', udrType);
    }
    if (detailId !== null && detailId !== undefined) {
      params = params.append('DetailId', detailId);
    }
    if (fetchDepth !== null && fetchDepth !== undefined) {
      params = params.append('FetchDepth', fetchDepth);
    }
    return this.getWithoutResponseBase<UDRDetail[]>(getUDRUrl, {
      params,
    });
  }

  getUDRDetail(detailId) {
    const getUDRUrl = '/api/udrs/detail/' + detailId;
    return this.get<UDRDetail>(getUDRUrl);
  }

  getUDRDetailByValue(value) {
    const getUDRUrl = '/api/udrs/details/value/' + value;
    return this.get<UDRDetail[]>(getUDRUrl) as any;
  }

  deleteUDRDetail(detailId) {
    const deleteUDRUrl = '/api/udrs/detail/' + detailId;
    let params = new HttpParams();
    params = params.append('deleteChildEntries', true);
    return this.delete<UDRDetail>(deleteUDRUrl, {
      params,
    });
  }

  addUDR(appId, idToFetch, tenant, udfId, webhookName) {
    let data = {
      ApplicationId: appId,
      Value: idToFetch,
      TenantId: tenant,
      UDFId: udfId,
      ApplicationActivityType: 'UDRChange',
    };
    return this.webhookService.postWebhook(webhookName, data).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  refreshUDRS(appId, webhookName) {
    let data = {
      appId: appId,
    };
    return this.webhookService.postWebhook(webhookName, data).pipe(
      map((res: any) => {
        if (res && res.data) {
          var output = res.data['Result'];
          return output as boolean;
        } else {
          return false;
        }
      })
    );
  }
}
