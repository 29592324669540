export class FlattenedApplicationsFilter {
  id?: number;
  isDefault: boolean;
  isFavorite: boolean;
  name: string;
  jurisdiction?: string[];
  intentOfGeneration?: string[];
  startDate?: string;
  endDate?: string;
  source?: string;
  type?: string;
  businessRole?: number;
  usersForBusinessRole?: string[];
  owner?: string[];
  spoc?: string[];
  status?: string[];
  alert?: string;
  internalCreator?: boolean;
  applicationOrigin?: boolean;
  paymentBalance?: boolean;
  StatusTimerDurationOperator?: string;
  StatusTimerDuration?: number;
  actionRequired?: boolean;
  applicationCreatorUser?: string[];
}
