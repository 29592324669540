import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { ContextualHelpModalComponent } from './contextual-help-modal/contextual-help-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'itc-formly-field-button',
  styleUrls: ['./button.component.scss'],
  template: `
    <div class="formly-field-button">
      <button
        [type]="props.type"
        [disabled]="disabled"
        (click)="onClick($event)"
      >
        {{ props.text }}
      </button>
      <mat-icon
        class="formly-field-button__icon"
        *ngIf="props.contextualHelp"
        [ngStyle]="{ cursor: 'pointer' }"
        (click)="openContextualHelpModal()"
      >
        help
      </mat-icon>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  constructor(private matDialog: MatDialog) {
    super();
  }

  control: UntypedFormControl;

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
  }

  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }

  openContextualHelpModal() {
    this.matDialog.open(ContextualHelpModalComponent, {
      data: this.field,
    });
  }
}
