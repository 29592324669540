export class Notification {
  id: number;
  applicationId: string;
  type: string;
  subjectLineOne: string;
  subjectLineTwo: string;
  severityLevel: string;
  sentDateTime: string;
  body: string;
  isRead: boolean;
  readDateTime: string;
}
