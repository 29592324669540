import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import {
  ApplicationTypeStatusBase,
  ApplicationTypeStatusGroup,
} from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class ChangeStatusService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(httpClient, configService, authService);
  }

  updateStatusChange(
    appId,
    newStatusId,
    fileNames,
    postBody,
    ownerNotificationMuted,
    attachFilesToEmail,
    newOwnerId
  ) {
    const data = {
      NewStatus: newStatusId,
      fileNames: fileNames.map((file) =>
        file?.name ? file.name : file
      ) as string[],
      PostBody: postBody,
      MuteNotification: ownerNotificationMuted,
      AttachFilesToEmailNotification: attachFilesToEmail,
      NewOwnerId: newOwnerId,
    };
    const changeStatusUrl = `/api/applications/${appId}/status`;
    return this.put(changeStatusUrl, data);
  }

  getStatusesForType(typeId) {
    const typeIdUrl = `/api/applications/types/${typeId}/statuses`;
    return this.get<ApplicationTypeStatusBase[]>(typeIdUrl);
  }
  getStatusGroupForType() {
    const typeIdUrl = `/api/applications/types/statuses/groups`;
    return this.get<ApplicationTypeStatusGroup[]>(typeIdUrl);
  }
}
