import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Aggregation } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppAggregationService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getGridHierarchyDERAggregationLogs(applicationId) {
    const url =
      '/api/applications/' + applicationId + '/gridhierarchyderaggregation';
    return this.get<Aggregation>(url);
  }
}
