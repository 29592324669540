import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleDropdownNavItem } from '@intellio/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'itc-title-dropdown-nav',
  templateUrl: './title-dropdown-nav.component.html',
  styleUrls: ['./title-dropdown-nav.component.scss'],
})
export class TitleDropdownNavComponent implements OnInit, OnChanges {
  @Input() navList: TitleDropdownNavItem[];
  @Input() currentNavRoute: string;
  @Input() navStyle: string;

  currentNav: TitleDropdownNavItem;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentNav = this.navList?.find(
      (n) => n.route === this.currentNavRoute
    );
  }

  ngOnChanges(): void {
    this.currentNav = this.navList.find(
      (n) => n.route === this.currentNavRoute
    );
  }
}
