import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Application,
  ApplicationTypeStatusBase,
  Task,
  FilterTasks,
  User,
} from '@intellio/shared/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
/* eslint-disable @nx/enforce-module-boundaries  */
// bug 66468 all imports
import {
  AppTasksService,
  NotificationService,
  SideDrawerService,
  AnalyticsService,
} from '@intellio/shared/services';
import { ApplicationFacade } from '@intellio/shared/state-mgmt';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddTaskAssignmentComponent } from '../add-task-assignment/add-task-assignment.component';
import { AppTaskDeleteComponent } from '../app-task-delete/app-task-delete.component';

@Component({
  // eslint-disable-next-line
  selector: 'itc-app-tasks-filter',
  templateUrl: './app-tasks-filter.component.html',
  styleUrls: ['./app-tasks-filter.component.scss'],
})
export class AppTasksFilterComponent implements OnInit, AfterViewInit {
  @Input() task: Task;
  @Input() statusList: ApplicationTypeStatusBase[];
  @Input() applicationStatuses: [];
  @Input() addNew: boolean;
  @Input() performantMode: boolean;
  @Input() noTaskStatus: boolean;
  @Output() newFilter = new EventEmitter<any>();

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data: any,
    //public dialogRef: MatDialogRef<AppTasksFilterComponent>,
    private sideDrawerService: SideDrawerService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private applicationFacade: ApplicationFacade,
    private analyticsService: AnalyticsService
  ) {}
  formGroup: UntypedFormGroup;
  currentApplication: Application;
  dialogRef: MatDialogRef<AppTasksFilterComponent>;
  addAssigneeDialogRef: MatDialogRef<AddTaskAssignmentComponent>;
  deleteTaskDialogRef: MatDialogRef<AppTaskDeleteComponent>;

  filterCount = 0;
  filterSelections = <FilterTasks>{};
  anotherAssignee = true;

  ngOnInit(): void {
    this.analyticsService.startPageLoadTimer('TasksFilterDrawerLoad');
    this.formGroup = this.formBuilder.group({
      assignees: [[]],
      dueBefore: [null],
      dueAfter: [null],
      priority: [null],
      applicationStatusId: [null],
      status: [null],
      search: [null],
    });
    this.applicationFacade.currentApplication$
      .pipe(take(1))
      .subscribe((data) => {
        this.currentApplication = data;
      });
  }

  addAssigneeDialog() {
    this.addAssigneeDialogRef = this.dialog.open(AddTaskAssignmentComponent);
    this.addAssigneeDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        data?.addedUsers?.forEach((user) => {
          this.formGroup.controls['assignees'].value.push(user);
        });
        data?.addedGroups?.forEach((group) => {
          this.formGroup.controls['assignees'].value.push(group);
        });
        this.formGroup.controls['assignees'].markAsDirty();
      }
    });
  }

  deleteAssignment(assignment) {
    if (assignment.user?.fullName != null) {
      this.formGroup.controls['assignees'].setValue(
        this.formGroup.controls['assignees'].value.filter(function (a) {
          return a.user?.fullName !== assignment.user?.fullName;
        })
      );
    } else {
      this.formGroup.controls['assignees'].setValue(
        this.formGroup.controls['assignees'].value.filter(function (a) {
          return a.group?.name !== assignment.group?.name;
        })
      );
    }
  }

  countFilters() {
    this.filterCount = 0;
    for (const field in this.formGroup.controls) {
      if (this.formGroup.controls[field].value?.toString().length > 0) {
        this.filterCount++;
      }
    }
  }

  applyModalFilters() {
    for (const field in this.formGroup.controls) {
      if (this.formGroup.controls[field].value?.toString().length > 0) {
        if (['dueBefore', 'dueAfter'].includes(field)) {
          const day = this.formGroup.controls[field].value
            ?.getDate()
            ?.toString();
          const month = (
            this.formGroup.controls[field].value?.getMonth() + 1
          )?.toString();
          const year = (
            this.formGroup.controls[field].value?.getYear() + 1900
          )?.toString();
          this.filterSelections[field] = `${month}-${day}-${year}`;
        } else if (field === 'assignees') {
          if (this.performantMode) {
            const assigneeSimplified = this.formGroup.controls[
              field
            ].value?.map((a) =>
              a.userId != null ? `userId:${a.userId}` : `groupId:${a.groupId}`
            );
            this.filterSelections[field] = assigneeSimplified;
          } else {
            this.filterSelections[field] = this.formGroup.controls[
              field
            ].value.map((a) => a.userId ?? a.groupId);
          }
        } else if (['priority', 'status'].includes(field)) {
          // Multiselect fields
          this.filterSelections[field] = this.formGroup.controls[field].value;
        } else {
          this.filterSelections[field] =
            this.formGroup.controls[field].value?.toString();
        }
      }
    }
    this.closeDrawer();
    this.countFilters();
    if (this.filterCount > 0) {
      this.newFilter.emit({
        filterSelections: this.filterSelections,
      });
    }
  }

  closeDrawer() {
    this.sideDrawerService.closeExistingDialog();
  }

  ngAfterViewInit(): void {
    this.analyticsService.endPageLoadTimer('TasksFilterDrawerLoad');
  }
}
