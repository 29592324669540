import { createReducer, on, Action } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as ApplicationActions from './application.actions';
import {
  Application,
  ApplicationStatuses,
  ApplicationType,
  ApplicationUDFDetail,
  BusinessRole,
  RuleAlerts,
  UDF,
  UDRDetail,
} from '@intellio/shared/models';

export const APPLICATION_FEATURE_KEY = 'application';

export interface ApplicationState {
  currentApplication: Application;
  currentId: string; // which Application record has been selected
  loaded: boolean; // has the Application been loaded
  error?: string | null; // last known error (if any)
  preProcessingResult?: Record<string, unknown> | null;
  postProcessingResult?: Record<string, unknown> | null;
  currentBusinessRoles: BusinessRole[];
  loadedUDRs: boolean;
  applicationUDRDetails: Record<number, UDRDetail[]>;
  applicationTypes: ApplicationType[];
  applicationTypesLoaded: boolean;
  statuses: ApplicationStatuses[];
  statusesLoaded: boolean;
  allApplicationAlerts: RuleAlerts[];
  allApplicationAlertsLoaded: boolean;
}

export interface ApplicationPartialState {
  readonly [APPLICATION_FEATURE_KEY]: ApplicationState;
}

export const initialState: ApplicationState = {
  // set initial required properties
  currentApplication: null,
  loaded: false,
  currentId: '',
  preProcessingResult: null,
  postProcessingResult: null,
  currentBusinessRoles: null,
  loadedUDRs: false,
  applicationUDRDetails: null,
  applicationTypes: undefined,
  applicationTypesLoaded: false,
  statuses: undefined,
  statusesLoaded: false,
  allApplicationAlerts: undefined,
  allApplicationAlertsLoaded: false,
};

const applicationReducer = createReducer(
  initialState,
  on(ApplicationActions.getApplication, (state, { applicationId }) => {
    state = {
      ...state,
      loaded: false,
    };

    return state;
  }),
  on(ApplicationActions.getApplicationSuccess, (state, { application }) => {
    state = {
      ...state,
      loaded: true,
      currentApplication: application,
    };

    return state;
  }),
  on(ApplicationActions.getApplicationFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(ApplicationActions.updateApplicationUDFsSuccess, (state, { udfs }) => {
    return (state = {
      ...state,
      currentApplication: {
        ...state.currentApplication,
        applicationUDFDetails: MergeUDFs(
          state.currentApplication.applicationUDFDetails,
          udfs
        ),
      },
    });
  }),
  on(ApplicationActions.updateApplicationDetailsSuccess, (state, { model }) => {
    const mappedNewUdfVals: ApplicationUDFDetail[] = Object.entries(
      model.applicationUDFs
    ).map((entry) => {
      const [key, value] = entry;
      let udf = new ApplicationUDFDetail();
      udf.id = key;
      udf.value = typeof value === 'string' ? value : '';
      return udf;
    });
    return (state = {
      ...state,
      currentApplication: {
        ...state.currentApplication,
        accountNumber:
          model.accountNumber ?? state.currentApplication.accountNumber,
        applicationUDFDetails: state.currentApplication.applicationUDFDetails
          .filter(
            (udf) => !mappedNewUdfVals.find((newVal) => udf.id === newVal.id)
          )
          .concat(mappedNewUdfVals),
      },
    });
  }),
  on(
    ApplicationActions.updateApplicationTypeSuccess,
    (state, { typeId, typeName, detail, maxCapacity, minCapacity }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          typeId: typeId,
          typeName: typeName,
          typeDetail: detail,
          typeJurisdictionMaxNameplateCapacity: maxCapacity,
          typeJurisdictionMinNameplateCapacity: minCapacity,
        },
      });
    }
  ),
  on(ApplicationActions.updateApplicationOwnerSuccess, (state, { owner }) => {
    return (state = {
      ...state,
      currentApplication: {
        ...state.currentApplication,
        owner,
      },
    });
  }),
  on(
    ApplicationActions.updateApplicationContentSuccess,
    (state, { content }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          content,
        },
      });
    }
  ),
  on(
    ApplicationActions.createApplicationAssociationSucceeded,
    (state, { associatedId }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          applicationAssociationId: associatedId,
        },
      });
    }
  ),
  on(
    ApplicationActions.updateApplicationSpocSuccess,
    (state, { assignedSpoc }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          assignedSpoc,
        },
      });
    }
  ),
  on(
    ApplicationActions.updateApplicationBusinessRolesSuccess,
    (state, { businessRoles }) => {
      return (state = {
        ...state,
        currentBusinessRoles: businessRoles,
      });
    }
  ),
  on(ApplicationActions.createApplicationSuccess, (state, { result }) => {
    return (state = {
      ...state,
      currentId: result.applicationId,
      preProcessingResult: result.preProcessingResult,
      postProcessingResult: result.postProcessingResult,
    });
  }),
  on(
    ApplicationActions.updateApplicationArchiveSuccess,
    (state, { archiveStatus }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          isArchived: archiveStatus,
        },
      });
    }
  ),
  on(
    ApplicationActions.updateApplicationStatusSuccess,
    (state, { newStatusId, newStatusName }) => {
      return (state = {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          statusId: newStatusId,
          statusName: newStatusName,
        },
      });
    }
  ),
  on(
    ApplicationActions.getApplicationUDRsSucceeded,
    (state, { detailId, result }) => {
      return (state = {
        ...state,
        applicationUDRDetails: {
          ...state.applicationUDRDetails,
          [detailId]: result,
        },
      });
    }
  ),
  on(ApplicationActions.updateApplicationUDRsSucceeded, (state, { result }) => {
    return (state = {
      ...state,
      loadedUDRs: result,
    });
  }),
  on(ApplicationActions.updateApplicationDueDate, (state, { newDueDate }) => {
    return (state = {
      ...state,
      currentApplication: {
        ...state.currentApplication,
        applicationDueDate: newDueDate,
      },
    });
  }),
  on(
    ApplicationActions.getApplicationTypesSucceeded,
    (state, { applicationTypes }) => {
      state = {
        ...state,
        applicationTypes: applicationTypes,
        applicationTypesLoaded: true,
      };
      return state;
    }
  ),
  on(ApplicationActions.getStatusesSucceeded, (state, { statuses }) => {
    state = {
      ...state,
      statuses: statuses,
      statusesLoaded: true,
    };
    return state;
  }),
  on(
    ApplicationActions.getAllApplicationAlertsSucceeded,
    (state, { allApplicationAlerts }) => {
      state = {
        ...state,
        allApplicationAlerts: allApplicationAlerts,
        allApplicationAlertsLoaded: true,
      };
      return state;
    }
  )
);

export function MergeUDFs(current: ApplicationUDFDetail[], updates: UDF[]) {
  var newList: ApplicationUDFDetail[] = [];
  current.forEach((udf) => newList.push(udf));
  updates.forEach((udf) => {
    var exists = newList.find((u) => u.id === udf.id && u.name === udf.name);
    var newDetail = {
      id: udf.id,
      name: udf.name,
      type: udf.type,
      value: udf.value,
    } as ApplicationUDFDetail;
    if (exists !== undefined) {
      exists.value = newDetail.value;
    } else {
      newList.push(newDetail);
    }
  });
  return newList;
}

export function reducer(state: ApplicationState | undefined, action: Action) {
  return applicationReducer(state, action);
}
