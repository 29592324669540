export class CreateAccountModel {
  FirstName: string;
  LastName: string;
  Email: string;
  NewPassword: string;
  ConfirmPassword: string;
  Role: string;
  Id?: string;
  Code: string;
}
