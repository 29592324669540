import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { IconRegistry } from '@intellio/shared/components';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import {
  AppConfigService,
  AuthService,
  SessionStorageService,
} from '@intellio/shared/services';
import { AccountFacade } from '@intellio/shared/state-mgmt';

@Component({
  selector: 'itc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  hasLoggedIn = false;
  /* The app component is only meant as an empty shell for the rest of the application,
  that lives in the connect-root library */
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    translateService: TranslateService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private authService: AuthService,
    private accountFacade: AccountFacade,
    private appConfigService: AppConfigService
  ) {
    Object.entries(IconRegistry).forEach(([iconName, fileName]) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/font-svgs/${fileName}.svg`
        )
      );
    });

    this.authService.authToken$.subscribe((token) => {
      if (token) {
        this.hasLoggedIn = true;
      } else if (token === null && this.hasLoggedIn) {
        this.accountFacade.dispatchLogoutAccount();
      }
    });

    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url;

        // If the url has callback in it and includes an id token
        // this means this url is a callback from the SSO process
        if (
          url.indexOf('callback') !== -1 &&
          url.indexOf('callback') < url.indexOf('code')
        ) {
          const ssoQueryString = url.split('?')[1];
          const ssoTokens = ssoQueryString.split('&');

          const ssoTokenDictionary = {};
          for (let i = 0; i < ssoTokens.length; i++) {
            const pair = ssoTokens[i].split('=');

            if (pair[0] === 'error') {
              switch (pair[1]) {
                case 'invalidAccount':
                  this.router.navigate(['sso-invalid-account']);
                  return;

                case 'ssoLoginError':
                  this.router.navigate(['sso-login-error']);
                  return;
              }
            }
            ssoTokenDictionary[pair[0]] = pair[1];
          }

          this.sessionStorageService.set(
            'SSOLogin',
            JSON.stringify(ssoTokenDictionary)
          );
          // clear out the query params from
          // the URL to hide the access code
          this.router.navigate(['callback']);
        }
      });

    translateService.setDefaultLang('DistributedGeneration');

    this.loadGoogleAnalytics(this.googleAnalyticsMeasurementId);
  }

  googleAnalyticsMeasurementId: string =
    this.appConfigService.tenantConfig.googleAnalyticsMeasurementId;

  loadGoogleAnalytics(trackingID: string): void {
    window.googleAnalyticsMeasurementId = trackingID;

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
    );

    let gaScript2 = document.createElement('script');
    gaScript2.setAttribute('src', `assets/google-analytics.js`);

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}
