import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class WebhookService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  postWebhook(webhookId: string, payload: any) {
    const url = '/api/webhooks/' + webhookId;
    return this.post(url, payload);
    // Enable if you want to mock an error response
    //return throwError({ status: 404, message: 'not found' }).pipe(delay(1000));
  }
  //postWebhook() {}
}
