import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formErrorMessage',
})
export class FormErrorMessagePipe implements PipeTransform {
  transform(errors: ValidationErrors | null): string {
    if (errors == null) {
      return '';
    } else {
      switch (Object.keys(errors)[0]) {
        case 'required': {
          return 'This field is required.';
        }
        case 'invalidPhoneNumber': {
          return 'Please enter only 10 numbers. No special characters. Use format: ##########';
        }
        case 'passwordsDoNotMatch': {
          return 'Passwords do not match.';
        }
        case 'invalidAccountNumber': {
          return 'Please use valid account number';
        }
        case 'invalidZipCode': {
          return 'Please use valid 5 digit zip code';
        }
        case 'invalidFein': {
          return 'Please use valid FEIN number';
        }
        default: {
          return 'Please fill out a valid value.';
        }
      }
    }
  }
}
