import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntellioHttpResponse } from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { AppConfigService, AuthService } from '..';
import { BaseService } from './base-service.service';

@Injectable({ providedIn: 'root' })
export class VanillaHttpService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }
}
