import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';

import * as AuthFeature from './auth.reducer';
import { getAuth, getAuthSuccess } from './auth.actions';
// bug 66468
import { AuthService } from '@intellio/shared/services'; // eslint-disable-line
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getAccountFailure } from '../account/account.actions';

@Injectable()
export class AuthEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAuth),
      mergeMap(({ username, password, recaptchaResponse }) =>
        this.authService.loginUser(username, password, recaptchaResponse).pipe(
          map((token) => getAuthSuccess({ token })),
          catchError((error: any) => of(getAccountFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
