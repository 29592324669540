export class FileUploadWithMetadata {
  file: File;
  fileName: string;
  fileMimeType: string;
  fileTypeId: string;
  notes?: string;
  uploaderId?: string;
  uploadedBy?: string;
  uploaderFullName?: string;
  fileUploadDate?: string;
  uploadedOn?: string;
  forInternalUseOnly?: boolean;
}
