export const processData = (
  options: Record<string, unknown>,
  data: any
): unknown => {
  if (
    options['headers'] &&
    options['headers']['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    const str: string[] = [];
    for (const p in data) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(data[p])}`);
    }
    return str.join('&');
  }
  return data;
};
