import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppPaymentsService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getPayments(applicationId) {
    const getPaymentsUrl = `/api/applications/${applicationId}/payments`;
    return this.get<any[]>(getPaymentsUrl);
  }

  getMostRecentPayment(applicationId) {
    const getPaymentUrl = `/api/applications/${applicationId}/payment/recent`;
    return this.get<any>(getPaymentUrl);
  }

  getTotalCost(applicationId) {
    const getTotalCostUrl = `/api/applications/${applicationId}/paymentCost`;
    return this.get<any>(getTotalCostUrl);
  }

  getPricing(id, capacity) {
    const getPricingUrl = `/api/applications/${id}/pricing?nameplateCapacity=${capacity}`;
    return this.get<any>(getPricingUrl);
  }

  getPaymentTypes() {
    const getPaymentTypesUrl = '/api/payments/type';
    return this.get<any>(getPaymentTypesUrl);
  }

  getPaymentStatuses() {
    const getPaymentStatusesUrl = '/api/payments/transactionStatus';
    return this.get<any>(getPaymentStatusesUrl);
  }

  createPaymentTransaction(payment) {
    const createPaymentTransactionUrl = '/api/payments';
    return this.post(createPaymentTransactionUrl, payment);
  }

  getPaymentTransaction(paymentId) {
    const getPaymentTransactionUrl = `/api/payments/${paymentId}`;
    return this.get<any>(getPaymentTransactionUrl);
  }

  updatePaymentTransaction(payment) {
    const updatePaymentTransactionUrl = `/api/payments/${payment.id}`;
    return this.put(updatePaymentTransactionUrl, payment);
  }
}
