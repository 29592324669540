import {
  ApplicationQueueHeader,
  ApplicationViewHeader,
  UDF,
} from '@intellio/shared/models';
import { createReducer, on, Action } from '@ngrx/store';

import * as ViewHeaderActions from './view-header.actions';

export const VIEW_HEADER_FEATURE_KEY = 'viewHeader';

export interface ViewHeadersState {
  applicationHeaders: ApplicationViewHeader[];
  queueHeaders: ApplicationQueueHeader[];
  udfQueueHeaders: ApplicationQueueHeader[];
  relatedAppsHeaders: ApplicationQueueHeader[];
  appHeadersloaded: boolean; // has the app headers list been loaded
  queueHeadersloaded: boolean; 
  relatedAppsHeadersloaded: boolean; 
  error?: string | null; // last known error (if any)
}

export interface ViewHeaderPartialState {
  readonly [VIEW_HEADER_FEATURE_KEY]: ViewHeadersState;
}

export const initialState: ViewHeadersState = {
  // set initial required properties
  applicationHeaders: [],
  queueHeaders: [],
  udfQueueHeaders: [],
  relatedAppsHeaders: [],
  appHeadersloaded: false,
  queueHeadersloaded: false,
  relatedAppsHeadersloaded: false
};

const viewHeaderReducer = createReducer(
  initialState,
  on(
    ViewHeaderActions.getApplicationHeadersSuccess,
    (state, { applicationHeaders }) => ({
      ...state,
      applicationHeaders,
    })
  ),
  on(ViewHeaderActions.getApplicationHeadersFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    ViewHeaderActions.getQueueHeadersSuccess,
    (state, { queueHeaders, udfs, categoryId}) => ({
      ...state,
      queueHeaders: mergeQueueHeaders(queueHeaders, udfs, categoryId),
      udfQueueHeaders: getUDFQueueHeaders(queueHeaders, udfs, categoryId),
    })
  ),
  on(ViewHeaderActions.getQueueHeadersFailure, (state, { error }) => ({
      ...state,
      error,
    })
  ), 
  on(ViewHeaderActions.getRelatedAppsHeadersSuccess,
    (state, { relatedAppsHeaders }) => ({
      ...state, 
      relatedAppsHeaders,
    })
  ), 
  on(ViewHeaderActions.getRelatedAppsHeadersFailure, 
    (state, { error }) => ({
      ...state, 
      error,
    })  
  )
);

function getUDFQueueHeaders(
  queueHeaders: ApplicationQueueHeader[],
  udfs: UDF[],
  categoryId: string
): ApplicationQueueHeader[] {
  const udfHeaders =
    udfs
      ?.filter((udf) => udf.isShownInQueue ) 
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map<ApplicationQueueHeader>((udf) => {
        return {
          headerId: udf.id,
          name: udf.name,
          sort: 'none',
        };
      }) || [];

  return udfHeaders;
}

function mergeQueueHeaders(
  queueHeaders: ApplicationQueueHeader[],
  udfs: UDF[],
  categoryId: string
): ApplicationQueueHeader[] {
  const udfHeaders = getUDFQueueHeaders(queueHeaders, udfs, categoryId);

  const udfLocation = queueHeaders.findIndex(
    (qh) => qh.headerId === '{{UDFPlaceholder}}'
  );
  if (udfLocation === -1) {
    return queueHeaders;
  }

  const preUdfHeaders = queueHeaders.slice(0, udfLocation);
  let mergedQueueHeaders = preUdfHeaders.concat(udfHeaders);

  if (udfLocation + 1 !== queueHeaders.length) {
    mergedQueueHeaders = mergedQueueHeaders.concat(
      queueHeaders.slice(udfLocation + 1)
    );
  }
  return mergedQueueHeaders;
}

export function reducer(state: ViewHeadersState | undefined, action: Action) {
  return viewHeaderReducer(state, action);
}
