import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  getChildObjects,
  getChildObjectsWithPath,
  getFormRoot,
  interpolateStringValues,
} from './form-data.utility';
import { Application } from '@intellio/shared/models';

/* eslint-disable rxjs/no-nested-subscribe */
// bug 66465

export function searchDown(node: FormlyFieldConfig, desired: string) {
  if (node == null) {
    return null;
  }
  if (
    node.form != null &&
    node.form.get(desired) != null &&
    node.key === desired
  ) {
    return node;
  }
  if (node.fieldGroup == null) {
    return null;
  }
  for (let i = 0; i < node.fieldGroup.length; i++) {
    const val = searchDown(node.fieldGroup[i], desired);
    if (val != null) {
      return val;
    }
  }
}

export function getFormattedCurrentDate(format) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const today = new Date();
  const d = String(today.getDate()); // Short day (ex. 3)
  const dd = d.length > 1 ? d : '0' + d; // Padded day (ex. 03)
  const mm =
    String(today.getMonth() + 1).length > 1
      ? String(today.getMonth() + 1)
      : '0' + String(today.getMonth() + 1); // Month number (ex. 10)
  const mmmm = monthNames[today.getMonth()]; // Full month name (ex. October)
  const yyyy = String(today.getFullYear()); // Full year (ex. 2019)
  const yy = yyyy.slice(-2); // Last 2 digits of the year (ex. 19)

  const formattedDate = format
    .toLowerCase()
    .replace('dd', dd)
    .replace('d', d)
    .replace('mmmm', mmmm)
    .replace('mm', mm)
    .replace('yyyy', yyyy)
    .replace('yy', yy);
  return formattedDate;
}

export function getOrdinalForNumber(num) {
  const lastDigit = num.toString().split('').pop();
  if (lastDigit == '1') {
    return 'st';
  } else if (lastDigit == '2') {
    return 'nd';
  } else if (lastDigit == '3') {
    return 'rd';
  } else {
    return 'th';
  }
}

export function getStringInterpolationMapping(
  application: Application,
  form: FormlyFieldConfig[],
  model
) {
  const mappings = {};
  const day = getFormattedCurrentDate('D');
  const dayStr = day + getOrdinalForNumber(day);
  mappings['MM/DD/YYYY'] = getFormattedCurrentDate('MM/DD/YYYY');
  mappings['Day'] = dayStr;
  mappings['MonthName'] = getFormattedCurrentDate('MMMM');
  mappings['Year'] = getFormattedCurrentDate('YYYY');
  const opCo = application.system?.jurisdictionName?.replace('-', ' ');
  mappings['typeName'] = application.typeName;
  mappings['OperatingCompany'] = opCo;

  let pricingSourceField = null;
  form.forEach((val) => {
    const x = getPricingSourceField(val, model);
    if (x !== null && x !== undefined) {
      pricingSourceField = x;
    }
  });

  const modelVals = Object.values(model);
  if (pricingSourceField) {
    const appCapacity = lookup(modelVals, pricingSourceField.value.key);

    mappings['NameplateGrossCapacityInKW'] = appCapacity;
  }

  const root = getFormRoot(form[0]);
  const fields: FormlyFieldConfig[] = [];
  for (let i = 0; i < root.fieldGroup.length; i++) {
    //get all fields with the decorator
    const groups: FormlyFieldConfig[] = getChildObjects(
      root.fieldGroup[i],
      'interpolationMapping'
    );
    groups.forEach((element) => {
      const temp = element.form.get(element.key.toString());
      mappings[element['interpolationMapping']] = temp.value;
      fields.push(element);
    });
  }
  return mappings;
}

//use to retrieve values from the model json due to nesting
export function lookup(obj, k) {
  if (typeof obj != 'object' || obj === null || obj === undefined) {
    return null;
  }
  let result = null;
  // intentional lint ignore
  // eslint-disable-next-line
  if (obj.hasOwnProperty(k)) {
    return obj[k];
  } else {
    for (const o in obj) {
      result = lookup(obj[o], k);
      if (result == null) continue;
      else break;
    }
  }
  return result;
}

export function getPricingSourceField(form, model) {
  //We pass in the model here because it needs to be in the context for the eval statement to work
  const pricingSourceFields = [];

  for (let i = 0; i < form.fieldGroup?.length; i++) {
    const groups = getChildObjectsWithPath(form.fieldGroup[i], 'pricingSource');
    if (groups && groups.length > 0) {
      for (let j = 0; j < groups.length; j++) {
        pricingSourceFields.push(groups[j]);
      }
    }
  }

  if (!pricingSourceFields) {
    console.log('no field is configured as the pricing source.');
    return;
  }

  let pricingSourceField = null;
  for (let i = 0; i < pricingSourceFields.length; i++) {
    if (eval(pricingSourceFields[i].value.pricingSource)) {
      pricingSourceField = pricingSourceFields[i];
    }
  }
  return pricingSourceField;
}

export function setupInterpolation(functionName, application, field, isAsync) {
  const syncArgs = functionName.slice(1);
  const splitArgs = syncArgs[0].replace(/'/g, '').split(',');
  const path = splitArgs[!isAsync ? 0 : 1]?.split('.');
  let dest = field;
  path.forEach((element) => {
    element = element.trim();
    dest = dest[element];
  });
  const label = splitArgs[0].trim();
  const p = field.parent;
  const sibling = searchDown(p, label);
  const root = getFormRoot(p);
  const callback = () => {
    const mapping = getStringInterpolationMapping(
      application,
      root?.fieldGroup,
      root.model
    );
    let setter = field;
    for (let i = 0; i < path.length - 1; i++) {
      setter = setter[path[i].trim()];
    }
    const interpolated = interpolateStringValues(dest, mapping);
    setter[path[path.length - 1]] = interpolated;
    if (field.type === 'help') {
      field.formControl.setValue(interpolated);
    }
  };

  if (isAsync) {
    if (sibling.type === 'button') {
      sibling.props.onClick = callback;
    }
  } else {
    callback();
  }
}
