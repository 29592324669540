import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AppTasksService,
  ApplicationFilesService,
} from '@intellio/shared/services';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '@intellio/shared/services';
import { throwError } from '@microsoft/applicationinsights-core-js';

@Component({
  selector: 'itc-task-file-delete',
  templateUrl: './task-file-delete.component.html',
  styleUrls: ['./task-file-delete.component.scss'],
})
export class TaskFileDeleteComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fileService: ApplicationFilesService,
    public dialogRef: MatDialogRef<TaskFileDeleteComponent>,
    private notificationService: NotificationService
  ) {}
  deletingFile = false;

  deleteFile() {
    this.deletingFile = true;
    this.fileService
      .deleteTaskFile(
        this.data.taskFile.taskId,
        this.data.taskFile.fileName,
        null
      )
      .pipe(
        catchError((err: any) => {
          this.deletingFile = false;
          this.notificationService.open(err.error.message, 'error');
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.deletingFile = false;
        this.dialogRef.close();
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const temp = this.data;
  }
  decodeFileName(filename) {
    return decodeURIComponent(filename);
  }
}
