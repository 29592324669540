import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[itcSticky]',
})
export class StickyDirective implements OnInit, OnDestroy {
  @Input()
  scrollYThreshold: number;
  @Input()
  scrollDebounceTime = 100;
  @Input()
  classOnActivation: string;

  @HostBinding('class.sticky-header')
  stickyHeaderClass = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer2: Renderer2
  ) {}

  ngOnInit() {
    fromEvent(window, 'scroll')
      .pipe(debounceTime(this.scrollDebounceTime))
      .subscribe(() => {
        if (window.scrollY >= this.scrollYThreshold) {
          this.stickyHeaderClass = true;

          if (this.classOnActivation) {
            this.renderer2.addClass(this.element, this.classOnActivation);
          }
        } else {
          this.stickyHeaderClass = false;

          if (this.classOnActivation) {
            this.renderer2.removeClass(this.element, this.classOnActivation);
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
