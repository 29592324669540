import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[itcMask]',
})
export class FieldMaskDirective {
  @Input('itcMask')
  mask = '*';

  @Input('isMaskEnabled')
  isMaskEnabled: boolean;

  input: HTMLInputElement;

  feinNumber = [];

  allDigitsEntered = false;

  constructor(el: ElementRef) {
    this.input = el.nativeElement;
  }

  @HostListener('keydown', ['$event', '$event.keyCode'])
  onKeyDown($event: KeyboardEvent, keyCode) {
    if (!this.isMaskEnabled) {
      return;
    }

    if ($event.metaKey || $event.ctrlKey) {
      return;
    }

    // TAB
    if (keyCode !== 9) {
      $event.preventDefault();
    }

    const key = String.fromCharCode(keyCode);
    const cursorPos = this.input.selectionStart;

    switch (keyCode) {
      case 37:
        this.handleLeftArrow(cursorPos);

        return;

      case 39:
        this.handleRightArrow(cursorPos);

        return;

      case 38:
        return;

      case 40:
        return;

      case 8:
        this.handleBackspace(cursorPos);

        return;

      case 46:
        this.handleDelete(cursorPos);

        return;

      default:
        if (keyCode > 57 || keyCode < 48) {
          return;
        }

        this.handleKey(cursorPos, key);

        return;
    }
  }

  handleKey(cursorPos, key) {
    if (this.input.value.length === 10) {
      this.allDigitsEntered = true;
    } else {
      this.allDigitsEntered = false;
    }

    if (this.allDigitsEntered) {
      return;
    }

    this.feinNumber.splice(cursorPos, 0, key);
    if (cursorPos < 6) {
      if (this.input.value.length == 2) {
        this.typeCharAtPosition(2, '-');
        this.typeCharAtPosition(3, '*');
        this.input.setSelectionRange(4, 4);
        this.handleExtraStars(cursorPos + 1, false);
        return;
      } else {
        this.typeCharAtPosition(cursorPos, '*');
      }
    } else {
      this.typeCharAtPosition(cursorPos, key);
    }
    this.input.setSelectionRange(cursorPos + 1, cursorPos + 1);

    this.handleExtraStars(cursorPos, false);
  }

  handleExtraStars(cursorPos, backspace) {
    if (cursorPos < 6 && this.input.value.length > 6 && !backspace) {
      this.input.value =
        '**-***' + this.feinNumber[5] + this.input.value.slice(7);
      this.input.setSelectionRange(cursorPos + 1, cursorPos + 1);
    } else if (cursorPos < 6 && this.input.value.length > 6 && backspace) {
      this.input.value =
        '**-***' + this.feinNumber[5] + this.input.value.slice(7);
      if (cursorPos >= 0) {
        this.input.setSelectionRange(cursorPos, cursorPos);
      }
    }
  }

  handleBackspace(cursorPos) {
    if (
      cursorPos > 0 &&
      this.input.value.length > 3 &&
      this.input.value[cursorPos - 1] == '-'
    ) {
      return;
    } else if (cursorPos === 4 && this.input.value.length === 4) {
      this.input.value = this.input.value.slice(0, 3);
    }
    this.feinNumber.splice(cursorPos - 1, 1);
    const previousPos = this.calculatePreviousCursorPos(cursorPos);

    if (previousPos >= 0) {
      this.overWriteCharAtPosition(this.input, previousPos, '');
      this.input.setSelectionRange(previousPos, previousPos);
    }
  }

  handleDelete(cursorPos) {
    this.feinNumber.splice(cursorPos, 1);
    this.overWriteCharAtPosition(this.input, cursorPos, '');
    this.input.setSelectionRange(cursorPos, cursorPos);
  }

  handleLeftArrow(cursorPos) {
    const previousPos = this.calculatePreviousCursorPos(cursorPos);

    if (previousPos >= 0) {
      this.input.setSelectionRange(previousPos, previousPos);
    }
  }

  calculatePreviousCursorPos(cursorPos) {
    const valueBeforeCursor = this.input.value.slice(0, cursorPos);

    return valueBeforeCursor.length - 1;
  }

  handleRightArrow(cursorPos) {
    const nextPos = cursorPos + 1;

    if (nextPos >= 0) {
      this.input.setSelectionRange(nextPos, nextPos);
    }
  }

  overWriteCharAtPosition(
    input: HTMLInputElement,
    position: number,
    key: string
  ) {
    const currentValue = input.value;

    input.value =
      currentValue.slice(0, position) + key + currentValue.slice(position + 1);
  }

  typeCharAtPosition(cursorPos: number, key: string) {
    const value = this.input.value;
    this.input.value = value.slice(0, cursorPos) + key + value.slice(cursorPos);
  }
}
