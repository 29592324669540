import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { combineLatest, filter, startWith } from 'rxjs';
import { getChildObject, getFormRoot } from './form-data.utility';

export const displayGenerationRebateCalculation = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    const numPanelsObj = getChildObject(p, 'numberOfPanels');
    const numberOfPanels = numPanelsObj.formControl.value;
    const dcRatingObj = getChildObject(p, 'dcRating');
    const dcRating = dcRatingObj.formControl.value;
    const enrollNetMeteringObj = getChildObject(
      getFormRoot(field),
      'netMeteringEnrollment'
    );
    const enrollNetMetering = enrollNetMeteringObj.formControl.value;

    combineLatest<[number, number, number]>([
      numPanelsObj.formControl.valueChanges.pipe(
        filter((v) => v != null),
        startWith(numPanelsObj.formControl.value)
      ),
      dcRatingObj.formControl.valueChanges.pipe(
        filter((v) => v != null),
        startWith(dcRatingObj.formControl.value)
      ),
      enrollNetMeteringObj.formControl.valueChanges.pipe(
        filter((v) => v != null && v != undefined),
        startWith(enrollNetMeteringObj.formControl.value)
      ),
    ]).subscribe(([numberOfPanels, dcRating, enrollNetMetering]) => {
      field.formControl.setValue(
        `${numberOfPanels} panels * ${dcRating}kW * $${enrollNetMetering}`
      );
    });
  };
};
