import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UDF, UdfCategory, UdfOptions } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getUDFs(typeId) {
    const getUDFsUrl = '/api/applications/types/' + typeId + '/udfs';
    return this.get<UDF[]>(getUDFsUrl);
  }

  getApplicationUDFCategories() {
    const getUDFCategoriesURL = '/api/applications/udfs/categories';
    return this.get<UdfCategory[]>(getUDFCategoriesURL);
  }

  getUDFDropdownOptions() {
    const getUDFsUrl = '/api/applications/udfs/options';
    return this.get<UdfOptions[]>(getUDFsUrl);
  }

  updateUDFs(model: UDF[], appId: string) {
    const updateUDFsUrl = '/api/applications/' + appId + '/udfs';
    return this.post<string>(updateUDFsUrl, model);
  }
}
