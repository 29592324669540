import { Injectable } from '@angular/core';
import { isJson } from '@intellio/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  get(key: string) {
    const storageItem = localStorage.getItem(key);
    if (isJson(storageItem)) {
      return JSON.parse(storageItem);
    }
    return storageItem;
  }

  set(key: string, value: unknown) {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      try {
        const stringVal = JSON.stringify(value);
        localStorage.setItem(key, stringVal);
      } catch (error) {
        localStorage.setItem(key, error);
      }
    }
    return this.get(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  doesExist(key: string) {
    if (this.get(key)) {
      return true;
    }
    return false;
  }
}
