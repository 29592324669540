import { ChangeDetectorRef } from '@angular/core';
import { FormlyFieldConfig, FieldWrapper } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

export abstract class IntellioFormlyWrapperComponent<
  F extends FormlyFieldConfig = FormlyFieldConfig
> extends FieldWrapper<F> {
  protected destroyed$ = new Subject<void>();

  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super();
  }

  initChangeDetectSub() {
    this.notifierService.submitClicked$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cd.detectChanges();
      });
  }
}
