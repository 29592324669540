import { Token } from '@intellio/shared/models';
import { createAction, props } from '@ngrx/store';

export enum AuthActionTypes {
  GetAuth = '[Auth] Get',
  GetAuthSuccess = '[Auth] Get Succeeded',
  GetAuthFailure = '[Auth] Get Failure',
}

export const getAuth = createAction(
  AuthActionTypes.GetAuth,
  props<{ username: string; password: string; recaptchaResponse: string }>()
);

export const getAuthSuccess = createAction(
  AuthActionTypes.GetAuthSuccess,
  props<{ token: Token }>()
);

export const getAuthFailure = createAction(
  AuthActionTypes.GetAuthFailure,
  props<{ error: any }>()
);
