import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
// bug 66468 all imports
import {
  // eslint-disable-line
  AnalyticsService,
  AppConfigService,
  AuthService,
  FeatureManagementService,
  LoginService,
  ModuleService,
  PermissionService,
  RoleService,
} from '@intellio/shared/services';
import { AccountFacade } from '@intellio/shared/state-mgmt'; // eslint-disable-line
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AppBootstrapResolver implements Resolve<Observable<boolean>> {
  constructor(
    private appConfigService: AppConfigService,
    private analyticsService: AnalyticsService,
    private moduleService: ModuleService,
    private permissionService: PermissionService,
    private loginService: LoginService,
    private roleService: RoleService,
    private accountFacade: AccountFacade,
    private authService: AuthService,
    private titleService: Title,
    private featureService: FeatureManagementService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (
      this.appConfigService.tenantConfig &&
      this.moduleService.modules.length > 0 &&
      Object.entries(this.permissionService.rolePermissions).length > 0
    ) {
      return of(true);
    }

    return forkJoin({
      didLoadTenantConfig: this.appConfigService.setTenantConfig(),
      didLoadFeatureStore: this.featureService.setFeatures(),
      didLoadModules: this.moduleService.setModules(),
      didLoadModuleAppCategoryOverrides:
        this.moduleService.setModuleAppCategoryOverrides(),
      didLoadPermissions: this.permissionService.initRolePermissions(),
      didLoadRoles: this.roleService.initPublicRoles(),
    }).pipe(
      switchMap((results) => {
        const internalPortalName =
          this.appConfigService.tenantConfig?.internalPortalName;
        if (internalPortalName) {
          this.titleService.setTitle(internalPortalName);
        }
        this.analyticsService.init(this.accountFacade.currentUser$);
        this.moduleService.init(this.accountFacade.currentUser$);
        if (
          results.didLoadTenantConfig &&
          results.didLoadFeatureStore &&
          results.didLoadModules &&
          results.didLoadModuleAppCategoryOverrides &&
          results.didLoadPermissions &&
          results.didLoadRoles
        ) {
          return this.loginService.autoLogin().pipe(
            tap((result) => {
              if (result) {
                this.accountFacade.dispatchGetAccount();
              }
            }),
            switchMap((result) => {
              if (result) {
                return this.accountFacade.currentUser$.pipe(take(1));
              }
              return of(null);
            }),
            tap((result) => {
              if (result) {
                this.authService.redirectToReturnUrl(
                  route.queryParams['returnUrl'] || ''
                );
                if (!result.isExternal) {
                  this.roleService.initPrivateRoles();
                }
              }
            }),
            switchMap((result) => (result !== null ? of(true) : of(false)))
          );
        }
        return of(false);
      })
    );
  }
}
