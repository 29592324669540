import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getFormRoot } from './form-data.utility';
import { searchDown, lookup } from './form-hooks.utility';

export const copySectionValuesHook = (
  functionName,
  destroyed$: Observable<unknown>
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.form
      .get(field.key as string)
      .valueChanges.pipe(takeUntil(destroyed$))
      .subscribe((newVal) => {
        if (newVal) {
          const args = functionName.slice(1);
          const params = args[0];
          const splitParams = params
            .replace(/'/gi, '')
            .replace(/\s/g, '')
            .split(',');
          const from = splitParams[0].split('.');
          const rootelement = getFormRoot(field);
          const model = rootelement.model;
          if (
            splitParams[2] === null ||
            splitParams[2] === undefined ||
            eval(splitParams[2])
          ) {
            const sdlist: string[] = field['copiedFieldKeys'][0];
            const keylist = Object.keys(sdlist);
            const destList = Object.values(sdlist);
            let modelGet = model;
            for (const i in from) {
              modelGet = modelGet[from[i]];
            }
            let modelVals = {};
            if (Array.isArray(Object.values(modelGet))) {
              modelVals = modelGet;
            } else {
              modelVals = Object.values(modelGet);
            }

            const keymappedOutputs = [];

            // If only one parameter was passed in, use the parent of the current field to set the destination fields
            // Otherwise, use the second parameter as the location for setting the destination fields
            let p;
            if (splitParams[1]) {
              p = searchDown(rootelement, splitParams[1]);
            } else {
              p = field.parent;
            }

            for (const k in keylist) {
              const res = lookup(modelVals, keylist[k]);
              if (res != null) {
                keymappedOutputs[destList[k]] = res;
              } else {
                keymappedOutputs[destList[k]] = '';
              }
            }

            const destFieldList: FormlyFieldConfig[] = [];
            const foundList: FormlyFieldConfig[] = [];
            for (let i = 0; i < destList.length; i++) {
              foundList[i] = searchDown(p, destList[i]).parent;
              const res = foundList[i].fieldGroup.find(
                (e) => e.key === destList[i]
              );
              destFieldList.push(res);
            }

            for (let i = 0; i < destFieldList.length; i++) {
              const toField = destFieldList[i].form.get(destList[i]);
              toField.setValue(keymappedOutputs[destList[i]]);
            }
          }
        }
      });
  };
};
