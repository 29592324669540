export enum IconRegistry {
  AgriculturalResidues = 'AgriculturalResidues',
  AnaerobicDigestion = 'AnaerobicDigestion',
  Biofuel = 'Biofuel',
  Biogas = 'Biofuel',
  Biomass = 'Biomass',
  CHPCogeneration = 'CHPCogeneration',
  MicroCHP = 'CHPCogeneration',
  Coal = 'Coal',
  Crops = 'Crops',
  Diesel = 'Diesel',
  FuelCell = 'FuelCell',
  FuelCells = 'FuelCell',
  FuelCellsRenewableFuel = 'FuelCell',
  FuelCellsUsingNonRenewableFuel = 'FuelCell',
  FuelOil = 'FuelOil',
  FuelHeatingOil = 'FuelOil',
  Gasoline = 'FuelOil',
  Oil = 'FuelOil',
  GeothermalElectric = 'GeothermalElectric',
  GridIntegratedElectricVehicle = 'GridIntegratedElectricVehicle',
  GridIntegratedElectricalVehicle = 'GridIntegratedElectricVehicle',
  Hydro = 'Hydro',
  Hydroelectric = 'Hydro',
  SmallHydroelectric = 'Hydro',
  LandscapeTrimming = 'LandscapeTrimming',
  LivestockManure = 'LivestockManure',
  Methane = 'Methane',
  LandfillGas = 'Methane',
  MethaneFood = 'Methane',
  MethaneLivestock = 'Methane',
  NaturalGas = 'NaturalGas',
  Nuclear = 'Nuclear',
  Other = 'Other',
  ProcessByproduct = 'Other',
  Syngas = 'Other',
  Solar = 'Solar',
  SolarStorage = 'SolarStorage',
  SolarThermal = 'SolarThermal',
  SolarThermalElectric = 'SolarThermal',
  SolarWind = 'SolarWind',
  Storage = 'Storage',
  EnergyStorageDER = 'Storage',
  EnergyStorage = 'Storage',
  EnergyStorageAddon = 'Storage',
  WaveEnergy = 'WaveEnergy',
  OceanThermalElectric = 'WaveEnergy',
  TidalEnergy = 'WaveEnergy',
  Wind = 'Wind',
  MicroTurbine = 'Wind',
  WindStorage = 'WindStorage',
  WoodWaste = 'WoodWaste',
  Admin = 'admin',
  Application = 'application',
  Applications = 'applications',
  Asterisk = 'asterisk',
  AuditLog = 'auditLog',
  Bookmark = 'bookmark',
  Branding = 'branding',
  Calendar = 'calendar',
  CaretDown = 'caret-down',
  CaretUp = 'caret-up',
  Charts = 'charts',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  Circle = 'circle',
  CloudCheck = 'cloud-check',
  Collaborate = 'collaborate',
  Copy = 'copy',
  CreditCard = 'credit-card',
  Dashboard = 'dashboard',
  Download = 'download',
  EditSetting = 'edit-setting',
  EditSm = 'edit-sm',
  Edit = 'edit',
  Engineering = 'engineering',
  Error = 'error',
  Exit = 'exit2',
  Feeder = 'feeder',
  FileExcel = 'file-excel',
  FileImage = 'file-image',
  FileMusic = 'file-music',
  FilePDF = 'file-pdf',
  FilePowerPoint = 'file-powerpoint',
  FileText = 'file-text',
  FileVideo = 'file-video',
  FileWord = 'file-word',
  FileZip = 'file-zip',
  Files = 'files',
  Filter = 'filter',
  Hacker = 'hacker',
  HelpCircle = 'help-circle',
  InitialApplication = 'initial-application',
  Inverter = 'inverter',
  Key = 'key',
  Levels = 'levels',
  Location = 'location',
  Medal = 'medal',
  Menu = 'menu',
  Messages = 'messages',
  MobileShield = 'mobile-shield',
  Modules = 'modules',
  Notifications = 'notifications',
  OpenCircleThin = 'open-circle-thin',
  OpenCircle = 'open-circle',
  Operations = 'operations',
  Password = 'password',
  Payment = 'payment',
  Pending = 'pending',
  Preferences = 'preferences',
  Print = 'print',
  Progress = 'progress',
  Protect = 'protect',
  Refresh = 'refresh',
  Reports = 'reports',
  Review = 'review',
  Save = 'save',
  Search = 'search',
  SecurePhone = 'secure-phone',
  Security = 'security',
  Settings = 'settings',
  Success = 'success',
  SummaryUser = 'summary-user',
  Trash = 'trash',
  upload = 'upload',
  UserTie = 'user-tie',
  UserUtility = 'user-utility',
  ActionRequiredAlertIcon = 'ActionRequiredAlertIcon',
  User = 'user',
  Users = 'users',
  Utility = 'utility',
  Forms = 'forms',
  WarningCircle = 'warning-circle',
  Warning = 'warning',
  XCircle = 'x-circle',
  LowPriority = 'single-chevron-green',
  MediumPriority = 'double-chevron-yellow',
  HighPriority = 'triple-chevron-orange',
  CriticalPriority = 'diamond-warning-red',
  GenerateDocument = 'generate-document',
  Wrench = 'wrench',
  SawtoothWave = 'sawtooth-wave',
}
