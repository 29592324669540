import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ApplicationFormSchema,
  ApplicationViewHeader,
  ApplicationStatus,
  EditApplicationSync,
  ApplicationMilestoneDetails,
  ApplicationQueueHeader,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { AccountService } from './account.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private accountService: AccountService
  ) {
    super(client, configService, authService);
  }

  deleteApplication(appId: string) {
    const deleteApplicationUrl = `/api/applications/${appId}`;
    return this.delete(deleteApplicationUrl);
  }

  updateApplicationType(appId: string, typeId: string) {
    const updateApplicationTypeUrl =
      '/api/applications/' + appId + '/type/' + typeId;
    return this.put(updateApplicationTypeUrl, null);
  }

  updateAppInfoFromModel(appId: string, model: EditApplicationSync) {
    const updateApplication = `/api/applications/${appId}/project`;
    return this.put(updateApplication, model);
  }

  getApplicationFormSchema(typeId: string, version: number) {
    const options = {};
    const schemaUrl = `/api/schemas/${typeId}/${version}`;
    return this.get<ApplicationFormSchema>(schemaUrl, options);
  }

  getCollaborators(appId, userisInternal) {
    const getCollaboratorsUrl = `/api/applications/${appId}/collaborators?IncludeInternalCollaborators=${userisInternal}`;
    return this.get<any>(getCollaboratorsUrl);
  }

  deleteCollaborator(appId, userId) {
    const deleteCollaboratorUrl = `/api/applications/${appId}/collaborators/${userId}`;
    return this.delete(deleteCollaboratorUrl);
  }

  createCollaboratorAccount(data) {
    const createCollaboratorAccountUrl = '/api/account/collaborator';
    return this.post(createCollaboratorAccountUrl, data);
  }

  addApplicationCollaborator(appId, userId, addToAssociatedApplications) {
    const data = {};
    const addApplicationCollaboratorUrl = `/api/applications/${appId}/collaborators/${userId}?addToAssociatedApplications=${addToAssociatedApplications}`;
    return this.put(addApplicationCollaboratorUrl, data);
  }

  getOrganizations() {
    const url = '/api/organizations';
    return this.get<any>(url);
  }

  getViewHeaders(isExternal: boolean, appType?: string) {
    let url = `/api/tenant/viewHeaders?external=${isExternal}`;

    if (appType) {
      url = `${url}&appType=${appType}`;
    }

    return this.get<ApplicationViewHeader[]>(url).pipe(
      map((response) => response.data)
    );
  }

  getRelatedAppsHeaders(isExternal: boolean): Observable<IntellioHttpResponse<ApplicationQueueHeader[]>> {
    const url = `/api/tenant/relatedAppsHeaders?external=${isExternal}`;
    return this.get<ApplicationQueueHeader[]>(url);
  }

  getStatus(appId, status) {
    const url = `/api/applications/${appId}/statuses/${status}`;
    return this.get<ApplicationStatus>(url);
  }

  getApplicationMilestoneDetails(appId) {
    const url = `/api/applications/${appId}/milestones`;
    return this.get<ApplicationMilestoneDetails[]>(url);
  }
}
