import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import { AutomationLogs, Automations } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AutomationLogService extends BaseService {
  readonly PAGE_SIZE = 25;
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  readAutomation(id) {
    const getAutomationsUrl = `/api/automationlogs/comprehensive/${id}`;
    const data = {
      isRead: true,
    };
    return this.put(getAutomationsUrl, data);
  }
  getAutomations(skip: number, take: number) {
    const url = '/api/automationlogs/comprehensive';
    const automationParams = { skip, take };
    const config = { params: automationParams, ignoreLoadingBar: true };

    return this.get<Automations>(url, config);
  }

  getAutomationsEndpoint(params) {
    const url = '/api/automationlogs/comprehensive';
    const config = { params: params };

    return this.get<AutomationLogs>(url, config);
  }
  getAutomationTypes() {
    const url = '/api/automationlogs';
    return this.get<string[]>(url);
  }
  getApplicationTypes() {
    const url = '/api/applications/types';
    return this.get<string[]>(url);
  }
}
