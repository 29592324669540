import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  ApprovedFileExtension,
  Environment,
  ENVIRONMENT,
  IntellioHttpResponse,
  TenantConfig,
} from '@intellio/shared/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare const config: AppConfig;

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _tenantConfig: TenantConfig;

  // this will be set by the response that comes back from the config server
  private Url = '';
  private Tenant = '';
  private APIConfigUrl = '';

  private _configUrl = ``; //TODO: hard-code this to the config server

  constructor(
    private client: HttpClient,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  setTenantConfig(): Observable<boolean> {
    this.APIConfigUrl = `${this.Url}/api/tenant/configuration?X-Tenant=${this.Tenant}`;
    return this.client
      .get<IntellioHttpResponse<TenantConfig>>(this.APIConfigUrl, {
        headers: { 'X-Config': 'true' },
      })
      .pipe(
        map((response) => {
          this._tenantConfig = response.data;
          this.seedMissingDefaultValues();
          return true;
        })
      );
  }

  loadConfigFile(): void {
    // Only load config values from file if in a deployed environment
    if (this.environment.production) {
      this.environment = { ...this.environment, config };
    }

    this.Url = this.config.url;
    this.Tenant = this.config.tenant;
  }

  seedMissingDefaultValues(): void {
    if (!this._tenantConfig.internalPortalName) {
      this._tenantConfig.internalPortalName = 'ConnectTheGrid'; // TODO we should default to Intellio Connect
    }
  }

  getApprovedFileExtensions() {
    var url = `${this.Url}/api/tenant/approvedFileExtension`;
    return this.client.get<IntellioHttpResponse<ApprovedFileExtension[]>>(url);
  }

  get config() {
    return this.environment.config;
  }

  get tenantConfig() {
    return this._tenantConfig;
  }

  get url() {
    return this.Url;
  }

  get tenant() {
    return this.Tenant;
  }

  get configUrl() {
    return this._configUrl;
  }

  get apiConfigUrl() {
    return this.APIConfigUrl;
  }
}
