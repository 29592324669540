import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { IntellioHttpResponse, TaskSavedFilter } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class TasksService extends BaseService {
  constructor(
    client: HttpClient,
    appConfigService: AppConfigService,
    authService: AuthService
  ) {
    super(client, appConfigService, authService);
  }

  getTaskFilters() {
    return this.get<TaskSavedFilter[]>('/api/taskfilters').pipe(
      map((response) => response.data)
    );
  }

  createTaskFilter(data: TaskSavedFilter) {
    return this.post<number>('/api/taskfilters', data).pipe(
      map((response) => response.data)
    );
  }

  updateTaskFilter(data: TaskSavedFilter) {
    return this.put<TaskSavedFilter>(`/api/taskfilters/${data.id}`, data).pipe(
      map((response) => response.data)
    );
  }

  deleteTaskFilter(filterId: number) {
    return this.delete<void>(`/api/taskfilters/${filterId}`).pipe();
  }
}
