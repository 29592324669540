import { Application } from '@intellio/shared/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { setupInterpolation } from './form-hooks.utility';

export const interpolateStringHook = (
  functionName,
  application: Application
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    setupInterpolation(functionName, application, field, false);
  };
};
