import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IntellioHttpResponse,
  Module,
  ModuleConfig,
  ModuleId,
  User,
  ModuleApplicationCategory,
} from '@intellio/shared/models';
import { config, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private _modules: Module[] = [];
  private _moduleAppCategoryOverrides: ModuleApplicationCategory[] = [];
  private _currentUser: User;

  constructor(
    private client: HttpClient,
    private configService: AppConfigService
  ) {}

  get modules() {
    return this._modules;
  }

  get currentUser() {
    return this._currentUser;
  }

  get moduleAppCategoryOverrides() {
    return this._moduleAppCategoryOverrides;
  }

  init(currentUser$: Observable<User>) {
    currentUser$
      .pipe(
        tap((user) => {
          if (user) {
            this._currentUser = user;
          } else {
            this._currentUser = undefined;
          }
        })
      )
      .subscribe();
  }

  getModuleConfigs(moduleId: ModuleId) {
    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };
    const url = `${this.configService.url}/api/tenant/modules/${moduleId}/configs`;
    return this.client.get<IntellioHttpResponse<ModuleConfig[]>>(url, options);
  }

  getInterpolatedModuleConfigs(
    moduleId: ModuleId,
    applicationId: string,
    defaultInterpolationValue: string
  ) {
    const url = `${this.configService.url}/api/tenant/modules/${moduleId}/configs/interpolate/${applicationId}?defaultInterpolationValue=${defaultInterpolationValue}`;
    return this.client.get<IntellioHttpResponse<ModuleConfig[]>>(url);
  }

  mergeOverrides(modules, overrides, categoryId) {
    let ret = [];
    // deep clone modules (no references to modules in this.modules)
    let mods = JSON.parse(JSON.stringify(modules));
    mods.forEach((module) => {
      const override = overrides.filter(
        (overrideObj) =>
          overrideObj.tenantId == module.tenantId &&
          overrideObj.module == module.module &&
          overrideObj.categoryId == categoryId
      )[0];
      if (override != undefined) {
        module.isActive = override.isActive;
        module.categoryId = override.categoryId;
      }
      ret.push(module);
    });
    return ret;
  }

  isModuleEnabled(
    moduleId: ModuleId,
    categoryId: string = this.currentUser?.activeCategoryId
  ): boolean {
    let modulesWithOverrides;
    if (categoryId == null) {
      categoryId = this.currentUser?.activeCategoryId;
    }
    if (this.currentUser != null) {
      modulesWithOverrides = this.mergeOverrides(
        this.modules,
        this.moduleAppCategoryOverrides,
        categoryId
      );
    }
    if (modulesWithOverrides != null) {
      return modulesWithOverrides?.some(
        (module) =>
          module.moduleId.toLowerCase() === moduleId.toLowerCase() &&
          module.isActive
      );
    } else {
      return this.modules.some(
        (module) =>
          module.moduleId.toLowerCase() === moduleId.toLowerCase() &&
          module.isActive
      );
    }
  }

  setModules(): Observable<boolean> {
    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.client
      .get<IntellioHttpResponse<Module[]>>(
        `${this.configService.url}/api/tenant/modules`,
        options
      )
      .pipe(
        map((response) => {
          this._modules = response.data || [];
          return true;
        })
      );
  }

  setModuleAppCategoryOverrides(): Observable<boolean> {
    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.client
      .get<IntellioHttpResponse<ModuleApplicationCategory[]>>(
        `${this.configService.url}/api/tenant/appcategorymodules`,
        options
      )
      .pipe(
        map((response) => {
          this._moduleAppCategoryOverrides = response.data || [];
          return true;
        })
      );
  }
}
