import { ModuleId, Permission } from './shared-models';

export class TitleDropdownNavItem {
  route: string;
  title: string;
  icon?: string;
  permissions?: Permission[];
  module?: ModuleId;
  customEnable?: boolean;
  iconClass?: string;
  onClick?: any;
  displayDropdownOnly?: boolean;
}
