<div class="modal">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Select a Template
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <div class="in-place-spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
      <form class="main-form">
        <div>
          <div>
            <mat-label i18n> <strong>Templates</strong></mat-label>
          </div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-select
              placeholder="Choose Template"
              [formControl]="templateControl"
            >
              <mat-option
                *ngFor="let template of templates"
                [value]="template.id"
              >
                {{ template.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button i18n class="secondary-button" (click)="closeDialog()">
        Cancel
      </button>
      <button mat-button i18n class="primary-button" (click)="applyTemplate()">
        Apply
      </button>
    </mat-dialog-actions>
  </div>
</div>
