import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { PresetGroupDetails, PresetsGroup } from '@intellio/shared/models';
import { PresetField } from '@intellio/shared/models';
import { PresetGroupDetailsField } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class PresetsService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getPresetsGroups() {
    const url = '/api/presets/groups';
    return this.get<PresetsGroup[]>(url, {});
  }

  getPresetGroupDetails(userId: string) {
    const url = `/api/presets/${userId}/groups`;
    return this.get<PresetGroupDetails[]>(url, {});
  }

  createGroupDetail(data: any, userId: string) {
    const url = `/api/presets/${userId}/groups`;
    return this.post(url, data);
  }

  updatePresetGroupDetail(data: any, userId: string, groupDetailId: any) {
    const url = `/api/presets/${userId}/groups/${groupDetailId}`;
    return this.put(url, data);
  }
}
