import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocumentTemplate,
  DocumentTemplateVersion,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({ providedIn: 'root' })
export class TemplatesService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getDocumentTemplates(): Observable<IntellioHttpResponse<DocumentTemplate[]>> {
    const url = '/api/documenttemplate';
    return this.get<DocumentTemplate[]>(url);
  }

  createDocumentTemplate(
    displayName: string,
    fileTypeId: string,
    partial: boolean
  ): Observable<IntellioHttpResponse<DocumentTemplate>> {
    const url = '/api/documenttemplate';
    return this.post<DocumentTemplate>(url, {
      displayName,
      fileTypeId,
      partial,
    });
  }

  createDocumentTemplateVersion(
    templateId: string,
    fileName: string,
    file
  ): Observable<IntellioHttpResponse<DocumentTemplateVersion>> {
    const url =
      '/api/documenttemplate/' + templateId + '/versions?fileName=' + fileName;

    return this.uploadFiles(url, [file]);
  }

  getDocumentTemplateVersions(
    docTemplateId: string
  ): Observable<IntellioHttpResponse<DocumentTemplateVersion[]>> {
    const url = '/api/documenttemplate/' + docTemplateId + '/versions';
    return this.get<DocumentTemplateVersion[]>(url);
  }

  downloadFile(documentTemplateId: string, versionNumber: number) {
    const url =
      '/api/documenttemplate/' +
      documentTemplateId +
      '/versions/' +
      versionNumber +
      '/fileContent';

    this.getFile(url).pipe(take(1)).subscribe();
  }

  restoreVersion(documentTemplateId: string, versionNumber: number) {
    const url = '/api/documenttemplate/' + documentTemplateId + '/restore';

    return this.put(url, { versionNumber });
  }

  deleteTemplate(documentTemplateId: string) {
    const url = '/api/documenttemplate/' + documentTemplateId;

    return this.delete(url);
  }
}
