import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({ providedIn: 'root' })
export class ConfirmEmailService extends BaseService {
  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  confirmEmail(userId: string, code: string) {
    const data = {
      userId: userId,
      code: code,
    };
    const forgotPasswordUrl = '/api/account/confirmemail/noredirect';
    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };
    return this.put<string>(forgotPasswordUrl, data, options);
  }
}
