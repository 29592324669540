export class Message {
  body: string;
  fileNames: string[];
  id: number;
  isExternal: boolean;
  senderIsAssignedSpoc: boolean;
  senderIsCurrentUser: boolean;
  senderIsCustomerOrContractor: boolean;
  senderName: string;
  senderTitle: string;
  senderUserName: string;
  statusChangeId: string;
  statusChangeToId: string;
  statusChangeToName: string;
  timeStamp: string;
  userId?: string;
  statusChangePostTemplate: string;
  fileTypes: string[];
  needsResponse: boolean;
  applicationId: string;
  projectId: string;
  appAddress: string;
}
