import { TaskAssignment } from './app-task-assignment.model';
import { User } from './user.model';

export class FilterTasks {
  userId: string;
  all?: boolean;
  status?: string[];
  priority?: string[];
  assignees?: string[] | string;
  dueBefore?: string;
  dueAfter?: string;
  applicationStatus?: string;
  includeGroupTasks?: boolean;
  showCompleted?: boolean;
  sort?: string;
  asc: boolean;
  skip: number;
  take: number;
  search?: string;
  exactMatch: boolean;
}
