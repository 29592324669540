export class PresetField {
  id: number;
  groupId: string;
  fieldKey: string;
  title: string;
  pattern: string;
  optionValues: unknown[];
  optionLabels: string[];
  displayOrder: number;
  value: any;
  format: string;
}
