import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getChildObject } from './form-data.utility';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export const filterPVModuleModelsHook = (): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    const mfgObj = getChildObject(p, 'pvmoduleManufacturer');
    const mfgFormField = field.form.get(mfgObj.key);

    const modelObj = getChildObject(p, 'pvmoduleModel');

    field.props.options = mfgFormField.valueChanges.pipe(
      startWith(`${mfgFormField.value}:initial`),
      tap((value) => {
        if (field.form.status === 'DISABLED') {
          field.form.get(modelObj.key).disable();
        }
        if (field.form.status !== 'DISABLED') {
          const model = field.form.get(modelObj.key);
          const isStartValue =
            value === null ? false : value?.toString().split(':').length > 1;
          if (!isStartValue) {
            model.setValue('');
          }
        }
      }),
      switchMap((mfg) => {
        if (mfg?.toString().includes(':initial')) {
          mfg = mfg.replace(':initial', '');
        }
        const filteredPVModuleModels = field.options.formState.pvModules.filter(
          (pvmm) => pvmm.manufacturer === mfg
        );
        if (filteredPVModuleModels.length > 0) {
          const options = filteredPVModuleModels.map((pvmm) => {
            return {
              value: pvmm.modelNumber,
              label: pvmm.modelNumber,
            };
          });
          return of(options);
        } else {
          return of([]);
        }
      })
    );
  };
};
