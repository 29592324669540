import { Injectable } from '@angular/core';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { IntellioHttpResponse } from '@intellio/shared/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureManagementService extends BaseService {

  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService
  ) {
    super(client, configService, authService);
  }

  featureStore = {};

  setFeatures(): Observable<boolean> {
    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.client.get<IntellioHttpResponse<any>>(`${this.configService.url}/api/features`, options).pipe(
      map((response) => {
        this.featureStore = response.data;
        return true;
      })
    );
  }

  isFeatureActivated(featureName: string): boolean {
    var interpolatedFeature = `${this.configService.tenant}-${featureName}`;
    return this.featureStore[interpolatedFeature] || false;
  }
}
