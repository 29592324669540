import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionRequiredAlertRule } from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ActionRequiredAlertRuleService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getActionRequiredAlertRules(isExternal: boolean) {
    const url = `/api/actionrequired/rules?isExternal=${isExternal}`;
    return this.get<ActionRequiredAlertRule[]>(url);
  }
}
