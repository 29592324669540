import { UntypedFormArray, ValidationErrors } from '@angular/forms';

export function repeatValidator(
  control: UntypedFormArray
): ValidationErrors | null {
  return control.value.length < 1
    ? {
        invalidRepeat: true,
      }
    : null;
}
