export class RuleAlerts {
  applicationUDFIdOne: string;
  applicationUDFIdTwo: string;
  applicationUDFValueOne: string;
  applicationUDFValueTwo: string;
  approvedReview: boolean;
  displayName: string;
  fileTypeIds: string[];
  id: number;
  nullOrEmptyApplicationUDFId: string;
  ruleIsExternal: boolean;
  statusId: string;
  submittedReview: boolean;
  supplementalFormStatusId: string;
  supplementalFormTypeId: string;
  typeId: string;
  unsubmittedReview: boolean;
}
