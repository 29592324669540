import { Pipe, PipeTransform } from '@angular/core';
import { Message, User, SimpleUser } from '@intellio/shared/models';

@Pipe({
  name: 'isMessageCurrentTeam',
})
export class IsMessageCurrentTeamPipe implements PipeTransform {
  transform(
    message: Message,
    isCurrentUserInternal: boolean,
    currentUserEmail: string,
    internalUsers: User[] | SimpleUser[]
  ): boolean {
    if (isCurrentUserInternal) {
      return (
        internalUsers.find((u) => u.email === message.senderUserName) !==
        undefined
      );
    } else {
      return currentUserEmail === message.senderUserName;
    }
  }
}
