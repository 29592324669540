import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { getFormRoot } from './form-data.utility';
import { takeUntil, startWith } from 'rxjs/operators';

export const copyFromHook = (functionName, destroyed$): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const args = functionName.slice(1);
    const parameters = args[0].split(',');
    const propertyName = parameters[0];
    const root = getFormRoot(field);
    const formControl = root.formControl.get(propertyName);
    formControl.valueChanges
      .pipe(startWith(formControl.value), takeUntil(destroyed$))
      .subscribe((val) => {
        if (!field.props.disabled) {
          field.formControl.setValue(val);
        }
      });
  };
};
