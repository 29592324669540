import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterField: string, filterValue): any[] {
    if (!items) {
      return [];
    }
    return items.filter((val) => val[filterField] === filterValue);
  }
}
