import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import {
  getFormRoot,
  getValueOnObjectFromStringProperty,
} from './form-data.utility';
import { takeUntil, startWith } from 'rxjs/operators';

export const filterEnergyTypesHook = (functionName): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const rootElement = getFormRoot(field);

    rootElement.formControl
      .get(functionName[1])
      ?.valueChanges.pipe(
        startWith(
          getValueOnObjectFromStringProperty(functionName[1], rootElement.model)
        )
      )
      .subscribe((value) => {
        if (value === 'Inverter') {
          field.props.options = [
            {
              value: 'FuelCell',
              label: 'Fuel Cell',
            },
            {
              value: 'Other',
              label: 'Other',
            },
            {
              value: 'Photovoltaic',
              label: 'Photovoltaic',
            },
            {
              value: 'ReciprocatingEngine',
              label: 'Reciprocating Engine',
            },
            {
              value: 'Turbine',
              label: 'Turbine',
            },
          ];
        } else {
          field.props.options = [
            {
              value: 'FuelCell',
              label: 'Fuel Cell',
            },
            {
              value: 'Other',
              label: 'Other',
            },
            {
              value: 'ReciprocatingEngine',
              label: 'Reciprocating Engine',
            },
            {
              value: 'Turbine',
              label: 'Turbine',
            },
          ];
        }
      });
  };
};
