import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UDF } from '@intellio/shared/models';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'itc-app-card-list',
  styleUrls: ['./app-card-list.component.scss'],
  template: `
    <div *ngIf="cards$ | async as cards; else loadingOrError">
      <div *ngIf="cards.length > 0; else noResults">
        <div>
          {{ cards.length }} results found. Please select from the list below
        </div>
        <div
          *ngFor="let card of cards; let i = index"
          (click)="props.onClick(card); setFormValue(card, i)"
        >
          <mat-card [ngClass]="activeState[i] ? 'active-card' : ''">
            <mat-card-content>
              <div class="card-list__application-info">
                <div
                  class="inner-card-list"
                  [ngClass]="activeState[i] ? 'selected-card' : ''"
                >
                  <div class="summary">
                    <mat-icon
                      class="card-list__icon"
                      svgIcon="{{ card.system.energySourceId }}"
                    ></mat-icon>
                    <div>
                      <div class="card-list__level">
                        {{ card.typeName }}
                        <span class="status">{{ card.statusName }}</span>
                      </div>
                      <div class="card-list__name">
                        {{ card.customerFullName ?? card.projectName }}
                        <div class="card-list__address">
                          {{ card.system.fullAddress }}
                        </div>
                      </div>
                      <div class="card-list__id">
                        {{ card.applicationCapacity }} kW
                      </div>
                      <div
                        class="card-list__id"
                        *ngIf="
                          card.applicationUDFDetails &&
                          card.applicationUDFDetails.length > 0
                        "
                      >
                        <span
                          *ngFor="
                            let udf of card.applicationUDFDetails
                              | filter: 'id':'QueueNumber';
                            index as indx
                          "
                        >
                          <ng-container [ngSwitch]="udf.type">
                            <span>{{ udf.name | uppercase }}: </span>
                            <span *ngSwitchCase="'Date'">{{
                              udf.value | date: 'MM/dd/yyyy'
                            }}</span>
                            <span *ngSwitchDefault>{{ udf.value }} </span>
                          </ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <br />
        </div>
      </div>
    </div>
    <ng-template #loadingOrError>
      <ng-container *ngIf="errorObject; else loading">
        <div class="col-12 text-center">
          <div class="alert alert-danger">
            {{ errorObject.message }}
          </div>
        </div>
      </ng-container>

      <ng-template #loading>
        <div class="in-place-spinner-wrapper" *ngIf="props.isLoading">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #noResults>
      <div>
        No Results were found. You may continue without associating an existing
        Interconnection request. If you know you have an Interconnection request
        for your facility, you may need to submit an ownership change form. At a
        later time you can link your new request to an existing Interconnection
        request.
      </div>
    </ng-template>
  `,
})
export class AppCardListComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  constructor() {
    super();
  }

  errorObject = null;
  control: UntypedFormControl;
  cards$: Observable<any[]>;

  activeState: boolean[] = [];

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
    this.cards$ = this.props.cards$;
    this.cards$ = this.cards$.pipe(
      tap((data) => {
        this.props.isLoading = false;
        if (data?.length === 0) {
          this.control.setValue('N/A');
        }
      }),
      catchError((err) => {
        this.props.isLoading = false;
        this.errorObject = err;
        return throwError(err);
      })
    );
  }

  setFormValue(app, index) {
    this.control.setValue(app.id);
    this.activeState.fill(false);
    this.activeState[index] = true;
  }

  //   onClick($event) {
  //     if (this.props.onClick) {
  //       this.props.onClick($event);
  //     }
  //   }
}
