import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { HttpClient } from '@angular/common/http';
import {
  GeocodeAddressModel,
  GeocodeResultModel,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { Observable, concatMap, forkJoin, map } from 'rxjs';
import { ModuleService } from './module.service';

@Injectable({
  providedIn: 'root',
})
export class GeocodeService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private moduleService: ModuleService
  ) {
    super(client, configService, authService);
  }

  validateAddress(
    addressData: GeocodeAddressModel
  ): Observable<IntellioHttpResponse<GeocodeResultModel>> {
    return this.moduleService.getModuleConfigs('Geocode').pipe(
      concatMap((configs) => {
        let uspsConfig = configs?.data?.find(
          (v) => v.configId === 'UspsAddressVerficationEnabled'
        );
        if (uspsConfig && uspsConfig.value === '1') {
          return forkJoin([
            this.postAddressToVerify(addressData),
            this.postAddressToGeocode(addressData),
          ]).pipe(
            map(([verifiedAddresses, geocodedAddresses]) => {
              // First try to return the USPS-verified address results
              if (verifiedAddresses.data.addresses.length > 0) {
                return verifiedAddresses;
              }
              // if we get no hits with USPS,
              // then return the geocoded results
              else {
                return geocodedAddresses;
              }
            })
          );
        } else {
          return this.postAddressToGeocodeLegacy(addressData);
        }
      })
    );
  }

  // NOTE: THis hits the Google Maps API
  // Replace this with the call to Azure Maps
  // in the future
  private postAddressToGeocodeLegacy(
    addressData: GeocodeAddressModel
  ): Observable<IntellioHttpResponse<GeocodeResultModel>> {
    const url = '/api/geocode';
    return this.post<GeocodeResultModel>(url, addressData);
  }

  // NOTE: This now hits Azure Maps
  // Make this the default once
  // we get off Google Maps
  private postAddressToGeocode(
    addressData: GeocodeAddressModel
  ): Observable<IntellioHttpResponse<GeocodeResultModel>> {
    const url = '/api/addresses/geocode';
    return this.post<GeocodeResultModel>(url, addressData);
  }

  private postAddressToVerify(
    addressData: GeocodeAddressModel
  ): Observable<IntellioHttpResponse<GeocodeResultModel>> {
    const url = '/api/addresses/verify';
    return this.post<GeocodeResultModel>(url, addressData);
  }
}
