import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  QUEUE_FILTER_FEATURE_KEY,
  QueueFilterState,
  QueueFilterPartialState,
} from './queue-filter.reducer';

export const selectQueueFilterState = createFeatureSelector<QueueFilterState>(
  QUEUE_FILTER_FEATURE_KEY
);

// Lookup the 'QueueFilter' feature state managed by NgRx
export const getQueueFilterState = createFeatureSelector<QueueFilterState>(
  QUEUE_FILTER_FEATURE_KEY
);

export const getQueueFilter = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.currentQueueFilter
);

export const getJurisdictions = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.jurisdictions
);

export const getJurisdictionsLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.jurisdictionsLoaded
);

export const getIntentsofGeneration = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.intentsofGeneration
);

export const getIntentsofGenerationLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.intentsofGenerationLoaded
);

export const getEnergySources = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.energySources
);

export const getEnergySourcesLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.energySourcesLoaded
);

export const getBusinessRoles = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.businessRoles
);

export const getBusinessRolesLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.businessRolesLoaded
);

export const getUDFs = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.udfs
);

export const getUDFsLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.udfsLoaded
);

export const getUDFOptions = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.udfOptions
);

export const getUDFOptionsLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.udfOptionsLoaded
);

export const getTenantFilterConfig = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.tenantFilterConfig
);

export const getTenantFilterConfigLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.tenantFilterConfigLoaded
);

export const getActionRequiredAlertRules = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.actionRequiredAlertRules
);

export const getActionRequiredAlertRulesLoaded = createSelector(
  getQueueFilterState,
  (state: QueueFilterState) => state.actionRequiredAlertRulesLoaded
);
