import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
})
export class SharedResolversModule {}

export { ApplicationResolver } from './application.resolver';
export { AppBootstrapResolver } from './app-bootstrap.resolver';
