<div class="modal">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Next Steps: Corrected Level
      <button mat-icon-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <div class="modal-body">
      Your current application's capacity is outside the thresholds for your
      current level and as a result you are unable to submit this application at
      its current level. Please create a new application of type
      <strong>{{ correctType }}</strong>
      and input
      <strong>{{ capacity }}</strong>
      as the application's nameplate capacity.
    </div>
    <mat-dialog-actions>
      <button mat-button i18n class="secondary-button" (click)="closeModal()">
        Cancel
      </button>
      <button
        mat-button
        i18n
        class="primary-button"
        (click)="navigateToQueue()"
      >
        View My Applications
      </button>
    </mat-dialog-actions>
  </div>
</div>
