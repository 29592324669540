import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ChangePrimaryUsersService extends BaseService {
  customerId: string;
  contractorId: string;
  appUrl: string;
  constructor(
    private httpClient: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(httpClient, configService, authService);
  }
  updatePrimaryUsersService(customerId, contractorId, appId) {
    const data = {
      NewCustomerId: customerId,
      NewContractorId: contractorId,
    };
    const url = `/api/applications/${appId}/externalusers`;
    return this.put(url, data);
  }
  getPrimaryUsers(appId) {
    const url = `/api/applications/${appId}/externalusers`;
    return this.get(url);
  }
}
