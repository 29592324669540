import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { initApp } from './init-app';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {
  AppConfigService,
  SharedServicesModule,
  UrlRewriteGuard,
} from '@intellio/shared/services';
import { ThemeService } from '@intellio/shared/services';
import { environment } from '../environments/environment';
import formPartials from '../assets/configs/form-partials.json';
import mockedSpplementalForms from '../assets/configs/mocked-supplemental-forms.json';
import tenantFormPartials from '../assets/configs/tenant-form-partials.json';
import {
  FORM_PARTIAL,
  TENANT_FORM_PARTIAL,
  MOCKED_SUPPLEMENTAL_FORMS,
} from '@intellio/shared/models';
import { ENVIRONMENT } from '@intellio/shared/models';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedComponentsModule } from '@intellio/shared/components';
import { createTranslateLoader } from '@intellio/shared/translate';
import { SharedContainerComponentsModule } from '@intellio/shared/container-components';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  FullRouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import * as fromRoot from './+state/root.reducer';
import { RootEffects } from './+state/root.effects';
import { RootFacade } from './+state/root.facade';
import { SharedStateMgmtModule } from '@intellio/shared/state-mgmt';
import { SharedResolversModule } from '@intellio/shared/resolvers';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EditorModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          canActivate: [UrlRewriteGuard],
          loadChildren: () =>
            import('@intellio/connect/root').then((m) => m.ConnectRootModule),
        },
      ],
      {
        initialNavigation: 'enabledBlocking',
        paramsInheritanceStrategy: 'always',
        scrollPositionRestoration: 'enabled', // or 'top'
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64], // [x, y] - adjust scroll offset
      }
    ),
    SharedServicesModule,
    SharedComponentsModule,
    SharedContainerComponentsModule,
    SharedStateMgmtModule,
    SharedResolversModule,
    NgxMatTimepickerModule,
    MatTreeModule,
    NgxMatDatetimePickerModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      validationMessages: [
        { name: 'phone', message: 'Phone number is invalid' },
      ],
    }),
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    TranslateModule.forRoot({
      // loader here for initial translations. SharedTranslationModule handles the lazy-loaded module translations
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([RootEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([RootEffects]),
    StoreModule.forFeature(fromRoot.ROOT_FEATURE_KEY, fromRoot.reducer),
    //NOTE: instrumentation needs to occur after
    // all configuration of the store and effects modules
    // of NgRx
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: !environment.production
    ? [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: FORM_PARTIAL, useValue: formPartials },
        {
          provide: MOCKED_SUPPLEMENTAL_FORMS,
          useValue: mockedSpplementalForms,
        },
        { provide: TENANT_FORM_PARTIAL, useValue: tenantFormPartials },
        {
          provide: APP_INITIALIZER,
          useFactory: initApp,
          multi: true,
          deps: [ThemeService, AppConfigService],
        },
        CurrencyPipe,
      ]
    : [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: FORM_PARTIAL, useValue: formPartials },
        {
          provide: MOCKED_SUPPLEMENTAL_FORMS,
          useValue: mockedSpplementalForms,
        },
        { provide: TENANT_FORM_PARTIAL, useValue: tenantFormPartials },
        {
          provide: APP_INITIALIZER,
          useFactory: initApp,
          multi: true,
          deps: [ThemeService, AppConfigService],
        },
        CurrencyPipe,
        {
          provide: ErrorHandler,
          useClass: ApplicationinsightsAngularpluginErrorService,
        },
      ],
  bootstrap: [AppComponent],
})
export class AppModule {}
