<div *ngIf="erroredFields.length > 0">
  <div style="font-weight: bold; padding: 10px">
    The following fields have errors
  </div>
  <ul class="form-errors">
    <li *ngFor="let field of erroredFields">
      <a (click)="focusElementClicked.emit(field)">
        {{
          field._fields[0].templateOptions.label !== '' &&
          field._fields[0].templateOptions.label
            ? field._fields[0].templateOptions.label
            : field._fields[0].templateOptions.title
        }}
      </a>
      <span *ngFor="let item of field.errors | keyvalue">
        {{ '-' + item.key }}
      </span>
    </li>
  </ul>
</div>
