import { createAction, props } from '@ngrx/store';
import {
  ApplicationCategory,
  StatusChangeSchema,
  User,
  SimpleUser,
} from '@intellio/shared/models';

export enum AccountActionTypes {
  GetAccount = '[Account] Get',
  GetAccountSucceeded = '[Account] Get Succeeded',
  GetAccountFailure = '[Account] Get Failure',
  GetAvailableCategoriesForUserSuccess = '[Account] Get Available Categories For User Success',
  GetAvailableCategoriesForUserFailure = '[Account] Get Available Categories For User Failure',
  GetExternalUsers = '[Account - External Users] Get',
  GetExternalUsersSucceeded = '[Account - External Users] Get Succeeded',
  GetExternalUsersFailure = '[Account - External Users] Get Failure',
  GetSimpleExternalUsers = '[Account - Simple External Users] Get',
  GetSimpleExternalUsersSucceeded = '[Account - Simple External Users] Get Succeeded',
  GetSimpleExternalUsersFailure = '[Account - Simple External Users] Get Failure',
  UpdateAccountCurrentAppCategory = '[Account - App Category] Update Account Current App Category',
  UpdateAccountCurrentAppCategorySucceeded = '[Account - App Category] Update Account Current App Category Succeeded',
  UpdateAccountCurrentAppCategoryFailed = '[Account - App Category] Update Account Current App Category Failed',
  LogoutAccount = '[Account] Logout',
  GetInternalUsers = '[InternalUsers] Get',
  GetInternalUsersSucceeded = '[InternalUsers] Get Succeeded',
  GetInternalUsersFailure = '[InternalUsers] Get Failure',
  GetStatusChangeSchemas = '[Account - Status Change Schemas] Get',
}

export const getAccount = createAction(AccountActionTypes.GetAccount);

export const getAccountSuccess = createAction(
  AccountActionTypes.GetAccountSucceeded,
  props<{ currentUser: User }>()
);

export const getAccountFailure = createAction(
  AccountActionTypes.GetAccountFailure,
  props<{ error: any }>()
);

export const getAvailableCategoriesForUserSuccess = createAction(
  AccountActionTypes.GetAvailableCategoriesForUserSuccess,
  props<{ availableCategories: ApplicationCategory[] }>()
);

export const getAvailableCategoriesForUserFailure = createAction(
  AccountActionTypes.GetAvailableCategoriesForUserFailure,
  props<{ error: any }>()
);

export const getExternalUsers = createAction(
  AccountActionTypes.GetExternalUsers
);

export const getExternalUsersSuccess = createAction(
  AccountActionTypes.GetExternalUsersSucceeded,
  props<{ users: User[] }>()
);

export const getExternalUsersFailure = createAction(
  AccountActionTypes.GetExternalUsersFailure,
  props<{ error: any }>()
);

export const getSimpleExternalUsers = createAction(
  AccountActionTypes.GetSimpleExternalUsers
);

export const getSimpleExternalUsersSuccess = createAction(
  AccountActionTypes.GetSimpleExternalUsersSucceeded,
  props<{ users: SimpleUser[] }>()
);

export const getSimpleExternalUsersFailure = createAction(
  AccountActionTypes.GetSimpleExternalUsersFailure,
  props<{ error: any }>()
);

export const getInternalUsers = createAction(
  AccountActionTypes.GetInternalUsers
);

export const getInternalUsersSucceeded = createAction(
  AccountActionTypes.GetInternalUsersSucceeded,
  props<{ internalUsers: User[] }>()
);

export const getInternalUsersFailure = createAction(
  AccountActionTypes.GetInternalUsersFailure,
  props<{ error: any }>()
);

export const updateAccountCurrentAppCategory = createAction(
  AccountActionTypes.UpdateAccountCurrentAppCategory,
  props<{ appCategory: ApplicationCategory }>()
);

export const updateAccountCurrentAppCategorySucceeded = createAction(
  AccountActionTypes.UpdateAccountCurrentAppCategorySucceeded,
  props<{ appCategory: ApplicationCategory }>()
);

export const updateAccountCurrentAppCategoryFailed = createAction(
  AccountActionTypes.UpdateAccountCurrentAppCategoryFailed,
  props<{ error: any }>()
);

export const getStatusChangeSchemas = createAction(
  AccountActionTypes.GetStatusChangeSchemas,
  props<{ appCategory: ApplicationCategory }>()
);

export const getStatusChangeSchemasSuccess = createAction(
  AccountActionTypes.GetStatusChangeSchemas,
  props<{ schemas: StatusChangeSchema[] }>()
);

export const getStatusChangeSchemasFailure = createAction(
  AccountActionTypes.GetStatusChangeSchemas,
  props<{ error: any }>()
);

export const logoutAccount = createAction(AccountActionTypes.LogoutAccount);
