import { HttpClient } from '@angular/common/http';
import { Application } from '@intellio/shared/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import * as _ from 'lodash';
import { TemplateRef } from '@angular/core';

export class FormlyService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  // Override this in an extended class
  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected mapHookFunctions(
    _hookFunctionName: string,
    _destroyed$: Observable<unknown>,
    formIsComplete?: boolean
  ): FormlyHookFn {
    return undefined;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // Override this in an extended class
  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected mapDynamicOptions(_titleName: string): Observable<unknown> {
    return EMPTY;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // Override this in an extended class
  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected mapExpressionPropertyFunctions(
    _name: string,
    _expression: string
  ): (field: FormlyFieldConfig) => any {
    return () => {}; // eslint-disable-line
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  mergePartialOverride(p: unknown, overrideObj: unknown): unknown {
    let overrideKey = '';
    const recurse = (partial, keyArray: string[]) => {
      const keys = Object.keys(partial);
      for (let i = 0; i < keys.length; i++) {
        const value = partial[keys[i]];
        if (keys[i] === 'condition') {
          continue;
        }
        if (
          (typeof value === 'object' && value !== null) ||
          Array.isArray(value)
        ) {
          if (value.key && value.key == keyArray[0] && keyArray.length > 1) {
            recurse(value, keyArray.splice(1, keyArray.length - 1));
          } else if (
            value.key &&
            value.key == keyArray[0] &&
            keyArray.length === 1
          ) {
            // we have found the key to replace
            partial[keys[i]] = _.cloneDeep(overrideObj[overrideKey]);
          } else {
            recurse(value, keyArray);
          }
        }
      }
    };
    const keyNames = Object.keys(overrideObj);
    for (let i = 0; i < Object.keys(overrideObj).length; i++) {
      overrideKey = keyNames[i];
      recurse(p, overrideKey.split('.'));
    }
    return p;
  }

  stitchPartialOverrides(
    tenant: string,
    formPartials: string[],
    tenantFormPartials: Record<string, string[]>
  ) {
    const requests = formPartials.map((partial) => {
      return forkJoin({
        partial: of(partial),
        original: this.client.get(
          `assets/mock-json/form-partials/${partial}.partial.json`
        ),
        override: tenantFormPartials[tenant]?.includes(partial)
          ? this.client
              .get(
                `assets/mock-json/form-partials/tenant-form-partials/${tenant}.${partial}.partial.json`
              )
              .pipe(catchError(() => of(null)))
          : of(null),
      });
    });

    return forkJoin(requests).pipe(
      map((res) => {
        return res.map((r) => {
          if (r.override !== null) {
            return this.mergePartialOverride(r.original, r.override);
          } else {
            return r.original;
          }
        });
      })
    );
  }

  // TODO: we will want to do the stitching together of the
  // different form partials at build time rather than run time
  // so we can move this method into an npm script at some point
  preprocessSchemaForReferences(
    tenant: string,
    formPartials: string[],
    tenantFormPartials: Record<string, string[]>,
    rawJson: FormlyFieldConfig[],
    destroyed$: Observable<unknown>,
    formIsComplete?: boolean,
    readOnlyMode: boolean = false
  ): Observable<FormlyFieldConfig[] | FormlyFieldConfig> {
    let x: Observable<unknown>;
    if (formPartials.length > 0) {
      x = this.stitchPartialOverrides(tenant, formPartials, tenantFormPartials);
    } else {
      x = of({});
    }
    return x.pipe(
      map((results) => {
        const partials = formPartials.reduce(
          (accumulator, partialKey, index: number) => {
            accumulator[partialKey] = results[index];
            return accumulator;
          },
          {}
        );
        return this.convertNode(
          rawJson,
          partials,
          destroyed$,
          formIsComplete,
          readOnlyMode
        );
      })
    );
  }

  // Use this method at runtime to inject the
  // dynamic form configuration that cannot be stored
  // in a static JSON file on the server
  setupDynamicFormConfiguration(
    currentObject: FormlyFieldConfig[] | FormlyFieldConfig,
    destroyed$: Observable<unknown>,
    formIsComplete?: boolean,
    readOnlyMode: boolean = false
  ) {
    const newObject = currentObject;

    // when we receive a JSON object, iterate over its keys
    const keys = Object.keys(newObject);

    for (let i = 0; i < keys.length; i++) {
      // loop instead of forEach for performance
      const value = newObject[keys[i]];

      // Ignore Observables in the data
      // so as to preserve type information
      // for when Formly binds to them
      // and subscribes to load the data
      if (value instanceof Observable) {
        continue;
      }

      if (value instanceof Function) {
        continue;
      }

      if (value instanceof TemplateRef) {
        continue;
      }

      if (
        (typeof value === 'object' && value !== null) ||
        Array.isArray(value)
      ) {
        // we want to dig deeper with objects
        if (value.props && value.props.options) {
          if (typeof value.props.dynamicOptions === 'string') {
            value.props.options = this.mapDynamicOptions(
              value.props.dynamicOptions
            );
          }
        }

        if (value.expressions && !readOnlyMode) {
          for (const prop in value.expressions) {
            value.expressions[prop] = this.mapExpressionPropertyFunctions(
              prop,
              value.expressions[prop]
            );
          }
        }

        if (
          value &&
          value.hooks &&
          typeof value.hooks === 'object' &&
          Object.keys(value.hooks).length > 0
        ) {
          if (value.hooks.onInit && typeof value.hooks.onInit === 'string') {
            const initValues = value.hooks.onInit.split(';').filter((v) => v);
            value.hooks.onInit = (field: FormlyFieldConfig) => {
              for (const value of initValues) {
                this.mapHookFunctions(value, destroyed$, formIsComplete)(field);
              }
            };
          }
          if (
            value.hooks.onChanges &&
            typeof value.hooks.onChanges === 'string'
          ) {
            const changesValue = value.hooks.onChanges
              .split(';')
              .filter((v) => v);
            value.hooks.onChanges = (field: FormlyFieldConfig) => {
              for (const value of changesValue) {
                this.mapHookFunctions(value, destroyed$, formIsComplete)(field);
              }
            };
          }
        }

        newObject[keys[i]] = this.setupDynamicFormConfiguration(
          value,
          destroyed$,
          formIsComplete,
          readOnlyMode
        );
      }
    }

    return newObject;
  }

  private convertNode(
    currentObject: FormlyFieldConfig[] | FormlyFieldConfig,
    fetchedPartials: { [reference: string]: unknown },
    destroyed$: Observable<unknown>,
    formIsComplete?: boolean,
    readOnlyMode: boolean = false
  ): FormlyFieldConfig[] | FormlyFieldConfig {
    const newObject = currentObject;

    // when we receive a JSON object, iterate over its keys
    const keys = Object.keys(newObject);

    for (let i = 0; i < keys.length; i++) {
      // loop instead of forEach for performance
      const value = newObject[keys[i]];

      // Ignore Observables in the data
      // so as to preserve type information
      // for when Formly binds to them
      // and subscribes to load the data
      if (value instanceof Observable) {
        continue;
      }

      if (value instanceof Function) {
        continue;
      }

      if (
        (typeof value === 'object' && value !== null) ||
        Array.isArray(value)
      ) {
        // we want to dig deeper with objects
        if (value.type === 'reference') {
          // if it's a reference, fetch with an HTTP call
          // to avoid a nested subscription() call, just cleaner to use async/await
          const fetchedReference = fetchedPartials[value.partialName];

          delete value.type;
          delete value.partialName; // remove the preprocessed types not relevant to Formly

          // Formly represents its groups as arrays of controls/other groups
          value.fieldGroup = _.cloneDeep(fetchedReference);
          if (
            value.requiredOptions?.mode !== null &&
            value.requiredOptions?.mode !== undefined
          ) {
            value.fieldGroup = this.updateRequiredKeys(
              value.fieldGroup,
              value.requiredOptions.mode,
              value.requiredOptions.requiredKeys
            );
          }
        }

        if (value.props && value.props.options) {
          if (typeof value.props.dynamicOptions === 'string') {
            value.props.options = this.mapDynamicOptions(
              value.props.dynamicOptions
            );
          }
        }

        if (value.expressions && !readOnlyMode) {
          for (const prop in value.expressions) {
            value.expressions[prop] = this.mapExpressionPropertyFunctions(
              prop,
              value.expressions[prop]
            );
          }
        }

        if (
          value &&
          value.hooks &&
          typeof value.hooks === 'object' &&
          Object.keys(value.hooks).length > 0
        ) {
          if (value.hooks.onInit && typeof value.hooks.onInit === 'string') {
            const initValues = value.hooks.onInit.split(';').filter((v) => v);
            value.hooks.onInit = (field: FormlyFieldConfig) => {
              for (const value of initValues) {
                this.mapHookFunctions(value, destroyed$, formIsComplete)(field);
              }
            };
          }
          if (
            value.hooks.onChanges &&
            typeof value.hooks.onChanges === 'string'
          ) {
            const changesValue = value.hooks.onChanges
              .split(';')
              .filter((v) => v);
            value.hooks.onChanges = (field: FormlyFieldConfig) => {
              for (const value of changesValue) {
                this.mapHookFunctions(value, destroyed$, formIsComplete)(field);
              }
            };
          }
        }

        newObject[keys[i]] = this.convertNode(
          value,
          fetchedPartials,
          destroyed$,
          formIsComplete,
          readOnlyMode
        );
      }
    }

    return newObject;
  }

  private updateRequiredKeys(
    currentObject: FormlyFieldConfig[] | FormlyFieldConfig,
    mode: string,
    requiredKeys: string[]
  ): FormlyFieldConfig[] | FormlyFieldConfig {
    const newObject = currentObject;

    // when we receive a JSON object, iterate over its keys
    const keys = Object.keys(newObject);

    for (let i = 0; i < keys.length; i++) {
      // loop instead of forEach for performance
      const value = newObject[keys[i]];

      if (value.fieldGroup !== null && value.fieldGroup !== undefined) {
        value.fieldGroup = this.updateRequiredKeys(
          value.fieldGroup,
          mode,
          requiredKeys
        );
      } else {
        if (value.props === undefined) {
          value.props = {};
        }
        switch (mode) {
          case 'all':
            value.props.required = true;
            break;
          case 'none':
            value.props.required = false;
            break;
          case 'requireSpecificKeys':
            value.props.required =
              requiredKeys.find((k) => k === value.key) !== undefined;
            break;
          case 'addRequiredKeys':
            value.props.required =
              requiredKeys.find((k) => k === value.key) !== undefined
                ? true
                : value.props.required;
            break;
          case 'removeRequiredKeys':
            value.props.required =
              requiredKeys.find((k) => k === value.key) !== undefined
                ? false
                : value.props.required;
            break;
        }
      }
    }

    return newObject;
  }
}
