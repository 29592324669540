import { Injectable } from '@angular/core';
import {
  ActionRequiredAlertRule,
  ApplicationsFilter,
  BusinessRole,
  EnergySource,
  FilterConfig,
  IntentOfGeneration,
  Jurisdiction,
  UDF,
  UdfOptions,
} from '@intellio/shared/models';

import { select, Store, Action } from '@ngrx/store';
import * as fastDeepEqual from 'fast-deep-equal';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import * as QueueFilterActions from './queue-filter.actions';
import * as QueueFilterSelectors from './queue-filter.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class QueueFilterFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  currentQueueFilter$ = this.store.pipe(
    select(QueueFilterSelectors.getQueueFilter),
    filter((filter) => filter !== undefined),
    distinctUntilChanged(fastDeepEqual)
  );

  jurisdictions$: Observable<Jurisdiction[]> = this.store.pipe(
    select(QueueFilterSelectors.getJurisdictions),
    filter((jurisdictions) => jurisdictions?.length > 0)
  );

  intentsofGeneration$: Observable<IntentOfGeneration[]> = this.store.pipe(
    select(QueueFilterSelectors.getIntentsofGeneration),
    filter((intentsOfGeneration) => intentsOfGeneration?.length > 0)
  );

  energySources$: Observable<EnergySource[]> = this.store.pipe(
    select(QueueFilterSelectors.getEnergySources),
    filter((energySources) => energySources?.length > 0)
  );

  businessRoles$: Observable<BusinessRole[]> = this.store.pipe(
    select(QueueFilterSelectors.getBusinessRoles),
    filter((businessRoles) => businessRoles?.length > 0)
  );

  udfs$: Observable<UDF[]> = this.store.pipe(
    select(QueueFilterSelectors.getUDFs),
    filter((udfs) => udfs?.length > 0)
  );

  udfOptions$: Observable<UdfOptions[]> = this.store.pipe(
    select(QueueFilterSelectors.getUDFOptions),
    filter((udfOptions) => udfOptions?.length > 0)
  );

  tenantFilterConfig$: Observable<FilterConfig> = this.store.pipe(
    select(QueueFilterSelectors.getTenantFilterConfig),
    filter((tenantFilterConfig) => tenantFilterConfig !== undefined)
  );

  actionRequiredAlertRules$: Observable<ActionRequiredAlertRule[]> = this.store.pipe(
    select(QueueFilterSelectors.getActionRequiredAlertRules), 
    filter((rules) => rules !== undefined)
  );

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  cacheQueueFilter(queueFilter: ApplicationsFilter) {
    this.store.dispatch(QueueFilterActions.cacheQueueFilter({ queueFilter }));
  }

  evictQueueFilter() {
    this.store.dispatch(QueueFilterActions.evictQueueFilter());
  }

  dispatchGetJurisdictions(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getJurisdictionsLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getJurisdictions());
        }
      });
  }

  dispatchGetIntentsofGeneration(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getIntentsofGenerationLoaded), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getIntentsOfGeneration());
        }
      });
  }

  dispatchGetEnergySources(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getEnergySources), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getEnergySources());
        }
      });
  }

  dispatchGetBusinessRoles(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getBusinessRoles), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getBusinessRoles());
        }
      });
  }

  dispatchGetUDFs(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getUDFs), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getUDFs());
        }
      });
  }

  dispatchGetUDFOptions(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getUDFOptions), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getUDFOptions());
        }
      });
  }

  dispatchGetTenantFilterConfig(override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getTenantFilterConfig), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getTenantFilterConfig());
        }
      });
  }

  dispatchGetActionRequiredAlertRules(isExternal: boolean, override?: boolean) {
    this.store
      .pipe(select(QueueFilterSelectors.getActionRequiredAlertRules), take(1))
      .subscribe((loaded) => {
        if (!loaded || override) {
          this.store.dispatch(QueueFilterActions.getActionRequiredAlertRules({isExternal}));
        }
      });
  }
}
