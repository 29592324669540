import {
  ActionRequiredAlertRule,
  ApplicationsFilter,
  BusinessRole,
  EnergySource,
  FilterConfig,
  IntentOfGeneration,
  Jurisdiction,
  UDF,
  UdfOptions,
} from '@intellio/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import * as QueueFilterActions from './queue-filter.actions';

export const QUEUE_FILTER_FEATURE_KEY = 'queueFilter';

export interface QueueFilterState {
  currentQueueFilter: ApplicationsFilter;
  jurisdictions: Jurisdiction[];
  jurisdictionsLoaded: boolean;
  intentsofGeneration: IntentOfGeneration[];
  intentsofGenerationLoaded: boolean;
  energySources: EnergySource[];
  energySourcesLoaded: boolean;
  businessRoles: BusinessRole[];
  businessRolesLoaded: boolean;
  udfs: UDF[];
  udfsLoaded: boolean;
  udfOptions: UdfOptions[];
  udfOptionsLoaded: boolean;
  tenantFilterConfig: FilterConfig;
  tenantFilterConfigLoaded: boolean;
  actionRequiredAlertRules: ActionRequiredAlertRule[];
  actionRequiredAlertRulesLoaded: boolean
}

export interface QueueFilterPartialState {
  readonly [QUEUE_FILTER_FEATURE_KEY]: QueueFilterState;
}

export const initialState: QueueFilterState = {
  currentQueueFilter: undefined,
  jurisdictions: undefined,
  jurisdictionsLoaded: false,
  intentsofGeneration: undefined,
  intentsofGenerationLoaded: false,
  energySources: undefined,
  energySourcesLoaded: false,
  businessRoles: undefined,
  businessRolesLoaded: false,
  udfs: undefined,
  udfsLoaded: false,
  udfOptions: undefined,
  udfOptionsLoaded: false,
  tenantFilterConfig: undefined,
  tenantFilterConfigLoaded: false,
  actionRequiredAlertRules: undefined,
  actionRequiredAlertRulesLoaded: false
};

export const reducer = createReducer(
  initialState,

  on(QueueFilterActions.cacheQueueFilter, (state, { queueFilter }) => {
    state = {
      ...state,
      currentQueueFilter: queueFilter,
    };

    return state;
  }),
  on(QueueFilterActions.evictQueueFilter, (state) => {
    state = {
      ...state,
      currentQueueFilter: null,
    };

    return state;
  }),
  on(
    QueueFilterActions.getJurisdictionsSucceeded,
    (state, { jurisdictions }) => {
      state = {
        ...state,
        jurisdictions: jurisdictions,
        jurisdictionsLoaded: true,
      };
      return state;
    }
  ),
  on(
    QueueFilterActions.getIntentsofGenerationSucceeded,
    (state, { intentsofGeneration }) => {
      state = {
        ...state,
        intentsofGeneration: intentsofGeneration,
        intentsofGenerationLoaded: true,
      };
      return state;
    }
  ),
  on(
    QueueFilterActions.getEnergySourcesSucceeded,
    (state, { energySources }) => {
      state = {
        ...state,
        energySources: energySources,
        energySourcesLoaded: true,
      };
      return state;
    }
  ),
  on(
    QueueFilterActions.getBusinessRolesSucceeded,
    (state, { businessRoles }) => {
      state = {
        ...state,
        businessRoles: businessRoles,
        businessRolesLoaded: true,
      };
      return state;
    }
  ),
  on(QueueFilterActions.getUDFsSucceeded, (state, { udfs }) => {
    state = {
      ...state,
      udfs: udfs,
      udfsLoaded: true,
    };
    return state;
  }),
  on(QueueFilterActions.getUDFOptionsSucceeded, (state, { udfOptions }) => {
    state = {
      ...state,
      udfOptions: udfOptions,
      udfOptionsLoaded: true,
    };
    return state;
  }),
  on(
    QueueFilterActions.getTenantFilterConfigSucceeded,
    (state, { tenantFilterConfig }) => {
      state = {
        ...state,
        tenantFilterConfig: tenantFilterConfig,
        tenantFilterConfigLoaded: true,
      };
      return state;
    }
  ),
  on(
    QueueFilterActions.getActionRequiredAlertRulesSucceeded,
    (state, { actionRequiredAlertRules }) => {
      state = {
        ...state,
        actionRequiredAlertRules,
        actionRequiredAlertRulesLoaded: true,
      };
      return state;
    }
  )
);
