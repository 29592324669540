<div class="modal">
  <div class="xsmall">
    <h2 i18n mat-dialog-title>
      Delete File
      <div aria-live="rude" aria-label="    A modal has opened."></div>
      <button mat-icon-button [mat-dialog-close]="{ type: 'close' }">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <mat-dialog-content class="mat-typography">
      <p i18n>Are you sure you want to delete this file?</p>
      <div>
        <strong class="strong-text">
          {{ decodeFileName(data.taskFile.fileName) }}
        </strong>
      </div>
      <div>{{ data.fileTypeName }}</div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        class="secondary-button"
        mat-button
        i18n
        [mat-dialog-close]="{ type: 'close' }"
      >
        Cancel
      </button>
      <button
        class="primary-button"
        mat-button
        i18n
        (click)="deleteFile()"
        *ngIf="!deletingFile"
      >
        Delete
      </button>
      <div class="in-place-spinner-wrapper" *ngIf="deletingFile">
        <mat-spinner></mat-spinner>
      </div>
    </mat-dialog-actions>
  </div>
</div>
