export class EditProfileFormSchema {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  addressLineOne: string;
  addressLineTwo: string;
  addressCity: string;
  addressState: string;
  addressZIP: string;
  phone: string;
  title: string;
  isAutoAssignedApplications: boolean;
  roles: Record<string, boolean>;
  notificationPreferences:
    | Record<string, { inApp: boolean; email: boolean }>[]
    | boolean;
}
