import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export const prefillPVModuleDetailsHook = (
  sharedPVModules$,
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    field.formControl.valueChanges
      .pipe(
        switchMap((val) => {
          return forkJoin<[string, any]>([of(val), sharedPVModules$]);
        }),
        takeUntil(destroyed$)
      )
      .subscribe(([modelNum, pvm]) => {
        if (modelNum) {
          const currPvm = pvm.find((pvmodule) => {
            return pvmodule.modelNumber === modelNum;
          });
          var useWatts = field?.props["units"];
          var nameplateValue = currPvm.nameplatePMaxInWatts / 1000;
          if(useWatts !== null
            && useWatts !== undefined
            && useWatts === 'watts'){
              nameplateValue = currPvm.nameplatePMaxInWatts;
          }
          (field.form.controls as any).panel_dc_rating_kw.setValue(
            nameplateValue
          );
        }
      });
  };
};
