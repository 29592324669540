import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SideDrawerService {
  /* 
    This is intended to serve the sole purpose of pushing
    TemplateRefs to be rendered in the template portal
    at connect-root and to remotely close/open the drawer.
    The reason being is that we want to the overlay/drawer container to
    span the entire width/height of the app and not be a buried child component.

    An ideal future state would be to instantiate a component instance
    like MatDialog works.
  */
  private sideDrawerContent = new Subject<TemplateRef<unknown>>();
  sideDrawerContent$ = this.sideDrawerContent.asObservable();

  openDialog(templateToRender: TemplateRef<unknown>) {
    this.sideDrawerContent.next(templateToRender);
  }

  closeExistingDialog(): void {
    this.sideDrawerContent.next(null);
  }
}
