import { Injectable } from '@angular/core';
// bug 66468
import {
  // eslint-disable-line
  ApplicationService,
  ApplicationsService,
} from '@intellio/shared/services';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import * as ViewHeaderActions from './view-header.actions';

@Injectable()
export class ViewHeaderEffects {

  getApplicationHeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewHeaderActions.getApplicationHeaders),
      mergeMap(({ isExternal, appType }) =>
        this.applicationService.getViewHeaders(isExternal, appType).pipe(
          map((applicationHeaders) =>
            ViewHeaderActions.getApplicationHeadersSuccess({
              applicationHeaders,
            })
          ),
          catchError((error: any) =>
            of(ViewHeaderActions.getApplicationHeadersFailure({ error }))
          )
        )
      )
    )
  );

  getQueueHeaders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewHeaderActions.getQueueHeaders),
      mergeMap(({ isExternal, categoryId }) =>
        forkJoin([
          this.applicationsService.getQueueHeaders(isExternal),
          this.applicationsService.getApplicationUdfsWithCategory(categoryId),
        ]).pipe(
          map(([queueHeaders, udfs]) =>
            ViewHeaderActions.getQueueHeadersSuccess({
              queueHeaders: queueHeaders.data,
              udfs: udfs.data,
              categoryId: categoryId, 
            })
          ),
          catchError((error: any) =>
            of(ViewHeaderActions.getQueueHeadersFailure({ error }))
          )
        )
      )
    )
  );

  getRelatedAppsHeaders$ = createEffect(() => 
    this.actions$.pipe(
      ofType(ViewHeaderActions.getRelatedAppsHeaders),
      mergeMap(({isExternal}) => 
        this.applicationService.getRelatedAppsHeaders(isExternal).pipe(
          map((headers) => ViewHeaderActions.getRelatedAppsHeadersSuccess({
            relatedAppsHeaders: headers.data
          })),
          catchError(error => of(ViewHeaderActions.getRelatedAppsHeadersFailure({ error })))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private applicationService: ApplicationService,
    private applicationsService: ApplicationsService
  ) {}
}
