import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemLogData } from '@intellio/shared/models';
import { AppConfigService, AuthService } from '..';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class SystemLogsService extends BaseService {
  readonly PAGE_SIZE = 25;

  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getSystemLogEntries(params) {
    const url = '/api/system/activities';
    const config = { params: params };
    return this.get<SystemLogData>(url, config);
  }

  getSystemActivityTypes() {
    const url = '/api/system/activities/types';
    return this.get<string[]>(url);
  }
}
