import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fillEmptyValue',
})
export class FillEmptyValuePipe implements PipeTransform {
  transform(value: unknown, fillCharacter: string = '-'): string {
    return value === null || value === undefined || value === ''
      ? fillCharacter
      : `${value}`;
  }
}
