import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'itc-auto-logout-warning',
  templateUrl: './auto-logout-warning.component.html',
  styleUrls: ['./auto-logout-warning.component.scss'],
})
export class AutoLogoutWarningComponent implements OnInit {
  private intervalId: number;
  timeRemaining: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: number) {
    this.timeRemaining = data;
  }

  ngOnInit() {
    this.intervalId = setInterval(() => {
      if (this.timeRemaining === 0) {
        clearInterval(this.intervalId);
        return;
      }
      this.timeRemaining--;
    }, 1000);
  }
}
