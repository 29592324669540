import { Application } from './application.model';
import { QueueApplication } from './queue-application.model';

export class ApplicationListResponse {
  externalApplications: Application[] | QueueApplication[];
  internalApplications: Application[] | QueueApplication[];
  locations: unknown;
  totalApplications: number;
  isDefaultResponse: boolean;
  isError: boolean;
}
