import { Pipe, PipeTransform } from '@angular/core';
import { Message, User, SimpleUser } from '@intellio/shared/models';

@Pipe({
  name: 'isOnTeam',
})
export class IsOnTeamPipe implements PipeTransform {
  transform(
    message: Message,
    internalUsers: SimpleUser[] | User[],
    externalUsers: User[],
    teamToTest: 'internal' | 'external'
  ): boolean {
    return (
      (teamToTest === 'internal' ? internalUsers : externalUsers).find(
        (u) => u.email === message.senderUserName
      ) !== undefined
    );
  }
}
