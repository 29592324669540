import { UDR } from './udr.model';

export class UDRDetail {
  id: number;
  applicationId?: string;
  udrId?: string;
  parentId?: number;
  value?: string;
  children?: UDRDetail[];
  expandable?: boolean;
  level?: number;
  udr?: UDR;
  createdDateTime?: Date;
}
