import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private accountService: AccountService
  ) {
    super(client, configService, authService);
  }

  getStatuses() {
    const url = '/api/applications/statuses/details';
    return this.get(url);
  }
}
