import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function passwordValidator(
  formGroup: UntypedFormGroup
): ValidationErrors | null {
  const val1 = formGroup.get('newPassword').value;
  const val2 = formGroup.get('confirmPassword').value;

  return val1 && val2 && val1 !== val2
    ? {
        passwordsDoNotMatch: true,
      }
    : null;
}
