import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ApplicationFile,
  FileUploadWithMetadata,
  IntellioHttpResponse,
} from '@intellio/shared/models';
import { ConnectableObservable, Observable } from 'rxjs';
import {
  publish,
  publishReplay,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { processData } from '@intellio/shared/utils';
import { ApplicationFilesService } from './application-files.service';

export class BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {}

  protected apiUrl = this.configService.url;
  protected tenantConfig = this.configService.tenantConfig;

  public get<T>(
    serviceUrl: string,
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<T>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.get<IntellioHttpResponse<T>>(
          `${this.configService.url}${serviceUrl}`,
          options
        );
      })
    );
  }

  public getWithoutResponseBase<T>(
    serviceUrl: string,
    options: Record<string, unknown> = {}
  ): Observable<T> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.get<T>(
          `${this.configService.url}${serviceUrl}`,
          options
        );
      })
    );
  }

  //used to retrieve a blob file from the backend using a GET call
  //downloads file to browser
  protected getFile(
    serviceUrl: string,
    options: Record<string, unknown> = {}
  ): Observable<HttpResponse<Blob>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.get(`${this.configService.url}${serviceUrl}`, {
          ...options,
          responseType: 'blob',
          observe: 'response',
        });
      }),
      tap((blob: HttpResponse<Blob>) => {
        const fileName = this.getAttachmentFileName(blob);
        const blobUrl = window.URL.createObjectURL(blob.body);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = decodeURIComponent(
          fileName.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      }),
      take(1)
    );
  }

  //used to retrieve a blob file from the backend using a POST call
  //downloads file to browser
  protected postFile(
    serviceUrl: string,
    body: any,
    options: Record<string, unknown> = {}
  ): Observable<HttpResponse<Blob>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.post(
          `${this.configService.url}${serviceUrl}`,
          body,
          {
            ...options,
            responseType: 'blob',
            observe: 'response',
          }
        );
      }),
      tap((blob: HttpResponse<Blob>) => {
        const fileName = this.getAttachmentFileName(blob);
        const blobUrl = window.URL.createObjectURL(blob.body);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = decodeURIComponent(
          fileName.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
        );
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      }),
      take(1)
    );
  }

  public post<T>(
    serviceUrl: string,
    data: unknown = {},
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<T>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.post<IntellioHttpResponse<T>>(
          `${this.configService.url}${serviceUrl}`,
          processData(options, data),
          options
        );
      })
    );
  }

  protected uploadFiles(
    serviceUrl: string,
    filesWithMetadata: FileUploadWithMetadata[],
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<any>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        const metadata = [];

        const formData: FormData = new FormData();
        filesWithMetadata.forEach((fileWithMetadata) => {
          formData.append('file', fileWithMetadata.file);
          metadata.push({ ...fileWithMetadata, file: undefined });
        });

        // Send only metadata inside the metadata key
        formData.append('metadata', JSON.stringify(metadata));

        return this.client.post<IntellioHttpResponse<ApplicationFile>>(
          `${this.configService.url}${serviceUrl}`,
          formData,
          options
        );
      }),
      take(1)
    );
  }

  public put<T>(
    serviceUrl: string,
    data: unknown,
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<T>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.put<IntellioHttpResponse<T>>(
          `${this.configService.url}${serviceUrl}`,
          data,
          options
        );
      })
    );
  }

  public delete<T>(
    serviceUrl: string,
    options: Record<string, unknown> = {}
  ): Observable<IntellioHttpResponse<T>> {
    return this.authService.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.delete<IntellioHttpResponse<T>>(
          `${this.configService.url}${serviceUrl}`,
          options
        );
      })
    );
  }

  public getAttachmentFileName(response: HttpResponse<Blob>) {
    let fileName = '[Unnamed File]';
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const matches = this.checkFileName(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  public checkFileName(disposition) {
    // The content disposition header can include the file name in a few
    // different formats. Use the following Regex string to capture them all.
    // See explanation here: https://stackoverflow.com/a/23054920
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    return fileNameRegex.exec(disposition);
  }
}
