import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  Form,
  FormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { getValueOnObjectFromStringProperty } from '@intellio/shared/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';

@Component({
  selector: 'itc-conditional-hide',
  template: `
    <div [hidden]="!shouldRender">
      <ng-template #fieldComponent></ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalHideComponent
  extends IntellioFormlyWrapperComponent
  implements OnInit, OnDestroy
{
  shouldRender = null;
  rootForm: AbstractControl;

  constructor(
    protected notifierService: WrapperChangeDetectNotifierService,
    protected cd: ChangeDetectorRef
  ) {
    super(notifierService, cd);
  }

  ngOnInit(): void {
    let root = this.field;
    this.rootForm = root.form;
    let isRoot = false;
    while (!isRoot) {
      if (!root.parent) {
        isRoot = true;
      } else {
        root = root.parent;
      }
    }

    // Option 1. Fire all conditionals whenever a
    // change occurs anywhere on the form
    // root.formControl.valueChanges
    //   .pipe(startWith(undefined), takeUntil(this.destroyed$))
    //   .subscribe(() => {
    //     this.render(this.field, root);
    //     console.log(this.field.key);
    //   });

    // Option 2. Subscribe only to value changes
    // on the fields that would cause this conditional
    // to change values
    const conditions = this.field.props.condition;
    conditions.forEach((cond) => {
      if (!cond.ignoreChanges) {
        const f = cond.keyIsGlobal
          ? root.formControl
          : this.field.parent.formControl;
        const ctrl = f.get(cond.key);
        if (ctrl !== null && ctrl !== undefined) {
          ctrl.valueChanges
            .pipe(startWith(undefined), takeUntil(this.destroyed$))
            .subscribe(() => {
              this.render(this.field, root);
            });
        } else {
          console.log(
            `${cond.key} is not associated with a form control - consider setting "keyIsGlobal: true" on ${this.field.key} if this is an expected form control`
          );
        }
      } else {
        this.render(this.field, root);
      }
    });

    this.initChangeDetectSub();
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  formatForComparision(val: unknown) {
    if (typeof val === 'number') {
      return val.toFixed(6);
    } else {
      return val;
    }
  }

  recursivelyClearFields(field: FormlyFieldConfig) {
    const group = field.fieldGroup;
    for (let i = 0; i < group.length; i++) {
      if (group[i].fieldGroup) {
        this.recursivelyClearFields(group[i]);
      } else if (group[i].key) {
        if (group[i].formControl.value) {
          group[i].formControl.reset(group[i].defaultValue || null);
        }

        if (group[i].formControl.enabled) {
          group[i].formControl.reset(group[i].defaultValue || null);
          group[i].formControl.disable({
            onlySelf: true,
            emitEvent: false,
          });
        }
      }
    }
  }

  recursivelyEnableFields(field: FormlyFieldConfig) {
    const group = field.fieldGroup;
    if (field.key) {
      if (field.formControl.disabled) {
        field.formControl.enable({
          onlySelf: true,
          emitEvent: false,
        });
        // as we recurse through the controls
        // make sure we only call reset on FormControl
        // instances and not FormGroup instances
        // so as not to get an exception thrown
        if (field.formControl instanceof FormControl) {
          field.formControl.reset(field.defaultValue || null);
        }
      }
    }
    for (let i = 0; i < group.length; i++) {
      if (group[i].fieldGroup) {
        this.recursivelyEnableFields(group[i]);
      } else if (group[i].key) {
        if (group[i].formControl.disabled) {
          group[i].formControl.enable({
            onlySelf: true,
            emitEvent: false,
          });
          if (
            group[i].formControl.value === null ||
            group[i].formControl.value === undefined
          ) {
            if (
              group[i].defaultValue !== null &&
              group[i].defaultValue !== undefined
            )
              group[i].formControl.reset(group[i].defaultValue);
          }
        }
      }
    }
  }

  // This component determines if we should
  // show or hide a form section or field
  // based on a set of conditions.
  // If it is determined that we are hiding a
  // field/section that was previously visible
  // we also clear the associated form and model
  // data. See the steps below for the implementation.
  render(field: FormlyFieldConfig, root: FormlyFieldConfig) {
    // TODO: use the shared function called shouldRender in
    // the form-data.utility.ts file
    const conditions = field.props.condition;
    const conditionOptions = field.props.conditionOptions;

    const predicate = (obj) => {
      let shouldShow = null;
      const valueToCompare = obj.valueKey
        ? getValueOnObjectFromStringProperty(
            obj.valueKey,
            obj.valueKeyIsGlobal ? root.model : field.parent.model
          )
        : obj.value;
      let value: any = '';
      const stringArray = [];
      if (obj.key) {
        value = getValueOnObjectFromStringProperty(
          obj.key,
          obj.keyIsGlobal ? root.model : field.parent.model
        );
        if (typeof value === 'object') {
          for (const property in value) {
            if (value[property]) {
              stringArray.push(property);
            }
          }
        }
        switch (obj.operator) {
          case '!':
            shouldShow =
              valueToCompare === null
                ? value !== undefined && value !== null
                : this.formatForComparision(value) !==
                  this.formatForComparision(valueToCompare);
            break;
          case '>':
            shouldShow =
              valueToCompare === null || value === undefined
                ? value !== undefined && value !== null
                : +value > +valueToCompare;
            break;
          case '<':
            shouldShow =
              valueToCompare === null || value === undefined
                ? value !== undefined && value !== null
                : +value < +valueToCompare;
            break;
          case 'has':
            if (typeof value !== 'object') {
              shouldShow =
                value === null || value === undefined
                  ? valueToCompare === undefined || valueToCompare === null
                  : value.includes(valueToCompare);
            } else {
              shouldShow =
                valueToCompare === null || value === undefined
                  ? value !== undefined && value !== null
                  : stringArray.includes(valueToCompare);
            }
            break;
          default:
            if (typeof value !== 'object') {
              shouldShow =
                valueToCompare === null
                  ? value === undefined || value === null
                  : this.formatForComparision(value) ===
                    this.formatForComparision(valueToCompare);
            } else {
              shouldShow =
                valueToCompare === null
                  ? value === undefined || value === null
                  : stringArray.length === 1 &&
                    stringArray.includes(valueToCompare);
            }
        }

        return shouldShow;
      } else {
        return valueToCompare;
      }
    };

    // 1. Compute the next value that
    // will be saved to this.shouldRender
    const nextShouldRenderVal =
      conditionOptions?.operator === '||'
        ? conditions.some(predicate)
        : conditions.every(predicate);

    // 2. Save off the current value of this.shouldRender
    // for comparison later
    const shouldRenderTemp = this.shouldRender;

    // 3. Assign the next value of shouldRender
    // to this.shouldRender. Note: we are doing this here
    // to prevent infinite loops through the
    // .valueChanges observable
    // when we call formControl.reset() later
    // in the code. Note: this assignment needs to
    // happen before invoking formControl.reset()!
    this.shouldRender = nextShouldRenderVal;

    // 4. Check if the new value of this.shouldRender is set to false
    // and the prior value of this.shouldRender was true.
    // i.e. a field that was previously visible is now going to be hidden
    // if (!nextShouldRenderVal && nextShouldRenderVal !== shouldRenderTemp) {
    //   // 5. reset the formControl and broadcast to all form elements that
    //   // they should recompute their values and properties
    //   // Note: due to the checks we put in place in steps 1 - 4
    //   // we are able to prevent infinite loops through
    //   // the .valueChanges observable this method is subscribing to
    //   if (field.wrappers?.includes('form-field')) {
    //     field.formControl.reset(null);
    //   }

    //   // 6. delete all nested associated fields in its model
    //   // since they are no longer visible
    //   if (field.fieldGroup) {
    //     this.recursivelyClearFields(field);
    //   }
    // }

    if (
      !nextShouldRenderVal &&
      (shouldRenderTemp === null || nextShouldRenderVal !== shouldRenderTemp)
    ) {
      if (field.wrappers?.includes('form-field')) {
        field.formControl.reset(null);
        field.formControl.disable({
          onlySelf: true,
          emitEvent: false,
        });
      } else if (
        field.key &&
        (field.type === 'file' || field.type === 'typeahead')
      ) {
        // file currently are the only form inputs
        // that do not have form-fields wrappers applied to
        // them. Thus, we need this check
        field.formControl.reset(null);
        field.formControl.disable({
          onlySelf: true,
          emitEvent: false,
        });
      }

      if (field.fieldGroup && field.key) {
        field.formControl.reset();
        field.formControl.disable({
          emitEvent: false,
        });
      } else if (field.fieldGroup) {
        this.recursivelyClearFields(field);
        if (
          field.formControl &&
          Object.keys((field.formControl as UntypedFormGroup).controls).every(
            (key) =>
              (field.formControl as UntypedFormGroup).controls[key].disabled
          )
        ) {
          field.formControl.disable({
            onlySelf: true,
            emitEvent: false,
          });
        }
      }
    }

    if (nextShouldRenderVal && nextShouldRenderVal !== shouldRenderTemp) {
      if (field.wrappers?.includes('form-field')) {
        field.formControl.enable({
          onlySelf: true,
          emitEvent: false,
        });
        // was hidden; now we are showing it.
        // -> Set the value to the default or null
        if (shouldRenderTemp === false) {
          if (field.defaultValue !== null && field.defaultValue !== undefined) {
            field.formControl.reset(field.defaultValue);
          } else {
            field.formControl.reset(null);
          }
        }
      } else if (
        field.key &&
        (field.type === 'file' || field.type === 'typeahead')
      ) {
        field.formControl.enable({
          onlySelf: true,

          emitEvent: false,
        });

        // was hidden; now we are showing it.

        // -> Set the value to the default or null

        if (shouldRenderTemp === false) {
          if (field.defaultValue !== null && field.defaultValue !== undefined) {
            field.formControl.reset(field.defaultValue);
          } else {
            field.formControl.reset(null);
          }
        }
      }

      if (field.fieldGroup && field.key) {
        field.formControl.enable({
          emitEvent: true,
        });
      } else if (field.fieldGroup) {
        this.recursivelyEnableFields(field);
      }

      if (field.fieldGroup && field.key) {
        field.formControl.enable({
          emitEvent: false,
          onlySelf: true,
        });
      }
    }

    if (nextShouldRenderVal !== shouldRenderTemp) {
      this.cd.markForCheck();
    }
  }
}
