import { ComponentType } from '@angular/cdk/portal';
import { EmbeddedViewRef, Injectable, TemplateRef } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NotificationType } from '@intellio/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private snackBarRef: MatSnackBarRef<any>;

  private defaultSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    panelClass: ['snackbar-error'],
  };

  constructor(private snackBarService: MatSnackBar) {}

  get snackBar() {
    return this.snackBarRef;
  }

  open(
    message: string,
    type: NotificationType,
    config: MatSnackBarConfig<any> = {}
  ): MatSnackBarRef<unknown> {
    return this.openWithActionText(message, type, undefined, config);
  }

  openWithActionText(
    message: string,
    type: NotificationType,
    actionText: string,
    config: MatSnackBarConfig<any> = {}
  ): MatSnackBarRef<unknown> {
    this.snackBarRef = this.snackBarService.open(
      message,
      actionText || 'Dismiss',
      {
        ...this.defaultSnackBarConfig,
        ...config,
        panelClass: this.getPanelClass(type),
      }
    );
    return this.snackBarRef;
  }

  openFromComponent<D>(
    component: ComponentType<D>,
    type: NotificationType,
    data: unknown = {}
  ): MatSnackBarRef<D> {
    this.snackBarRef = this.snackBarService.openFromComponent(component, {
      ...this.defaultSnackBarConfig,
      panelClass: this.getPanelClass(type),
      data,
    });
    return this.snackBarRef;
  }

  openFromTemplate<D>(
    template: TemplateRef<D>,
    type: NotificationType,
    data: unknown = {}
  ): MatSnackBarRef<EmbeddedViewRef<D>> {
    this.snackBarRef = this.snackBarService.openFromTemplate(template, {
      ...this.defaultSnackBarConfig,
      panelClass: this.getPanelClass(type),
      data,
    });
    return this.snackBarRef;
  }

  dismiss() {
    this.snackBarService.dismiss();
    this.snackBarRef = null;
  }

  private getPanelClass(type: NotificationType) {
    const classes = ['snackbar'];
    switch (type) {
      case 'info':
        classes.push('snackbar-info');
        break;
      case 'success':
        classes.push('snackbar-success');
        break;
      case 'warn':
        classes.push('snackbar-warn');
        break;
      case 'error':
        classes.push('snackbar-error');
        break;
    }
    return classes;
  }
}
