import { UntypedFormControl, ValidationErrors } from '@angular/forms';

export function requireTrueValidator(
  control: UntypedFormControl
): ValidationErrors | null {
  const value = control.value;

  return value !== true
    ? {
        invalidTrueValue: true,
      }
    : null;
}
