import { AppStatusTimerExtension } from './app-status-timer-extension.model';

export class AppStatusTimer {
  id: number;
  applicationId: string;
  timerConfigId: number;
  timerRemainingDurationInSeconds: number;
  timerTotalDurationInSeconds: number;
  suppressAlertNotifications: boolean;
  suppressExpirationNotifications: boolean;
  daysToNextNotification: number;
  mostRecentNotificationCorrelationId: number;
  extensions: AppStatusTimerExtension[];

  displayOrder: number;
  timerDurationInSeconds: number;
  alertDurationInSeconds: number;
  durationCalculationType: string;
  alertReminderNotificationFrequencyInDays: number;
  expirationReminderNotificationFrequencyInDays: number;
  displayName: string;
  timerExpiredApplicationStatusToId: number;
  isActive: boolean;
  createdDateTime: string;
}
