export class EditApplicationSync {
  applicationUDFs? = {};
  addressLineOne?: string;
  addressLineTwo?: string;
  addressCity?: string;
  addressState?: string;
  addressZIP?: string;
  latitude?: string;
  longitude?: string;
  energySourceId?: string;
  generationTypeId?: string;
  applicationCapacity?: number;
  intentOfGenerationId?: string;
  customerFullName?: string;
  accountNumber?: string;
}
