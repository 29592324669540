import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable()
export class APIErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.notificationService.open(
            'You are not authenticated to take this action',
            'error'
          );
        } else if (error.status === 403) {
          this.notificationService.open(
            'You are not authorized to take this action',
            'error'
          );
        } else {
          if (error?.error?.message) {
            this.notificationService.open(
              'An error has occurred: ' + error?.error?.message,
              'error'
            );
          }
        }
        return throwError(error);
      })
    );
  }
}
