import { OutOfOfficeBackup } from './outofoffice-backup.model';

export class OutOfOfficeEntry {
  id: number;
  userId: string;
  assignExistingTasks: boolean;
  autoResponseMessage: string;
  spocBackups?: OutOfOfficeBackup[];
  ownerBackups?: OutOfOfficeBackup[];
}
