import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from '@intellio/shared/models';
/* eslint-disable @nx/enforce-module-boundaries  */
// bug 66468 all imports
import {
  AppTasksService,
  NotificationService,
  SideDrawerService,
} from '@intellio/shared/services';
import { throwError } from 'rxjs';

@Component({
  selector: 'itc-app-task-delete',
  templateUrl: './app-task-delete.component.html',
  styleUrls: ['./app-task-delete.component.scss'],
})
export class AppTaskDeleteComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private sideDrawerService: SideDrawerService,
    private appTasksService: AppTasksService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AppTaskDeleteComponent>
  ) {}
  task: Task;
  loading = false;

  ngOnInit(): void {
    this.task = this.data;
  }

  deleteTask() {
    this.loading = true;
    this.appTasksService.deleteTask(this.task.id).subscribe(
      () => {
        this.dialogRef.close();
        this.sideDrawerService.closeExistingDialog();
        this.notificationService.open('Task Deleted', 'success');
      },
      (err: any) => {
        this.notificationService.open(err.error.message, 'error');
        return throwError(err);
      }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
