export class AppStatusTimerConfig {
  id: number;
  displayOrder: number;
  timerDurationInSeconds: number;
  alertDurationInSeconds: number;
  durationCalculationType: string;
  alertReminderNotificationFrequencyInDays: number;
  expirationReminderNotificationFrequencyInDays: number;
  displayName: string;
  timerExpiredApplicationStatusToId: number;
}
