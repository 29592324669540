import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OutOfOfficeEntry, User } from '@intellio/shared/models';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { OutOfOfficeService } from './outofoffice.service';

@Injectable({ providedIn: 'root' })
export class OutOfOfficePopup {
  constructor(
    private notificationService: NotificationService,
    private snackBarService: MatSnackBar,
    private outOfOfficeService: OutOfOfficeService
  ) {}

  currentUser: User;
  oooData: OutOfOfficeEntry;

  init(user: User) {
    this.outOfOfficeService.getOutOfOfficeEntry(user?.id).subscribe((data) => {
      this.oooData = data.data;
    });
    this.currentUser = user;
    if (user.isOutOfOffice) {
      this.displayToast();
    }
  }

  displayToast() {
    const snackbarConfig: MatSnackBarConfig = {
      duration: 86400000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['snackbar-warn'],
      data: this.currentUser,
    };
    this.snackBarService
      .open('Out of Office is On', 'Turn Off', snackbarConfig)
      .onAction()
      .subscribe(() => {
        this.turnOffOutOfOffice();
      });
  }

  turnOffOutOfOffice() {
    this.outOfOfficeService
      .deleteOutOfOfficeEntry(this.oooData)
      .pipe(
        catchError((err: any) => {
          this.notificationService.open(err.error.message, 'error');
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.notificationService.open(
          'You turned off Out-Of-Office',
          'success'
        );
      });
  }
}
