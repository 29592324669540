import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { UntypedFormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'itc-formly-timepicker',
  template: `
    {{ props.label }}
    <span
      *ngIf="props.required && props.hideRequiredMarker !== true"
      aria-hidden="true"
      class="mat-form-field-required-marker"
      >*</span
    >
    <ngx-mat-timepicker
      [formControl]="control"
      [showSeconds]="false"
      [showSpinners]="true"
      [stepMinute]="60"
      [id]="id"
      [color]="props.color"
      [formlyAttributes]="field"
      [tabIndex]="props.tabindex"
      [defaultTime]="[0, 0]"
    >
    </ngx-mat-timepicker>
    <span>Format (hh:mm)</span>
  `,
  styleUrls: ['./formly-timepicker.component.scss'],
})
export class TimepickerComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  control: UntypedFormControl;

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
  }
}
