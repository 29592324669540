import { Injectable } from '@angular/core';
import { User } from '@intellio/shared/models';
import { Observable, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from './account.service';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class TokenRefreshService {
  private timer$: Observable<unknown>;
  private stopTimer$ = new Subject();

  constructor(
    private appConfigService: AppConfigService,
    private authService: AuthService,
    private accountService: AccountService
  ) {}

  init(user: User) {
    if (user) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  }

  private startTimer(startImmediately = false) {
    if (this.timer$) {
      return;
    }

    // 5 minute buffer for token refresh
    const refreshIntervalInSeconds =
      this.appConfigService.tenantConfig.accessTokenLifetimeInSeconds - 300;
    const refreshIntervalInMs = refreshIntervalInSeconds * 1000;
    this.timer$ = timer(
      startImmediately ? 0 : refreshIntervalInMs,
      refreshIntervalInMs
    ).pipe(
      switchMap(() => this.authService.renewToken()),
      takeUntil(this.stopTimer$)
    );
    this.timer$.subscribe();
  }

  private stopTimer() {
    if (this.timer$) {
      this.stopTimer$.next(null);
      this.timer$ = null;
    }
  }
}
