import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';
import * as fastDeepEqual from 'fast-deep-equal';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as AuthFeature from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {
  // NOTE: We are currently not using the AuthFacade for
  // fetching the latest version of the user token
  // due to a circular dependency between the shared-services
  // library and this shared-state-mgmt library.
  // Mainly services use the auth state and until we
  // determine a way to resolve the circular dependency
  // this code will remain unused for the time being.
  // Currently the auth token is being cached by the auth
  // service and that is working fine for the time being.

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  authToken$ = this.store.pipe(
    select(AuthSelectors.getToken),
    filter((token) => token !== null),
    distinctUntilChanged(fastDeepEqual)
  );

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  loginUser(username: string, password: string, recaptchaResponse?: string) {
    this.store.dispatch(
      AuthActions.getAuth({ username, password, recaptchaResponse })
    );
  }
}
