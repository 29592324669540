import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminFormWorkflowConfig } from '@intellio/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';

@Injectable({ providedIn: 'root' })
export class AdminConfigService extends BaseService {
  readonly PAGE_SIZE = 25;

  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getConfig(endpoint): Observable<AdminFormWorkflowConfig> {
    const getFormsAndWorkflows = endpoint;
    return this.get<string>(getFormsAndWorkflows).pipe(
      map((response) => JSON.parse(response.data))
    );
  }
  getFilterOptions(route) {
    const getFormsAndWorkflows = '/' + route;
    return this.get<any[]>(getFormsAndWorkflows);
  }
  deleteResource(route, resource) {
    let result = route;
    Object.keys(resource).forEach((key) => {
      const subStr = '{' + key + '}';
      let newValue = resource[key];
      if (newValue === null || newValue === undefined) {
        newValue = '';
      } else {
        newValue = newValue.toString();
      }
      result = result.replace(subStr, newValue);
    });
    const deleteFormsAndWorkflows = '/' + result;
    return this.delete<any[]>(deleteFormsAndWorkflows);
  }
  createResource(route, resource) {
    let result = route;
    Object.keys(resource).forEach((key) => {
      const subStr = '{' + key + '}';
      let newValue = resource[key];
      if (newValue === null || newValue === undefined) {
        newValue = '';
      } else {
        newValue = newValue.toString();
      }
      result = result.replace(subStr, newValue);
    });
    return this.post('/' + result, resource);
  }
  fetchDropdownOptions(field) {
    if (typeof field.dropdown.route === 'string') {
      // Fetch the options from the API route.
      return this.getFilterOptions(field.dropdown.route);
    }
  }
  getResources(url) {
    return this.get<any[]>(url);
  }
}
