import {
  ActionRequiredAlertRule,
  ApplicationsFilter,
  BusinessRole,
  EnergySource,
  FilterConfig,
  IntentOfGeneration,
  Jurisdiction,
  UDF,
  UdfOptions,
} from '@intellio/shared/models';
import { createAction, props } from '@ngrx/store';

export enum QueueFilterActions {
  CacheQueueFilter = '[QueueFilter] Cache QueueFilter',
  EvictQueueFilter = '[QueueFilter] Evict QueueFilter',
  GetJurisdictions = '[Jurisdictions] Get',
  GetJurisdictionsSucceeded = '[Jurisdictions] Get Succeeded',
  GetJurisdictionsFailure = '[Jurisdictions] Get Failure',
  GetIntentsofGeneration = '[IntentsofGeneration] Get',
  GetIntentsofGenerationSucceeded = '[IntentsofGeneration] Get Succeeded',
  GetIntentsofGenerationFailure = '[IntentsofGeneration] Get Failure',
  GetEnergySources = '[EnergySources] Get',
  GetEnergySourcesSucceeded = '[EnergySources] Get Succeeded',
  GetEnergySourcesFailure = '[EnergySources] Get Failure',
  GetBusinessRoles = '[BusinessRoles] Get',
  GetBusinessRolesSucceeded = '[BusinessRoles] Get Succeeded',
  GetBusinessRolesFailure = '[BusinessRoles] Get Failure',
  GetUDFs = '[UDFs] Get',
  GetUDFsSucceeded = '[UDFs] Get Succeeded',
  GetUDFsFailure = '[UDFs] Get Failure',
  GetUDFOptions = '[UDFOptions] Get',
  GetUDFOptionsSucceeded = '[UDFOptions] Get Succeeded',
  GetUDFOptionsFailure = '[UDFOptions] Get Failure',
  GetTenantFilterConfig = '[TenantFilterConfig] Get',
  GetTenantFilterConfigSucceeded = '[TenantFilterConfig] Get Succeeded',
  GetTenantFilterConfigFailure = '[TenantFilterConfig] Get Failure',
  GetActionRequiredAlertRules = '[ActionRequiredAlertRules] Get',
  GetActionRequiredAlertRulesSucceeded = '[ActionRequiredAlertRules] Get Succeeded',
  GetActionRequiredAlertRulesFailure = '[ActionRequiredAlertRules] Get Failure',
}

export const cacheQueueFilter = createAction(
  QueueFilterActions.CacheQueueFilter,
  props<{ queueFilter: ApplicationsFilter }>()
);

export const evictQueueFilter = createAction(
  QueueFilterActions.EvictQueueFilter
);

export const getJurisdictions = createAction(
  QueueFilterActions.GetJurisdictions
);

export const getJurisdictionsSucceeded = createAction(
  QueueFilterActions.GetJurisdictionsSucceeded,
  props<{ jurisdictions: Jurisdiction[] }>()
);

export const getJurisdictionsFailure = createAction(
  QueueFilterActions.GetJurisdictionsFailure,
  props<{ error: any }>()
);

export const getIntentsOfGeneration = createAction(
  QueueFilterActions.GetIntentsofGeneration
);

export const getIntentsofGenerationSucceeded = createAction(
  QueueFilterActions.GetIntentsofGenerationSucceeded,
  props<{ intentsofGeneration: IntentOfGeneration[] }>()
);

export const getIntentsofGenerationFailure = createAction(
  QueueFilterActions.GetIntentsofGenerationFailure,
  props<{ error: any }>()
);

export const getEnergySources = createAction(
  QueueFilterActions.GetEnergySources
);

export const getEnergySourcesSucceeded = createAction(
  QueueFilterActions.GetEnergySourcesSucceeded,
  props<{ energySources: EnergySource[] }>()
);

export const getEnergySourcesFailure = createAction(
  QueueFilterActions.GetEnergySourcesFailure,
  props<{ error: any }>()
);

export const getBusinessRoles = createAction(
  QueueFilterActions.GetBusinessRoles
);

export const getBusinessRolesSucceeded = createAction(
  QueueFilterActions.GetBusinessRolesSucceeded,
  props<{ businessRoles: BusinessRole[] }>()
);

export const getBusinessRolesFailure = createAction(
  QueueFilterActions.GetBusinessRolesFailure,
  props<{ error: any }>()
);

export const getUDFs = createAction(QueueFilterActions.GetUDFs);

export const getUDFsSucceeded = createAction(
  QueueFilterActions.GetUDFsSucceeded,
  props<{ udfs: UDF[] }>()
);

export const getUDFsFailure = createAction(
  QueueFilterActions.GetUDFsFailure,
  props<{ error: any }>()
);

export const getUDFOptions = createAction(QueueFilterActions.GetUDFOptions);

export const getUDFOptionsSucceeded = createAction(
  QueueFilterActions.GetUDFOptionsSucceeded,
  props<{ udfOptions: UdfOptions[] }>()
);

export const getUDFOptionsFailure = createAction(
  QueueFilterActions.GetUDFOptionsFailure,
  props<{ error: any }>()
);

export const getTenantFilterConfig = createAction(
  QueueFilterActions.GetTenantFilterConfig
);

export const getTenantFilterConfigSucceeded = createAction(
  QueueFilterActions.GetTenantFilterConfigSucceeded,
  props<{ tenantFilterConfig: FilterConfig }>()
);

export const getTenantFilterConfigFailure = createAction(
  QueueFilterActions.GetTenantFilterConfigFailure,
  props<{ error: any }>()
);

export const getActionRequiredAlertRules = createAction(
  QueueFilterActions.GetActionRequiredAlertRules,
  props<{ isExternal: boolean}>()
);

export const getActionRequiredAlertRulesSucceeded = createAction(
  QueueFilterActions.GetActionRequiredAlertRulesSucceeded,
  props<{ actionRequiredAlertRules: ActionRequiredAlertRule[] }>()
);

export const getActionRequiredAlertRulesFailure = createAction(
  QueueFilterActions.GetActionRequiredAlertRulesFailure,
  props<{ error: any }>()
);
