import { InjectionToken } from '@angular/core';

export const FORM_PARTIAL = new InjectionToken<string>('form.partial');

export const TENANT_FORM_PARTIAL = new InjectionToken<string>(
  'tenant.form.partial'
);

export const MOCKED_SUPPLEMENTAL_FORMS = new InjectionToken<string>(
  'mocked.supplemental.forms'
);
export interface TenantFormPartials {
  [key: string]: string[];
}
