import { User } from '@intellio/shared/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCOUNT_FEATURE_KEY, AccountState } from './account.reducer';

// Lookup the 'Account' feature state managed by NgRx
export const getAccountState =
  createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const getAccount = createSelector(
  getAccountState,
  (state: AccountState) => state.currentUser
);

export const getExternalUsers = createSelector(
  getAccountState,
  (state: AccountState) => state.externalUsers
);

export const getExternalUsersLoaded = createSelector(
  getAccountState,
  (state: AccountState) => state.externalUsersLoaded
);

export const getSimpleExternalUsers = createSelector(
  getAccountState,
  (state: AccountState) => state.simpleExternalUsers
);

export const getSimpleExternalUsersLoaded = createSelector(
  getAccountState,
  (state: AccountState) => state.simpleExternalUsersLoaded
);

export const getInternalUsers = createSelector(
  getAccountState,
  (state: AccountState) => state.internalUsers
);

export const getInternalUsersLoaded = createSelector(
  getAccountState,
  (state: AccountState) => state.internalUsersLoaded
);

export const getUserActiveCategory = createSelector(
  getAccount,
  (user: User) => user.activeCategoryId
);

export const getAvailableCategoriesForUser = createSelector(
  getAccountState,
  (state: AccountState) => state.availableCategories
);

export const getStatusChangeSchemas = createSelector(
  getAccountState,
  (state: AccountState) => state.statusChangeSchemas
);
