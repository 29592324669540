import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// bug 66468
import { ContextualHelpService } from '@intellio/shared/services'; // eslint-disable-line

@Component({
  selector: 'itc-contextual-help-modal',
  templateUrl: './contextual-help-modal.component.html',
  styleUrls: ['./contextual-help-modal.component.scss'],
})
export class ContextualHelpModalComponent implements OnInit {
  helpHtml: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ContextualHelpModalComponent>,
    public contextualHelpService: ContextualHelpService
  ) {}

  ngOnInit() {
    this.contextualHelpService.getContextualHelp().subscribe((helpData) => {
      const storedHelp = helpData.data.schema?.schema.find(
        (i) => i.fieldName === this.data.props.contextualHelp
      )?.helpText;
      this.helpHtml = storedHelp ? storedHelp : this.data.props.contextualHelp;
    });
  }
}
