import { Component } from '@angular/core';

// bug 66468
@Component({
  // eslint-disable-next-line
  selector: 'leave-before-saving',
  templateUrl: './leave-before-saving.component.html',
  styleUrls: ['./leave-before-saving.component.css'],
})
export class LeaveBeforeSavingComponent {}
