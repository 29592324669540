import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { RoleService } from './role.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private authService: AuthService,
    private roleService: RoleService
  ) {}

  login(username: string, password: string, recaptchaResponse?: string) {
    return this.authService.loginUser(username, password, recaptchaResponse);
  }

  autoLogin() {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((token) => {
        if (token !== '') {
          return of(true);
        }
        return of(false);
      })
    );
  }
}
