import { ThemeMode } from '@intellio/shared/models';
import { AppConfigService, ThemeService } from '@intellio/shared/services';

export function initApp(
  themeService: ThemeService,
  appConfigService: AppConfigService
) {
  return () => {
    appConfigService.loadConfigFile();
    themeService.setTheme(appConfigService.config.tenant, ThemeMode.Light);
  };
}
