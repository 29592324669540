import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ModuleService } from './module.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesModuleGuard implements CanActivate {
  constructor(private moduleService: ModuleService, private router: Router) {}

  canActivate(): boolean {
    if (this.moduleService.isModuleEnabled('DocumentTemplates')) {
      return true;
    }

    this.router.navigate(['applications']);
    return false;
  }
}
