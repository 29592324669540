import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { takeUntil } from 'rxjs/operators';

export const copyBetweenPartialsHook = (
  functionName,
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    field.form
      .get(field.key as string)
      .valueChanges.pipe(takeUntil(destroyed$))
      .subscribe((newVal) => {
        if (newVal) {
          const syncArgs = functionName.slice(1);
          const splitArgs = syncArgs[0].split(',');
          const fieldNameToCopyFrom = splitArgs[0];
          const fieldNameToCopyTo = splitArgs[1];

          const fieldsToCopyFrom = p.fieldGroup.find(
            (i) => i.key === fieldNameToCopyFrom
          ).fieldGroup;
          const fieldsToCopyTo = p.fieldGroup.find(
            (i) => i.key === fieldNameToCopyTo
          ).fieldGroup;

          copyPartialHelper(fieldsToCopyFrom, fieldsToCopyTo);
        }
      });
  };
};

const copyPartialHelper = (
  fieldsToCopyFrom: FormlyFieldConfig[],
  fieldsToCopyTo: FormlyFieldConfig[]
) => {
  for (let i = 0; i < fieldsToCopyFrom.length; i++) {
    //if form field is a group recurse, else copy values down
    if (
      fieldsToCopyFrom[i].type === 'formly-group' &&
      fieldsToCopyFrom[i]['fieldGroup'] &&
      fieldsToCopyTo[i]['fieldGroup']
    ) {
      copyPartialHelper(
        fieldsToCopyFrom[i]['fieldGroup'],
        fieldsToCopyTo[i]['fieldGroup']
      );
    } else {
      const key = `${fieldsToCopyFrom[i].key}`;
      const destinationField = fieldsToCopyTo[i].form.get(key);
      const valueToSet = fieldsToCopyFrom[i].form.get(key).value;
      destinationField.setValue(valueToSet);
    }
  }
};
