import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Permission } from '@intellio/shared/models';
// bug 66468
import { PermissionService } from '@intellio/shared/services'; // eslint-disable-line

@Directive({ selector: '[itcPermissionsRequired]' })
export class PermissionsRequiredDirective implements OnInit {
  @Input('itcPermissionsRequired')
  permissionsToCheck: Permission[];

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.viewContainer.clear();
    if (
      !this.permissionsToCheck || //have no permissions to check
      this.permissionService.doesUserHaveAnyPermissions(this.permissionsToCheck)
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
